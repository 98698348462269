import { Box, Button, Grid, Modal, Paper, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Artboard1 from "../../assets/membership/Artboard1.svg";
import Artboard2 from "../../assets/membership/Artboard2.svg";
import Artboard3 from "../../assets/membership/Artboard3.svg";
import Artboard4 from "../../assets/membership/Artboard4.svg";
import Artboard6 from "../../assets/membership/Artboard6.svg";

import yes from "../../assets/membership/yes.svg";
import cancle from "../../assets/membership/cancle.svg";
import { Fade } from "react-reveal";
import axios from "axios";
import useGetMemberships from "../../Hooks/Plan/membershipData";
import CommaSeparator from "../../components/CommaSeprator";
import { useCompanyStepDetails, useSentNotification } from "../../Hooks/Auth";
import useGetAuthUser from "../../Hooks/LoggedInUser/useGetAuthUser";
import Status from "../../utils/status";

const PricingDetails = () => {
  let navigate = useNavigate();
  const [select, setSelect] = useState("1");
  const [MembershipFee, setMembershipFee] = useState(false);
  const [Brokerage, setBrokerage] = useState(false);
  const [Subscription, setSubscription] = useState(false);
  const [AMC, setAMC] = useState(false);
  const [BuyInstantly, setBuyInstantly] = useState(false);
  const [Access, setAccess] = useState(false);
  const [Manager, setManager] = useState(false);
  const [Listing, setListing] = useState(false);
  const [boughtMembership, setBoughtMembership] = useState({});
  const [memberShipExpiry, setMemberShipExpiry] = useState();

  const [membershipType, setMembershipType] = useState("");
  const [membershipReccLoading, setMembershipReccLoading] = useState(false);
  const { mutate, isLoading } = useCompanyStepDetails();

  const {
    data: memberShipData,
    isLoading: memberShipDataLoading,
    error: memberShipDataError,
  } = useGetMemberships();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const makepayment = async (props) => {
    setMembershipReccLoading(true);
    await axios
      .post(`juspay/create-order`, {
        plan: props,
      })
      .then((res) => {
        window.open(res?.data?.payment_links?.web, "_blank");
        setTimeout(() => {
          navigate(`/payment/${res?.data?.order_id}`);
        }, 5000);
      })
      .catch((err) => {
        setMembershipReccLoading(false);
      });
  };

  const AcceptTerms = async () => {
    mutate(
      { termsAcceptStatus: true },
      {
        onSuccess: async (res) => {
          if (res) {
            await fetchPaymentForm();
          }
        },
        onError: () => {},
      }
    );
  };

  async function GetmemberShipData() {
    await axios.get("juspay/get-membership-data").then((res) => {
      var today = new Date();
      var expiryDate = new Date(
        res?.data?.data?.MembershipExpiryDate ||
          res?.data?.data?.MembershipEndDate
      );
      if (today > expiryDate) {
        setMemberShipExpiry(true);
        setBoughtMembership(null);
      } else {
        setMemberShipExpiry(false);
        setBoughtMembership(res?.data);
      }
    });
  }
  useEffect(() => {
    GetmemberShipData();
  }, []);

  const { data: AuthUserData } = useGetAuthUser();
  const { mutate: SendNotification } = useSentNotification();

  const handleSendNotification = () => {
    SendNotification({
      id: AuthUserData?.data?.companyId,
      status: Status.PAYMENT,
    });
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      handleSendNotification();
      e.returnValue =
        "Please click 'Stay on this Page' and we will give you candy";
      return "Please click 'Stay on this Page' and we will give you candy";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  if (memberShipDataLoading) return <div>Loading...</div>;
  if (memberShipDataError) return <div>Error...</div>;

  return (
    <Paper
      sx={{
        width: "100%",
        bgcolor: "transparent",
        position: "relative",
        width: "100vw",
        // height: "100vh",

        background:
          "linear-gradient(272.35deg, rgba(108, 108, 162, 0.06) 12.38%, rgba(136, 154, 232, 0.06) 73.74%, rgba(240, 240, 240, 0.06) 89.09%)",
      }}
      elevation={0}
    >
      <Box
        sx={{
          bgcolor: "transparent",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          // overflowX: 'auto',
          // scrollbarWidth: 'thin',
          // WebkitOverflowScrolling: 'touch', // Smooth scrolling on iOS
          // '&::-webkit-scrollbar': {
          //   height: '8px',
          // },
          // '&::-webkit-scrollbar-thumb': {
          //   backgroundColor: 'rgba(0,0,0,0.2)',
          //   borderRadius: '4px',
          // },
        }}
      >
        <Paper
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            bgcolor: "white",
            borderRadius: "0px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            py: "16px",
            width: "100%",
            mx: "auto",
          }}
          gap={0.5}
          elevation={0}
        >
          <Fade top duration={1000}>
            <Typography sx={maintext}>Explore Our Membership Plans</Typography>
          </Fade>
        </Paper>
        {(!memberShipExpiry && boughtMembership?.data?.membership) ||
        (!memberShipExpiry &&
          boughtMembership?.data?.MembershipType === "advance") ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                flexDirection: "column",
                alignItems: "center",
                height: "auto",
                minHeight: "60vh",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: 400,
                  color: "#2261A2",
                }}
              >
                You have already bought
                <span style={{ fontWeight: "600" }}>
                  {" "}
                  {boughtMembership?.data?.Membership}
                </span>{" "}
                membership{" "}
              </Typography>

              <Button
                sx={{
                  variant: "contained",
                  color: "white",
                  backgroundColor: "#2261A2",
                  borderRadius: "10px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  minWidth: "160px",
                  maxWidth: "200px",
                  mt: 2,
                  ":hover": {
                    backgroundColor: "#2261A2",
                    color: "white",
                  },
                  textTransform: "none",
                }}
                onClick={() => navigate("/home")}
              >
                Go Back Home
              </Button>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              bgcolor: "transparent",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              overflowX: "auto",
              scrollbarWidth: "thin",
              WebkitOverflowScrolling: "touch", // Smooth scrolling on iOS
              "&::-webkit-scrollbar": {
                height: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: "4px",
              },
            }}
          >
            <Grid
              container
              sx={{
                bgcolor: "transparent",
                width: { xs: "max-content", sm: "100%" }, // Use max-content to allow horizontal scrolling
                mx: "auto",
                boxShadow: "none",
                borderRadius: "20px",
                height: "auto",
                minHeight: "auto",
                display: "flex",
                flexWrap: "nowrap", // Prevent wrapping
                justifyContent: "center",
                alignItems: "stretch", // Stretch items to same height
                py: "50px",
                overflowX: "auto",
              }}
            >
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "transparent",
                }}
                onClick={() => setSelect("0")}
              >
                <Paper
                  elevation={0}
                  sx={{
                    ...paperStyle,
                    width: "75%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "left",
                    cursor: "auto",
                    bgcolor: "transparent",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      py: 0.5,
                      width: "40px",
                      height: "40px",
                    }}
                  >
                    &nbsp;
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      height: "auto",
                      py: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        ...TextStyleInsidePaper,
                        transform: MembershipFee ? "scale(1.1)" : null,
                        transition: "0.4s",
                        py: { xl: 1.5, lg: 1.5, md: 1.2, sm: 0.5, xs: 0.5 },
                      }}
                      onMouseEnter={() => {
                        setMembershipFee(true);
                      }}
                      onMouseLeave={() => {
                        setMembershipFee(false);
                      }}
                    >
                      <Box
                        component={"img"}
                        src={Artboard1}
                        alt=""
                        sx={iconsStyles}
                      />
                      Membership Subscription Fee
                    </Typography>
                    <Typography
                      sx={{
                        ...TextStyleInsidePaper,
                        transform: Brokerage ? "scale(1.1)" : null,
                        transition: "0.4s",
                        mt: { xl: 1.4, lg: 1.4, md: 1.2, sm: 1.2, xs: 2.2 },
                      }}
                      onMouseEnter={() => {
                        setBrokerage(true);
                      }}
                      onMouseLeave={() => {
                        setBrokerage(false);
                      }}
                    >
                      <Box
                        component={"img"}
                        src={Artboard1}
                        alt=""
                        sx={iconsStyles}
                      />
                      Brokerage
                    </Typography>
                    <Typography
                      sx={{
                        ...TextStyleInsidePaper,
                        transform: Subscription ? "scale(1.1)" : null,
                        transition: "0.4s",
                        mt: { xl: 0.3, lg: 0.3, md: 0.3, sm: 0.3, xs: -0.5 },
                      }}
                      onMouseEnter={() => {
                        setSubscription(true);
                      }}
                      onMouseLeave={() => {
                        setSubscription(false);
                      }}
                    >
                      {" "}
                      <Box
                        component={"img"}
                        src={Artboard2}
                        alt=""
                        sx={iconsStyles}
                      />
                      Subscription Term
                    </Typography>
                    <Typography
                      sx={{
                        ...TextStyleInsidePaper,
                        transform: AMC ? "scale(1.1)" : null,
                        transition: "0.4s",
                        mt: { xl: 0.2, lg: 0.2, md: 0.2, sm: 0.2, xs: -0.4 },
                      }}
                      onMouseEnter={() => {
                        setAMC(true);
                      }}
                      onMouseLeave={() => {
                        setAMC(false);
                      }}
                    >
                      <Box
                        component={"img"}
                        src={Artboard2}
                        alt=""
                        sx={iconsStyles}
                      />
                      AMC
                    </Typography>
                    <Typography
                      sx={{
                        ...TextStyleInsidePaper,
                        transform: Manager ? "scale(1.1)" : null,
                        transition: "0.4s",
                        mt: -0.3,
                      }}
                      onMouseEnter={() => {
                        setManager(true);
                      }}
                      onMouseLeave={() => {
                        setManager(false);
                      }}
                    >
                      {" "}
                      <Box
                        component={"img"}
                        src={Artboard6}
                        alt=""
                        sx={iconsStyles}
                      />
                      Priority Account Manager
                    </Typography>
                    <Typography
                      sx={{
                        ...TextStyleInsidePaper,
                        transform: Listing ? "scale(1.1)" : null,
                        transition: "0.4s",
                        mt: { xl: -0.3, lg: -0.3, md: -0.3, sm: 0.3, xs: 0.5 },
                      }}
                      onMouseEnter={() => {
                        setListing(true);
                      }}
                      onMouseLeave={() => {
                        setListing(false);
                      }}
                    >
                      <Box
                        component={"img"}
                        src={Artboard6}
                        alt=""
                        sx={iconsStyles}
                      />
                      Priority Product Listing
                    </Typography>
                    <Typography
                      sx={{
                        ...TextStyleInsidePaper,
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        transform: BuyInstantly ? "scale(1.1)" : null,
                        transition: "0.4s",
                        mt: { xl: 1, lg: 1, md: 1, sm: 0.7, xs: 0.7 },
                      }}
                      onMouseEnter={() => {
                        setBuyInstantly(true);
                      }}
                      onMouseLeave={() => {
                        setBuyInstantly(false);
                      }}
                    >
                      {" "}
                      <Box
                        component={"img"}
                        src={Artboard4}
                        alt=""
                        sx={iconsStyles}
                      />
                      Introduction Email To all members
                    </Typography>
                    <Typography
                      sx={{
                        ...TextStyleInsidePaper,
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        transform: Access ? "scale(1.1)" : null,
                        transition: "0.4s",
                        mt: { xl: 1, lg: 1, md: 1, sm: 0.5, xs: 0.2 },
                      }}
                      onMouseEnter={() => {
                        setAccess(true);
                      }}
                      onMouseLeave={() => {
                        setAccess(false);
                      }}
                    >
                      <Box
                        component={"img"}
                        src={Artboard3}
                        alt=""
                        sx={iconsStyles}
                      />
                      Product Banner on Marketplace for 7 days (*T&C Applied)
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
              {boughtMembership?.data?.MembershipType === "basic" ? null : (
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: { xl: 0, lg: 0, md: 0, sm: 2, xs: 2 },
                  }}
                >
                  <Paper
                    sx={{
                      ...paperStyle,
                      cursor: "pointer",
                      borderTop:
                        select === "0"
                          ? "5px solid white"
                          : "5px solid #2261A2",
                      backgroundColor: select === "0" ? "#2261A2" : "white",
                      boxShadow: "0px 3px 20px 1px rgba(0, 0, 0, 0.05)",
                      "&:hover": {
                        transform: "scale(1.04)",
                        transition: "0.4s",
                      },
                    }}
                    onClick={() => setSelect("0")}
                  >
                    <Box sx={card}>
                      <Typography
                        sx={{
                          ...preplan,
                          whiteSpace: "nowrap",
                          color: select === "0" ? "#FFFFFF" : "black",
                        }}
                      >
                        {memberShipData?.at(0)?.PlanName}
                      </Typography>
                      <Typography
                        sx={{
                          ...inrtext,
                          width: "90%",
                          borderBottom: "2.5px solid #D9D9D999",
                          color:
                            select === "0"
                              ? "#FFFFFF"
                              : "rgba(108, 108, 162, 1)",
                          transform: MembershipFee ? "scale(1.1)" : null,
                          transition: "0.4s",
                        }}
                        onMouseEnter={() => {
                          setMembershipFee(true);
                        }}
                        onMouseLeave={() => {
                          setMembershipFee(false);
                        }}
                      >
                        INR &nbsp;
                        <CommaSeparator
                          Price={memberShipData?.at(0)?.PlanPrice}
                        />
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "auto",
                          width: "60%",
                          // bgcolor: "red",
                          py: 1.5,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "0" ? "#FFFFFF" : "black",
                            transform: Brokerage ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setBrokerage(true);
                          }}
                          onMouseLeave={() => {
                            setBrokerage(false);
                          }}
                        >
                          {memberShipData?.at(0)?.GST}%
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "0" ? "#FFFFFF" : "black",
                            transform: Subscription ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setSubscription(true);
                          }}
                          onMouseLeave={() => {
                            setSubscription(false);
                          }}
                        >
                          1 Year
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "0" ? "#FFFFFF" : "black",
                            transform: AMC ? "scale(1.1)" : null,
                            transition: "0.4s",
                            whiteSpace: "nowrap",
                          }}
                          onMouseEnter={() => {
                            setAMC(true);
                          }}
                          onMouseLeave={() => {
                            setAMC(false);
                          }}
                        >
                          Free for First Year
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "0" ? "#FFFFFF" : "black",
                            transform: Manager ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setManager(true);
                          }}
                          onMouseLeave={() => {
                            setManager(false);
                          }}
                        >
                          <div
                            style={
                              {
                                // color: select === "0" ? "#2261A2" : "#FFFFFF",
                                // background: select === "0" ? "#FFFFFF" : "#2261A2",
                              }
                            }
                          >
                            {/* <img src={cancle} alt="Cancel Icon"  /> */}
                            <Box
                              component={"img"}
                              src={cancle}
                              alt=""
                              sx={iconsStyles}
                            />
                          </div>
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "0" ? "#FFFFFF" : "black",
                            transform: Listing ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setListing(true);
                          }}
                          onMouseLeave={() => {
                            setListing(false);
                          }}
                        >
                          <div
                            style={
                              {
                                // color: select === "0" ? "#2261A2" : "#FFFFFF",
                                // background: select === "0" ? "#FFFFFF" : "#2261A2",
                              }
                            }
                          >
                            <Box
                              component={"img"}
                              src={cancle}
                              alt=""
                              sx={iconsStyles}
                            />
                          </div>
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "0" ? "#FFFFFF" : "#2261A2",
                            transform: BuyInstantly ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setBuyInstantly(true);
                          }}
                          onMouseLeave={() => {
                            setBuyInstantly(false);
                          }}
                        >
                          <div
                            style={
                              {
                                // color: select === "0" ? "#2261A2" : "#FFFFFF",
                                // background: select === "0" ? "#FFFFFF" : "#2261A2",
                              }
                            }
                          >
                            <Box
                              component={"img"}
                              src={cancle}
                              alt=""
                              sx={iconsStyles}
                            />
                          </div>
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "0" ? "#FFFFFF" : "#2261A2",
                            transform: Access ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setAccess(true);
                          }}
                          onMouseLeave={() => {
                            setAccess(false);
                          }}
                        >
                          <div
                            style={
                              {
                                // color: select === "0" ? "#2261A2" : "#FFFFFF",
                                // background: select === "0" ? "#FFFFFF" : "#2261A2",
                              }
                            }
                          >
                            <Box
                              component={"img"}
                              src={cancle}
                              alt=""
                              sx={iconsStyles}
                            />
                          </div>
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        onClick={() => {
                          makepayment("basic");
                          // handleOpen();
                          // setMembershipType("basic");
                        }}
                        sx={{
                          display: select === "0" ? "block" : "none",
                          width: "145px",
                          height: "37px",
                          borderRadius: "10px",
                          background: "#FFF",
                          boxShadow: "none",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          color: "#2261A2",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          textTransform: "none",
                          "&:hover": {
                            color: "#2261A2",
                            background: "#FFF",
                          },
                        }}
                      >
                        Choose plan
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              )}

              {boughtMembership?.data?.MembershipType === "advance" ? null : (
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    sx={{
                      ...paperStyle,
                      cursor: "pointer",
                      backgroundColor: select === "1" ? "#2261A2" : "white",
                      borderTop:
                        select === "1"
                          ? "5px solid white"
                          : "5px solid #2261A2",

                      boxShadow: "0px 3px 20px 1px rgba(0, 0, 0, 0.05)",
                      "&:hover": {
                        transform: "scale(1.04)",
                        transition: "0.4s",
                      },
                    }}
                    onClick={() => setSelect("1")}
                  >
                    <Box sx={card}>
                      <Typography
                        sx={{
                          ...preplan,
                          whiteSpace: "nowrap",
                          color: select === "1" ? "#FFFFFF" : "black",
                        }}
                      >
                        {memberShipData?.at(1)?.PlanName}
                      </Typography>
                      <Typography
                        sx={{
                          ...inrtext,
                          width: "90%",
                          borderBottom: "2.5px solid #D9D9D999",
                          color:
                            select === "1"
                              ? "#FFFFFF"
                              : "rgba(108, 108, 162, 1)",
                          transform: MembershipFee ? "scale(1.1)" : null,
                          transition: "0.4s",
                        }}
                        onMouseEnter={() => {
                          setMembershipFee(true);
                        }}
                        onMouseLeave={() => {
                          setMembershipFee(false);
                        }}
                      >
                        INR &nbsp;
                        <CommaSeparator
                          Price={memberShipData?.at(1)?.PlanPrice}
                        />
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          height: "auto",
                          width: "80%",
                          py: 1.8,
                        }}
                      >
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "1" ? "#FFFFFF" : "black",
                            transform: Brokerage ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setBrokerage(true);
                          }}
                          onMouseLeave={() => {
                            setBrokerage(false);
                          }}
                        >
                          {memberShipData?.at(1)?.GST}%
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "1" ? "#FFFFFF" : "black",
                            transform: Subscription ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setSubscription(true);
                          }}
                          onMouseLeave={() => {
                            setSubscription(false);
                          }}
                        >
                          2 Year
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "1" ? "#FFFFFF" : "black",
                            transform: AMC ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setAMC(true);
                          }}
                          onMouseLeave={() => {
                            setAMC(false);
                          }}
                        >
                          Free for Two Year
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "1" ? "#FFFFFF" : "black",
                            transform: Manager ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setManager(true);
                          }}
                          onMouseLeave={() => {
                            setManager(false);
                          }}
                        >
                          <div
                            style={
                              {
                                // color: select === "1" ? "#2261A2" : "#FFFFFF",
                                // background: select === "1" ? "#FFFFFF" : "#2261A2",
                              }
                            }
                          >
                            <Box
                              component={"img"}
                              src={yes}
                              alt=""
                              sx={iconsStyles}
                            />
                          </div>
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "1" ? "#FFFFFF" : "black",
                            transform: Listing ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setListing(true);
                          }}
                          onMouseLeave={() => {
                            setListing(false);
                          }}
                        >
                          <div
                            style={
                              {
                                // color: select === "1" ? "#2261A2" : "#FFFFFF",
                                // background: select === "1" ? "#FFFFFF" : "#2261A2",
                              }
                            }
                          >
                            <Box
                              component={"img"}
                              src={yes}
                              alt=""
                              sx={iconsStyles}
                            />
                          </div>
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "1" ? "#FFFFFF" : "black",
                            transform: BuyInstantly ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setBuyInstantly(true);
                          }}
                          onMouseLeave={() => {
                            setBuyInstantly(false);
                          }}
                        >
                          <div
                            style={
                              {
                                // color: select === "1" ? "#2261A2" : "#FFFFFF",
                                // background: select === "1" ? "#FFFFFF" : "#2261A2",
                              }
                            }
                          >
                            <Box
                              component={"img"}
                              src={yes}
                              alt=""
                              sx={iconsStyles}
                            />
                          </div>
                        </Typography>
                        <Typography
                          sx={{
                            ...TextStyleInsidePaper,
                            color: select === "1" ? "#FFFFFF" : "#2261A2",
                            transform: Access ? "scale(1.1)" : null,
                            transition: "0.4s",
                          }}
                          onMouseEnter={() => {
                            setAccess(true);
                          }}
                          onMouseLeave={() => {
                            setAccess(false);
                          }}
                        >
                          <div
                            style={
                              {
                                // color: select === "1" ? "#2261A2" : "#FFFFFF",
                                // background: select === "1" ? "#FFFFFF" : "#2261A2",
                              }
                            }
                          >
                            <Box
                              component={"img"}
                              src={yes}
                              alt=""
                              sx={iconsStyles}
                            />
                          </div>
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        onClick={() => {
                          makepayment("advance");
                          // handleOpen();
                          // setMembershipType("advance");
                        }}
                        sx={{
                          display: select === "1" ? "block" : "none",
                          width: "145px",
                          height: "37px",
                          borderRadius: "10px",
                          background: "#FFF",
                          boxShadow: "none",
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 500,
                          color: "#2261A2",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          textTransform: "none",
                          "&:hover": {
                            color: "#2261A2",
                            background: "#FFF",
                          },
                        }}
                      >
                        Choose plan
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Box>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {membershipReccLoading ? (
              <div className="triple-spinner-two"></div>
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "1.5",
                    color: "#2261A2",
                  }}
                >
                  If you choose to proceed, please be aware that the membership
                  amount will be deducted from your account within 2 days.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "15px",
                    mt: "20px",
                  }}
                >
                  <Button
                    onClick={() => makepayment(membershipType)}
                    sx={ButtonStyles}
                  >
                    Proceed
                  </Button>
                  <Button onClick={handleClose} sx={CancelButtonStyles}>
                    Cancel
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Modal>
      </Box>
    </Paper>
  );
};

export default PricingDetails;

const ButtonStyles = {
  variant: "contained",
  background: "#2261A2",
  color: "#FFFFFF",
  borderRadius: "5px",
  width: "120px",
  height: "40px",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "none",
  "&:hover": {
    background: "#fff",
    color: "#2261A2",
    border: "1px solid #2261A2",
  },
};
const CancelButtonStyles = {
  variant: "contained",
  background: "#fff",
  color: "#2261A2",
  borderRadius: "5px",
  width: "120px",
  height: "40px",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
  textTransform: "none",
  border: "1px solid #2261A2",
  "&:hover": {
    background: "#fff",
    color: "#2261A2",
    border: "1px solid #2261A2",
  },
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xl: "500px", lg: "500px", md: "500px", sm: "400px", xs: "300px" },
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const TextStyleInsidePaper = {
  display: "flex",
  alignItems: "center",
  gap: "1.5rem",
  fontFamily: "Poppins",
  fontSize: {
    xl: "14px",
    lg: "14px",
    md: "1rem",
    sm: "0.9rem",
    xs: "0.9rem",
  },
  fontWeight: 500,
  color: "black",
  lineHeight: { xl: "1.8", lg: "1.8", md: "1.8", sm: "1.5", xs: "1.5" },
  // cursor: "pointer",
  py: { xl: 1.3, lg: 1.3, md: 1.3, sm: 1, xs: 1 },
};

const paperStyle = {
  width: { xl: "45%", lg: "45%", md: "55%", sm: "60%", xs: "60%" },
  display: "flex",
  margin: "2rem",
  padding: "2rem",
  height: {
    xl: "52rem",
    lg: "52rem",
    md: "52rem",
    sm: "44rem",
    xs: "44rem",
  },
  // cursor: "pointer",
  borderRadius: "14px",
  // border: "1px solid rgba(219, 219, 219, 1)",
};

const checkicon = {
  color: "#2261A2",
  borderRadius: "32px",
  padding: "2px",
  background: "#E5EAEF",
  fontSize: "18px",
};

const card = {
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: {
    xl: "0.5rem",
    lg: "0.5rem",
    md: "0.5rem",
    sm: "0.5rem",
    xs: "0.5rem",
  },
};

const inrtext = {
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: {
    xl: "3rem",
    lg: "2.5rem",
    md: "2.5rem",
    sm: "2.2rem",
    xs: "2.2rem",
  },
  lineHeight: {
    xl: "3.8rem",
    lg: "3.8rem",
    md: "3.8rem",
    sm: "3.2rem",
    xs: "3.2rem",
  },
  py: 1.5,
  fontWeight: "700",
  whiteSpace: "nowrap",
};

const preplan = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "1.8rem",
    lg: "1.8rem",
    md: "1.8rem",
    sm: "1.4rem",
    xs: "1.4rem",
  },
  lineHeight: {
    xl: "4.2rem",
    lg: "4.2rem",
    md: "3.8rem",
    sm: "3rem",
    xs: "3rem",
  },
  color: "#FFF",
};

const maintext = {
  fontFamily: "Poppins",
  fontStyle: "SemiBold",
  fontWeight: 600,
  fontSize: {
    xl: "3rem",
    lg: "3rem",
    md: "2.5rem",
    sm: "1.8rem",
    xs: "1.6rem",
  },
  mt: 1,
};

const iconsStyles = {
  width: { xl: "22px", lg: "22px", md: "22px", sm: "15px", xs: "15px" },
  height: { xl: "22px", lg: "22px", md: "22px", sm: "15px", xs: "15px" },
};
