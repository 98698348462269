export const smallHeadText = {
  fontFamily: "Poppins",
  fontSize: { xl: "15px", lg: "15px", md: "15px", sm: "13px", xs: "13px" },
  fontWeight: 300,
  color: "black",
  letterSpacing: "0.11em",
  textTransform: "uppercase",

  textAlign: { xl: "left", lg: "left", md: "left", sm: "center", xs: "center" },
};

export const headText = {
  mt: 1,
  fontFamily: "Poppins",
  fontSize: { xl: "32px", lg: "32px", md: "18px", sm: "22px", xs: "20px" },
  fontWeight: 500,
  letterSpacing: "0.01em",
  color: "rgba(35, 36, 42, 1)",
  textAlign: { xl: "left", lg: "left", md: "left", sm: "center", xs: "center" },
  width: { xl: "30%", lg: "30%", md: "100%", sm: "100%", xs: "100%" },
  lineHeight: { xl: "32px", lg: "32px", md: "32px", sm: "26px", xs: "20px" },
};

export const insideCategoryBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 2,
  background: "rgba(255, 255, 255, 1)",
  boxShadow: "0px 10px 20px rgba(220, 220, 220, 0.5)",
  borderRadius: "10px",
  width: "auto",
  height: "auto",
  px: 2,
  cursor: "pointer",
  "&:hover": {
    boxShadow: "1px 1px 30.6px 1px rgba(47, 63, 87, 0.08)",
  },
};

export const categoryText = {
  fontFamily: "poppins",
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "12px", xs: "12px" },
  fontWeight: 400,
  color: "rgba(0, 0, 0, 1)",
};

export const headingStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xs: "22px",
    sm: "25px",
    md: "28px",
    lg: "28px",
    xl: "28px",
  },
  letterSpacing: "0.5px",
  lineHeight: "65px",
  display: "flex",
  alignItems: "center",
  color: "#0D0E0E",
  marginRight: "10px",
  textTransform: "uppercase",
};

export const gradientText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: {
    xs: "22px",
    sm: "25px",
    md: "28px",
    lg: "28px",
    xl: "28px",
  },
  letterSpacing: "0.5px",
  lineHeight: "65px",
  display: "flex",
  alignItems: "center",
  background: "linear-gradient(75deg, #375DBB 29.17%, #00B1FF 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
};

export const jonnowmainbox = {
  position: "relative",
  width: "100%",
  mt: 3,
  paddingBottom: {
    xl: "25%",
    lg: "25%",
    md: "50%",
    sm: "75%",
    xs: "75%",
  },
  overflow: "hidden",
};

export const joinnowimg = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  zIndex: 1,
};

export const joinnowsecbox = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 2,
  p: 2,
  width: "90%",
};

export const typo1 = {
  color: "rgba(45, 29, 97, 1)",
  fontFamily: "Poppins, sans-serif",
  fontSize: {
    xl: "32px",
    lg: "30px",
    md: "24px",
    sm: "16px",
    xs: "14px",
  },
  fontWeight: 400,
  textAlign: "center",
};

export const typo2 = {
  color: "rgba(45, 29, 97, 1)",
  fontFamily: "Poppins, sans-serif",
  fontSize: {
    xl: "30px",
    lg: "28px",
    md: "24px",
    sm: "18px",
    xs: "16px",
  },
  mt: 1,
  fontWeight: 600,
  textAlign: "center",
};

export const buttonmainbox = {
  display: "flex",
  justifyContent: "center", // Centers horizontally
  alignItems: "center", // Centers vertically
  mt: 3,
};

export const buttonjoinow = {
  textTransform: "none",
  width: {
    xl: "150px",
    lg: "150px",
    md: "140px",
    sm: "130px",
    xs: "120px",
  },
  height: {
    xl: "55px",
    lg: "55px",
    md: "50px",
    sm: "45px",
    xs: "40px",
  },
  borderRadius: "15px",
  fontFamily: "Poppins, sans-serif",
  fontSize: { xl: 17, lg: 17, md: 16, sm: 14, xs: 13 },
  fontWeight: 600,
  cursor: "pointer",
  background: "rgba(130, 114, 181, 1)",
  color: "white",
  "&:hover": {
    background: "rgba(110, 90, 160, 1)", // Slightly darker hover color
    color: "white",
    cursor: "pointer",
  },
};

export const mainboxdefancebox2 = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gap: "5px",
  mt: 2,
};

export const MakingAdifferencetypo = {
  fontFamily: "Poppins",
  fontSize: {
    xl: "40px",
    lg: "40px",
    md: "30px",
    sm: "20px",
    xs: "15px",
  },
  fontWeight: 500,
  letterSpacing: "0.01em",
  textAlign: "center",
  color: "black",
};

export const LetsBartertypo = {
  fontFamily: "Poppins",
  fontSize: {
    xl: "20px",
    lg: "20px",
    md: "16px",
    sm: "15px",
    xs: "12px",
  },
  fontWeight: 400,
  letterSpacing: "0.01em",
  textAlign: "center",
  color: "rgba(35, 36, 42, 1)",
  zIndex: 1,
};

export const mainboxmap = {
  position: "relative",
  width: "100%",
  minHeight: "100%",
  // background: `linear-gradient(to bottom right, rgba(173, 216, 230, 0.3), white)`, // Light gradient effect
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: { xs: 1, md: 1.5 },
  mx: "auto",
};

export const gridfirstdata = {
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "80%",
  left: 0,
  top: 0,
};

export const carddata = {
  border: "1.29px solid rgba(25, 24, 37, 0.1)",
  borderRadius: 5,
  p: 2,
  height: "50%",
  width: "70%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

export const typonumber = {
  fontFamily: "Poppins",
  fontSize: {
    xl: "40px",
    lg: "35px",
    md: "30px",
    sm: "25px",
    xs: "20px",
  },
  fontWeight: 600,
  color: "rgba(130, 114, 181, 1)",
};

export const typoame = {
  fontFamily: "Poppins",
  fontSize: {
    xl: "20px",
    lg: "18px",
    md: "16px",
    sm: "14px",
    xs: "14px",
  },
  fontWeight: 400,
  color: "rgba(25, 24, 37, 1)",
  mt: 1,
};
