import React from "react";
import * as Styles from "../Styles";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { Fade } from "react-reveal";
import flowhowitworks from "../../../assets/Images/LearnBarterImages/flowhowitworks.svg";
import rightArr from "../../../assets/Images/LearnBarterImages/rightArr.svg";
const HeroLearnBarter = () => {
  return (
    <Paper elevation={0} sx={{ mt: 10 }}>
      <Paper
        elevation={0}
        sx={{
          //   height: {
          //     xl: '80vh',
          //     lg: '80vh',
          //     md: '80vh',
          //     sm: '100vh',
          //     xs: '100vh',
          //   },
          width: "100%",
          mt: { xl: 0, lg: 0, md: 0, sm: 10, xs: 10 },
          mb: { xl: 0, lg: 0, md: 5, sm: 2, xs: 2 },
        }}
      >
        <Grid container>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            sm={12}
            sx={{ mt: { xl: 2, lg: 2, md: 2, sm: 2, xs: 2 } }}
          >
            <Fade top duration={1000}>
              <Typography
                sx={{
                  ...Styles.headMainText,
                  textAlign: "left",
                  width: {
                    xl: "90%",
                    lg: "90%",
                    md: "80%",
                    sm: "90%",
                    xs: "90%",
                  },
                  mx: "auto",
                }}
              >
                How Does
                <span style={{ color: "rgba(108, 108, 162, 1)" }}>
                  &nbsp;BXI Work ?
                </span>
              </Typography>
            </Fade>
            <Box sx={styleYoutubeVideo}>
              <Box
                component="iframe"
                src="https://www.youtube.com/embed/g2ZrdTW5WSY?si=k4j68cV0s8n5sXee"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  border: "none",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            xs={12}
            sm={12}
            sx={{
              mb: { xl: 0, lg: 0, md: 0, sm: 4, xs: 4 },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                height: "100%",
                width: "30%",
                backgroundImage: `url(${rightArr})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                zIndex: 0,
                display: {
                  xl: "block",
                  lg: "block",
                  md: "none",
                  sm: "none",
                  xs: "none",
                },
              }}
            />
            <Box sx={{ position: "relative", zIndex: 1 }}>
              <Box component={"img"} src={flowhowitworks} sx={styleFlowImg} />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Paper>
  );
};

export default HeroLearnBarter;

const styleYoutubeVideo = {
  position: "relative",
  paddingTop: "56.25%" /* 16:9 Aspect Ratio */,
  width: "99.5%",
  mx: "auto",
  borderRadius: "10px",
  overflow: "hidden",
  mt: 2,
  ml: { xl: 4, lg: 4, md: 0, sm: 0.2, xs: 0.15 },
};

const styleFlowImg = {
  width: "100%",
  mx: "auto",
  maxWidth: "100%",
  height: "80%",
  maxHeight: {
    xl: "60vh",
    lg: "57vh",
    md: "50vh",
    sm: "40vh",
    xs: "40vh",
  },
  display: "flex",
  justifyContent: "center",
  mt: { xl: 8.5, lg: 9.2, md: 10, sm: 2, xs: 2 },
};
