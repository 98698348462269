import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  Typography,
  Box,
  Dialog,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader";
import CloseIconDialog from "@mui/icons-material/Close";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import useGetLoggedInUser from "../../../../Hooks/LoggedInUser/useGetLoggedInUser";
import useGetCompanyTypeData from "../../../../Hooks/CompanyData/useGetCompanyTypeData";
import Checkbox from "@mui/material/Checkbox";

const BulkUploadExcelpreviewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inputRef = useRef();
  const FileLink = location?.state?.Link;
  const bulkuplodid = location?.state?.id;
  const isMedia = location?.state?.isMedia ? true : false;

  const [allproducts, setAllProducts] = useState();
  const [viewimg, setViewImg] = useState(false);
  const [loading, setLoading] = useState();
  const [ProductData, setProductData] = useState();
  const [files, setFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [sizechart, setSizeChart] = useState(null);
  const [deleteloading, setDeleteLoading] = useState();
  const [FileNameErrorfordimanesion, setFileNameErrorForDimension] = useState();
  const [FileNameErrorForSize, setFileNameErrorForSize] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const { data: CompanyData } = useGetLoggedInUser();

  const { data: CompanyTypeData } = useGetCompanyTypeData(
    CompanyData?.data?.companyType
  );

  const handleViewClose = () => {
    setViewImg(false);
    setFiles([]);
    setImagePreviews([]);
  };
  const handleViewOpen = () => setViewImg(true);
  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((item) => item !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const NaviGateFunction = (product) => {
    const {
      ListingType,
      ProductUploadStatus,
      VoucherType,
      _id,
      ProductCategoryName,
    } = product;
    const companyType = CompanyTypeData?.data?.CompanyTypeName;

    const baseRoutes = {
      Voucher: {
        productinformation:
          companyType === "Hotel" ? "productinfo" : "generalinformation",
        technicalinformation: "techinfo",
        golive: "voucherdesign",
      },
      Product: {
        productinformation: "productinfo",
        technicalinformation: "techinfo",
        golive: "golive",
      },
    };

    const companyRoutes = {
      Textile: "textile",
      "Office Supply": "officesupply",
      Lifestyle: "lifestyle",
      Electronics: "electronics",
      FMCG: "fmcg",
      Mobility: "mobility",
      QSR: ListingType === "Voucher" ? "qsr" : "restaurant",
      Others: ListingType === "Voucher" ? "other" : "others",
      Hotel: "hotels",
      "Airlines Tickets": "airline",
      "Entertainment & Events": "ee",
    };

    const mediaRoutes = {
      productinformation: "productinfo",
      technicalinformation: "techinfo",
      golive: "golive",
    };

    const commonRoutes = {
      "Value Voucher / Gift Cards ": "valueandgiftvoucher",
      "Offer Specific": "spacificvoucher",
    };

    let route;

    if (companyType === "Media") {
      const mediaType = ["MediaOnline", "Multiplex ADs"].includes(
        ProductCategoryName
      )
        ? "online"
        : "offline";
      route = `media${mediaType}/media${mediaType}${mediaRoutes[ProductUploadStatus]}`;
    } else if (ListingType && ProductUploadStatus && companyType) {
      const baseRoute = baseRoutes[ListingType];
      const baseRoutesec = baseRoute[ProductUploadStatus];
      const companyRoute = companyRoutes[companyType];
      route = `${companyRoute}${ListingType === "Voucher" ? "Voucher" : ""}/${
        ListingType === "Product"
          ? companyRoute + baseRoutesec
          : ListingType === "Voucher" && ProductUploadStatus !== "golive"
          ? companyRoute + baseRoutesec
          : baseRoutesec
      }`;
    }

    if (
      ["pendingapproval", "productpreview", "Approved"].includes(
        ProductUploadStatus
      )
    ) {
      if (VoucherType && commonRoutes[VoucherType]) {
        navigate(`/home/${commonRoutes[VoucherType]}/${_id}`);
      } else if (companyType === "Media") {
        navigate(`/home/mediaonlineproductpreview/${_id}`);
      } else {
        navigate(`/home/allproductpreview/${_id}`);
      }
    } else if (route) {
      navigate(`/home/${route}/${_id}`);
    }
  };

  const uploadData = async () => {
    const formData = new FormData();

    if (files?.length < 3) {
      return toast.error("Please upload at least 3 images", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else if (files?.length > 6) {
      return toast.error("More than 6 images are not accepted", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    let FilarrayForDimension = [];
    let FilearrayforSize = [];
    const MAX_FILE_SIZE = 10000000;
    let ReadyToUpload = true; // Initialize to true

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];

      // Check if the file is an image
      if (!file.type.startsWith("image/")) {
        return toast.error("Only image files are accepted", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      try {
        const dataUrl = await readFileAsDataURL(file);
        const img = await loadImage(dataUrl);
        const width = img.width;
        const height = img.height;
        const aspectRatio = width / height;

        if (
          (ProductData?.ProductCategoryName === "Textile" ||
            ProductData?.ProductCategoryName === "Office Supply" ||
            ProductData?.ProductCategoryName === "Lifestyle" ||
            ProductData?.ProductCategoryName === "Other") &&
          file.size > Number(1000000)
        ) {
          FilearrayforSize.push(file.name);
          ReadyToUpload = false;
        } else if (file.size > MAX_FILE_SIZE) {
          FilearrayforSize.push(file.name);
        } else if (
          aspectRatio.toFixed(2) - 2 / 3 < 2 ||
          aspectRatio.toFixed(2) - 3 / 4 < 2 ||
          aspectRatio.toFixed(2) - 9 / 16 < 2
        ) {
        } else if (
          file.size <= Number(1000000) &&
          (aspectRatio.toFixed(2) - 4 / 3 < 2 ||
            aspectRatio.toFixed(2) - 3 / 2 < 2 ||
            aspectRatio.toFixed(2) - 16 / 9 < 2)
        ) {
        } else {
          FilarrayForDimension.push(file.name);
        }
      } catch (error) {
        ReadyToUpload = false;
      }

      formData.append("files", file);
    }

    // Validate the sizechart file
    if (sizechart) {
      if (
        !sizechart.type.startsWith("image/") &&
        sizechart.type !== "application/pdf"
      ) {
        return toast.error("Sizechart must be an image or a PDF", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    formData.append("id", ProductData?._id);
    // formData.append("sizechart", sizechart);
    formData.append("ProductUploadStatus", "pendingapproval");

    setFileNameErrorForDimension(FilarrayForDimension);
    setFileNameErrorForSize(FilearrayforSize);
    if (FilarrayForDimension.length <= 0 && FilearrayforSize.length <= 0) {
      axios({
        method: "post",
        url: "/product/product_mutation",
        data: formData,
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
        },
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      })
        .then((res) => {
          handleViewClose();
          findAllWebHookReponsesProducts();
          return toast.success("Image Uploaded Succesfully", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            return toast.error(err?.response?.data?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        });
    } else {
      ReadyToUpload = false;
      return toast.error("Image dimension or Size issue", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleChangeFiles = (event) => {
    const newFiles = Array.from(event.target.files);
    const duplicateFiles = newFiles.filter((file) =>
      files.some((existingFile) => existingFile.name === file.name)
    );
    setImagePreviews;

    if (duplicateFiles.length > 0) {
      return toast.error("Duplicate Files are not allowed", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    const newImagePreviews = newFiles.map((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => resolve(reader.result);
      });
    });

    Promise.all(newImagePreviews).then((previews) => {
      setImagePreviews((prevFiles) => [...prevFiles, ...previews]);
    });
  };

  const findAllWebHookReponsesProducts = async () => {
    setLoading(true);
    try {
      await axios
        .post(
          `publiq_bulk_upload/get_products_by_bulk_response_id`,
          {
            data: bulkuplodid,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          // console.log("response", response.data.data);
          setAllProducts(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching :", error.message);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const DeleteAllIdsProducts = async () => {
    if (selectedIds?.length === allproducts?.length) {
      return toast.error("You can't Delete All Products", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (window.confirm("Are you sure you want to delete products?")) {
      try {
        await axios
          .post(
            `publiq_bulk_upload/delete_products_by_ids`,
            {
              ids: selectedIds,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            findAllWebHookReponsesProducts();
            setSelectedIds([]);
          })
          .catch((error) => {
            console.log("Error fetching :", error.message);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  const DeleteAllWebHookReponsesProducts = async () => {
    if (window.confirm("Are you sure you want to delete all products?")) {
      setDeleteLoading(true);
      try {
        await axios
          .post(
            `publiq_bulk_upload/delete_products_by_bulk_response_id`,
            {
              data: bulkuplodid,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            // console.log("response", response.data.data);
            setAllProducts(response.data.data);
            setDeleteLoading(false);
            navigate(-1);
          })
          .catch((error) => {
            console.error("Error fetching :", error.message);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const ChangeFileUploadStatus = async () => {
    if (window.confirm("Are you sure you want to Confirm all products?")) {
      try {
        await axios
          .post(
            `publiq_bulk_upload/change_file_upload_status`,
            {
              excel_id: bulkuplodid,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            navigate(-1);
          })
          .catch((error) => {
            console.error("Error fetching :", error.message);
          });
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const shouldDisplayButton =
    allproducts?.length > 0
      ? allproducts?.every((product) => product.ProductImages.length >= 3)
      : false;

  useEffect(() => {
    findAllWebHookReponsesProducts();
  }, [bulkuplodid]);

  return (
    <Box>
      <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />
      <Typography
        style={{ fontWeight: 500, fontSize: "1.8rem", color: "#525252" }}
      >
        Bulk Upload Excel Preview
      </Typography>
      <Box>
        {loading ? (
          <Typography>Loading ....</Typography>
        ) : allproducts?.length > 0 ? (
          <Box>
            <Box>
              {allproducts?.map((item, idx) => {
                return (
                  <Box sx={parentbox}>
                    <Box
                      sx={{
                        ...parentbox,
                        width: "85%",
                        border: "2px solid #e0e0e0",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          top: "0%",
                          left: "93%",
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <RemoveRedEyeIcon
                          sx={{
                            color: "#c64091",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => {}}
                        />
                        <BorderColorIcon
                          sx={{
                            color: "#c64091",
                            fontSize: "23px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            NaviGateFunction(item);
                          }}
                        />
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#c64091",
                            },
                            "& .MuiSvgIcon-root": {
                              fontSize: "20px",
                            },
                            padding: 0,
                            color: "#c64091",
                          }}
                          checked={selectedIds.includes(item?._id)}
                          onChange={() => handleCheckboxChange(item?._id)}
                        />
                      </Box>
                      <Box sx={childbox}>
                        <Typography sx={TableBottomtext}>
                          Product Name :{item.ProductName}
                        </Typography>
                        {isMedia ? null : (
                          <Typography sx={TableBottomtext}>
                            Colour :
                            {item.ProductsVariantions.at(0).ProductColor}
                          </Typography>
                        )}
                        <Typography sx={TableBottomtext}>Variant :</Typography>
                        {isMedia ? null : (
                          <Typography sx={TableBottomtext}>
                            Mfg Date :
                            {item.ManufacturingDate
                              ? new Date(
                                  item?.ManufacturingDate
                                ).toLocaleDateString("en-IN")
                              : item.ManufacturingData
                              ? new Date(
                                  item?.ManufacturingData
                                ).toLocaleDateString("en-IN")
                              : null}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={childbox}>
                        <Typography sx={TableBottomtext}>
                          MRP :{item.ProductsVariantions.at(0).PricePerUnit}
                        </Typography>
                        <Typography sx={TableBottomtext}>
                          Discounted MRP :
                          {item.ProductsVariantions.at(0).DiscountedPrice}
                        </Typography>
                        <Typography sx={TableBottomtext}>
                          HSN Code :{item.ProductsVariantions.at(0).HSN}
                        </Typography>
                        {isMedia ? null : (
                          <Typography sx={TableBottomtext}>
                            Expiry Date :
                            {item.ExpiryDate
                              ? new Date(item?.ExpiryDate).toLocaleDateString(
                                  "en-IN"
                                )
                              : item.ExpiryData
                              ? new Date(item?.ExpiryData).toLocaleDateString(
                                  "en-IN"
                                )
                              : null}
                          </Typography>
                        )}
                      </Box>
                      <Box sx={childbox}>
                        {isMedia ? null : (
                          <Typography sx={TableBottomtext}>
                            Warranty :{item.ProductTechInfo.Warranty}{" "}
                            {item.ProductTechInfo.WarrantyPeriod}
                          </Typography>
                        )}
                        <Typography sx={TableBottomtext}>Weight :</Typography>
                        <Typography sx={TableBottomtext}>
                          Tags :
                          {item?.ProductTechInfo?.Tags.map((data, id) => {
                            return <span>{data}, </span>;
                          })}
                        </Typography>
                        <Typography sx={TableBottomtext}>
                          Listing Validity :{item.listperiod}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        ...parentbox,
                        width: "15%",
                      }}
                    >
                      {item.ProductImages.length >= 3 ? (
                        <Typography sx={TableBottomtext}>
                          Image already Uploaded
                        </Typography>
                      ) : (
                        <Button
                          variant="contained"
                          sx={imgbutton}
                          onClick={() => {
                            setProductData(item);
                            handleViewOpen();
                          }}
                        >
                          Add Images
                        </Button>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Box sx={{ ...parentbox, justifyContent: "center", gap: "10px" }}>
              <Button
                variant="contained"
                sx={imgbutton}
                onClick={() => {
                  DeleteAllWebHookReponsesProducts();
                }}
              >
                Cancle All Products
              </Button>
              {selectedIds.length > 0 ? (
                <Button
                  variant="contained"
                  sx={imgbutton}
                  onClick={() => {
                    DeleteAllIdsProducts();
                  }}
                >
                  Delete Products
                </Button>
              ) : null}
              {shouldDisplayButton ? (
                <Button
                  variant="contained"
                  sx={imgbutton}
                  onClick={() => {
                    ChangeFileUploadStatus();
                  }}
                >
                  Upload All Products
                </Button>
              ) : null}
            </Box>
          </Box>
        ) : (
          "No Data Available"
        )}
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        // aria-labelledby="responsive-dialog-title"
        open={viewimg}
        onClose={handleViewClose}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            my: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <CloseIconDialog
              sx={{
                color: "#667085",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleViewClose();
              }}
            />
          </Box>
          <Box
            onClick={() => inputRef.current.click()}
            sx={{ ...ImageSelectBoxStyle, cursor: "pointer" }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <Box
              sx={{
                display: "grid",
                width: "60%",
                mx: "auto",
              }}
            >
              <Box sx={DisplayGridStyle}>
                {/* <Box component="img" src={UploadtoCloud} sx={ImageBox} /> */}
                <Typography
                  sx={{
                    ...TextStyle,
                    display: "flex",
                    justifyContent: "center",
                    color: "#c64091",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#c64091",
                    },
                    textalign: "center",
                  }}
                >
                  Drag & Drop | Upload OR
                  <span style={{ color: "#c64091", fontWeight: 500 }}>
                    &nbsp; Browse &nbsp;
                  </span>
                  to choose a file
                </Typography>
                <Typography sx={MulishFontTextStyle}>
                  Size Limit & Format ( JPEG , PNG - 1 MB , GIF 4 - 5 MB )
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: "center", mt: "1%" }}>
              {ProductData?.ProductCategoryName === "Textile" ||
              ProductData?.ProductCategoryName === "Office Supply" ||
              ProductData?.ProductCategoryName === "Lifestyle" ||
              ProductData?.ProductCategoryName === "Other" ? (
                <Typography sx={{ ...TextStyle, color: "#c64091" }}>
                  Aspect Ratio 3 : 4 (1080 X 1920 )
                </Typography>
              ) : (
                <Typography sx={{ ...TextStyle, color: "#c64091" }}>
                  Aspect Ratio 32 : 9 (3840 X 1080 ) OR 16 : 9 ( 1920 X 1080)
                </Typography>
              )}

              <Typography
                sx={{
                  ...TextStyle,
                  color: "#c64091",
                }}
              >
                Mandatory Photos : Front View, Back View, Close Fabric View,
              </Typography>
            </Box>
            <input
              type="file"
              multiple
              onChange={handleChangeFiles}
              hidden
              accept=".png,.jpeg,.gif,.avi,.webp,.jpg"
              ref={inputRef}
            />
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "90%",
            }}
          >
            {imagePreviews.map((preview, index) => {
              return (
                <Box
                  sx={{
                    position: "relative",
                    m: 1,
                  }}
                >
                  <img
                    key={index}
                    src={preview}
                    alt={`Selected ${index}`}
                    style={{ width: "200px", height: "auto" }}
                  />
                  <CloseIconDialog
                    sx={{
                      color: "#667085",
                      fontSize: "25px",
                      cursor: "pointer",
                      position: "absolute",
                      top: "0%",
                      right: "0%",
                    }}
                    onClick={() => {
                      const newPreview = Array.from(imagePreviews);
                      newPreview.splice(index, 1);
                      setImagePreviews(newPreview);

                      const newFiles = Array.from(files);
                      newFiles.splice(index, 1);
                      setFiles(newFiles);
                      // Remove the image from error arrays if it exists
                      setFileNameErrorForDimension((prevErrors) =>
                        prevErrors?.filter((error) => error !== file.name)
                      );
                      setFileNameErrorForSize((prevErrors) =>
                        prevErrors?.filter((error) => error !== file.name)
                      );
                    }}
                  />
                </Box>
              );
            })}
          </Box>
          <Button
            variant="contained"
            sx={imgbutton}
            onClick={() => {
              uploadData();
            }}
          >
            Upload Images
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export default BulkUploadExcelpreviewPage;

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "35px",
  color: "#525252",
  width: "100%",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const parentbox = {
  height: "150px",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  my: 1,
};

const childbox = {
  height: "140px",
  width: "30%",
};

const imgbutton = {
  height: "40px",
  fontSize: "16px",
  textTransform: "none",
  width: "auto",
  background: "#c64091",
  border: "1px solid transparent",
  "&:hover": {
    background: "#fff",
    color: "#c64091",
    cursor: "pointer",
    border: "1px solid #c64091",
  },
};

const ImageSelectBoxStyle = {
  border: "2px dashed #c64091",
  background: "#fff",
  width: "80%",
  maxWidth: "670px",
  p: "4%",
  mx: "auto",
  position: "relative",
};

const DisplayGridStyle = {
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  tetxAlign: "center",
  mx: "auto",
};

const ImageBox = {
  display: "flex",
  justifyContent: "center",
  position: "absolute",
  left: "5%",
  textalign: "center",
};

const TextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "10px",
  textTransform: "none",
  color: "#6B7A99",
};

const MulishFontTextStyle = {
  fontFamily: "Mulish",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "8px",
  color: "#676767",
  textAlign: "center",
};
