import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  Select,
  MenuItem,
} from "@mui/material";

import { makeStyles, styled } from "@mui/styles";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDebounce } from "react-use";
import Checkbox from "@mui/material/Checkbox";
import "react-toastify/dist/ReactToastify.css";
import Stepper from "../../components/Stepper";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import mainImg from "../../assets/Images/register/createacc.svg";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { RegisterValidation } from "../../validation/auth/register";
import { useCreateAccount, useRocSearch } from "../../Hooks/Auth";
import * as StylesOnBoarding from "../../components/common/Styled/StylesOnBoarding.js";
import MainLogo from "./MainLogo.jsx";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
  select: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
});

const longText = `
8 character minimum, One Number, One Special character, One Lowercase character, One uppercase character
`;

const longMailText = `
Once registered, this will be the default email and cannot be changed.
`;

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const CreateAccount = () => {
  const [open, setOpen] = React.useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [check, setCheck] = useState(false);
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [companyTypeData, setCompanyTypeData] = useState([]);
  const classes = useStyles();

  const InputFieldsData = [
    {
      label: "Select Company Type",
      inputType: "select",
      name: "companyType",
      placeholder: "Select Company Type",
    },
    {
      label: "Mobile No",
      inputType: "input",
      name: "phoneNumber",
      maxLength: 10,
    },
    {
      label: "Email Address",
      inputType: "input",
      name: "email",
    },
    {
      label: "Password",
      inputType: showPass ? "input" : "password",
      name: "password",
      maxLength: 16,
      minLength: 8,
    },
    {
      label: "Confirm Password",
      inputType: showConfirmPass ? "input" : "password",
      name: "confirmPassword",
      maxLength: 16,
      minLength: 8,
    },
  ];

  // open Modal for Important Information for every time
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleClickOpen();
  }, []);

  // Api create Account
  const { mutate, isLoading, reset, error: RegisterError } = useCreateAccount();

  // Api RocSeacrch
  const {
    mutate: RocSearch,
    data: companyData,
    isLoading: RocLoading,
    error: RocSearchError,
  } = useRocSearch();

  const [isReady] = useDebounce(
    () => {
      RocSearch({ name: companyName });
    },
    1000,
    [companyName]
  );

  // here we use useForm
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(RegisterValidation),
  });

  // here we get company name
  useEffect(() => {
    const compnay = companyData?.data?.find(
      (e) => e.companyName === companyName
    );

    if (!compnay) return;
    setValue("companyName", companyName);
    setValue("cin", compnay?.companyID);
  }, [companyName]);

  // submit the form
  const createCompany = handleSubmit((data) => {
    let email = data.email;

    mutate(
      { ...data, email },
      {
        onSuccess: (response) => {
          const companyId = response?.data?.newCompany?._id;
          const companyName = response?.data?.newCompany?.companyName;

          const searchParams = new URLSearchParams();
          searchParams.append("id", companyId);
          searchParams.append("email", email);
          searchParams.append("companyName", companyName);

          const queryString = searchParams.toString();

          const url = `/otp?${queryString}`;

          navigate(url);
        },

        onError: (error) => {
          toast.error(
            error.response?.data?.message ??
              "Issue Going Forward Please Try Again Later",
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          reset();
        },
      }
    );
  });

  // here we get Company Type data Get Api
  const getCompanyType = async () => {
    return await axios
      .get("company_type/get_companyTypes")
      .then((res) => {
        setCompanyTypeData(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCompanyType();
  }, []);

  return (
    <form onSubmit={createCompany}>
      <ToastContainer style={{ fontSize: "16px" }} />
      <Paper elevation={0} sx={StylesOnBoarding.mainCommonPage}>
        <Box>
          <Stepper />
        </Box>
        <Grid
          container
          sx={{
            background: "#fff",
            height: "95vh",
            width: {
              xl: "110%",
              lg: "110%",
              md: "110%",
              sm: "100%",
              xs: "100%",
            },
          }}
        >
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: 8,
            }}
          >
            <MainLogo />
            <Typography sx={StylesOnBoarding.login}>
              Create Account
            </Typography>{" "}
            <Box mt={4} sx={StylesOnBoarding.AllInputBoxes}>
              {/* here all input Box map*/}
              <Typography
                sx={{
                  ...StylesOnBoarding.signupLableStyle,
                  marginBottom: "-05px",
                }}
              >
                Company Name
              </Typography>
              <ThemeProvider theme={outerTheme}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  loading={!isReady() || RocLoading}
                  options={
                    companyData
                      ? companyData?.data?.map((e) => ({
                          label: e.companyName,
                          id: e.companyID,
                        }))
                      : []
                  }
                  onInputChange={(event, newInputValue, reason) => {
                    setCompanyName(newInputValue);
                  }}
                  sx={{
                    ...StylesOnBoarding.inputBox,
                    width: "420px",
                    minWidth: "420px",
                    maxWidth: "420px",
                    border: "1px solid #445FD2",
                  }}
                  renderInput={(params) => (
                    <TextField
                      className={classes.textField}
                      size="small"
                      {...params}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderRadius: "15px",
                          height: "45px",
                          padding: "0 0px",
                        },
                        background: "#FFF",
                        borderRadius: "15px",
                      }}
                      // label="Company Name"
                    />
                  )}
                />
                {InputFieldsData?.map((el, idx) => {
                  if (el.inputType === "select") {
                    return (
                      <div key={idx} style={{ height: "auto" }}>
                        <CustomDropdown
                          {...register(el.name)}
                          array={companyTypeData}
                          placeholder="Select Company Type"
                          sx={{
                            bgcolor: "red",
                            borderRadius: "15px",
                            height: "45px",
                          }}
                        />

                        <Typography sx={Typo}>
                          {errors[el.name]?.message}
                        </Typography>
                      </div>
                    );
                  } else {
                    const isPassword = el.label === "Password";
                    const isConfirmPassword = el.label === "Confirm Password";
                    const isEmail = el.label === "Email Address";
                    const showField = isPassword
                      ? showPass
                      : isConfirmPassword
                      ? showConfirmPass
                      : false;
                    return (
                      <div
                        key={idx}
                        style={{
                          height:
                            isPassword || isConfirmPassword ? "70px" : "60px",
                          position: "relative",
                        }}
                      >
                        <Typography
                          sx={{
                            ...StylesOnBoarding.signupLableStyle,
                            mb: "15px",
                            position: "relative",
                          }}
                        >
                          {el.label + "*"}{" "}
                          {isPassword || isConfirmPassword ? (
                            <CustomWidthTooltip title={longText}>
                              <Button
                                sx={{
                                  height: "0px",
                                  width: "0px",
                                  p: "0px",
                                  m: "0px",
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "0px",
                                  left: isPassword
                                    ? "15%"
                                    : isConfirmPassword
                                    ? "30%"
                                    : null,
                                }}
                              >
                                <InfoIcon fontSize="small" color="info" />{" "}
                              </Button>
                            </CustomWidthTooltip>
                          ) : isEmail ? (
                            <CustomWidthTooltip title={longMailText}>
                              <Button
                                sx={{
                                  height: "0px",
                                  width: "0px",
                                  p: "0px",
                                  m: "0px",
                                  cursor: "pointer",
                                  position: "absolute",
                                  top: "0px",
                                  left: isEmail ? "23%" : null,
                                }}
                              >
                                <InfoIcon fontSize="small" color="info" />{" "}
                              </Button>
                            </CustomWidthTooltip>
                          ) : null}
                        </Typography>
                        <InputField
                          {...register(el.name)}
                          inputType={showField ? "text" : el.inputType}
                          title={""}
                          placeholder={el.label}
                          sx={{
                            borderRadius: "15px",
                            paddingRight: "40px",
                            height: "45px",
                          }}
                          maxLength={el.maxLength}
                          minLength={el.minLength}
                        />
                        <Typography sx={Typo}>
                          {errors[el.name]?.message}
                        </Typography>
                        {(isPassword || isConfirmPassword) && (
                          <Button
                            sx={StylesOnBoarding.signupEyeStyle}
                            onClick={() => {
                              if (isPassword) {
                                setShowPass(!showPass);
                              } else if (isConfirmPassword) {
                                setShowConfirmPass(!showConfirmPass);
                              }
                            }}
                          >
                            {showField ? <FaEye /> : <FaEyeSlash />}
                          </Button>
                        )}
                      </div>
                    );
                  }
                })}
              </ThemeProvider>
              <Grid
                container
                mt={0}
                sx={{
                  width: "80%",
                  marginLeft: 0,
                  minWidth: {
                    xl: "425px",
                    lg: "400px",
                    md: "300px",
                    sm: "350px",
                    xs: "250px",
                  },
                  marginRight: "auto",
                  mt: -1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={StylesOnBoarding.CommonBtn}
                >
                  {isLoading ? (
                    <CircularProgress
                      size="20px"
                      sx={{ color: "white", width: "40%" }}
                    />
                  ) : (
                    "Next"
                  )}
                </Button>

                <Dialog
                  open={open}
                  sx={{
                    backdropFilter: "blur(1px)",
                  }}
                  fullWidth
                  maxWidth="lg"
                  PaperProps={{
                    sx: DialogueStyle,
                  }}
                >
                  <DialogContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <DialogContentText
                      id="alert-dialog-description"
                      sx={{
                        mt: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#445FD2",
                          fontFamily: "Poppins",
                          fontSize: "36px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "44px",
                          textAlign: "center",
                        }}
                      >
                        Important Information
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          mt: "20px",
                        }}
                      >
                        <Typography sx={StylesOnBoarding.DialogText}>
                          Please keep your GST & Company Bank Details Handy
                        </Typography>
                        <Typography sx={StylesOnBoarding.DialogText}>
                          From Here it will take 3 - 5 Minutes to get your
                          account activated.
                        </Typography>{" "}
                        <br />
                        <Typography sx={StylesOnBoarding.DialogText}>
                          <Checkbox
                            onClick={() => setCheck(!check)}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: "30px" },
                              padding: "1px",
                              color: "#6B7A99",
                            }}
                          />
                          &nbsp; "I, on behalf of my company, provide full
                          consent to BXI World LLP/Barter Exchange of India, and
                          its representatives to perform a mandatory background
                          check with no objection or reservations"
                        </Typography>
                      </Box>
                    </DialogContentText>

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      <Button
                        variant="contained"
                        disabled={!check}
                        sx={{
                          width: "60%",
                          textTransform: "none",
                          fontSize: "13px",
                          fontFamily: "Poppins",
                          background: check ? "#445FD2" : "#EAEAEA",
                          color: check ? "#fff" : "#868686",
                          borderRadius: "10px",
                          height: "41px",
                          fontSize: "16px",
                          ":hover": {
                            background: "var(--Primary-D_Blue, #0053AD)",
                          },
                        }}
                        onClick={handleClose}
                      >
                        Continue
                      </Button>
                    </Box>
                  </DialogContent>
                </Dialog>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} mt={1}>
                  <Typography sx={StylesOnBoarding.accountText}>
                    Already Have An Account?{" "}
                    <span
                      style={{
                        color: "rgba(55, 93, 187, 1)",
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: 400,
                      }}
                    >
                      {" "}
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "rgba(55, 93, 187, 1)",
                        }}
                        to="/login"
                      >
                        Login
                      </Link>
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {/* last grid img */}
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: {
                xl: "flex",
                lg: "flex",
                md: "flex",
                sm: "none",
                xs: "none",
              },
            }}
          >
            <img
              src={mainImg}
              alt="img"
              style={StylesOnBoarding.CommonImgStyle}
            />
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

export default CreateAccount;

const InputField = React.forwardRef((props, ref) => {
  return (
    <Box sx={StylesOnBoarding.inputFieldDesign}>
      <Box sx={StylesOnBoarding.inputbox}>
        <label
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 400,
            color: "#6B7A99",
          }}
        >
          {props.title}
        </label>
      </Box>
      <input
        ref={ref}
        {...props}
        type={props.inputType}
        placeholder={props.placeholder}
        autoComplete="off"
        style={{
          ...StylesOnBoarding.inputStyles,
          height: "42px",
          width: "400px",
          border: "1px solid #445FD2",
        }}
      />
    </Box>
  );
});

const CustomDropdown = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        ...StylesOnBoarding.inputFieldDesign,
        ...custom_dropdown_box,
      }}
    >
      <Typography style={{ ...StylesOnBoarding.signupLableStyle }}>
        Company Type
      </Typography>
      <Box sx={StylesOnBoarding.inputbox}></Box>
      <Select
        className={classes.select}
        ref={ref}
        {...props}
        type="text"
        sx={{
          ...StylesOnBoarding.inputStyles,
          height: "45px",
          width: "420px",
          fontSize: "14px",
          border: "1px solid #445FD2",
        }}
      >
        <MenuItem value="" disabled selected hidden>
          {props.placeholder}
        </MenuItem>
        {props.array?.map((el, idx) => {
          return (
            <MenuItem key={idx} value={el?.CompanyTypeName}>
              {el?.CompanyTypeName}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
});

const DialogueStyle = {
  sx: {
    width: {
      xl: "50%",
      lg: "50%",
      md: "80%",
      sm: "90%",
      xs: "90%",
    },
    height: "auto",
    minHeight: "320px",
    maxHeight: "320px",
    borderRadius: "20px",
    justifyContent: "center",
    overflow: "hidden",
  },
};

const custom_dropdown_box = {
  minWidth: {
    xl: "425px",
    lg: "400px",
    md: "300px",
    sm: "350px",
    xs: "250px",
  },
  maxWidth: {
    xl: "430px",
    lg: "400px",
    md: "280px",
    sm: "350px",
    xs: "250px",
  },
};

const Typo = {
  ml: 1,
  mt: 0.5,
  fontSize: "10px",
  color: "red",
};

const outerTheme = createTheme({
  palette: {
    primary: {
      main: "#6B7A99",
    },
  },
  typography: {
    fontSize: 20,
  },
});
