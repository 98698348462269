import Box from "@mui/material/Box";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import Button from "@mui/material/Button";
import React, { useEffect, useRef, useState } from "react";
import call from "../../assets/Images/call.png";
import Modal from "@mui/material/Modal";
import { Paper, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import LogoutButton from "../../assets/SidebarIcon/Logout.png";
import MenuIcon from "../../assets/SidebarIcon/Menu.png";
import Seller_Menu_Icon from "../../assets/SidebarIcon/Seller_Menu_Icon.png";
import axios from "axios";
import { motion } from "framer-motion";
import { isError } from "react-query";
import { useLocation } from "react-router-dom";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import newbarterLogo from "../../assets/SidebarIcon/BxiLogo.svg";
import Header from "../Header/Header";
import BarterGPTModal from "./BarterGPTModal";
import sendEvents from "../../utils/sendEvents";
import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import GlobalToast from "../Toasts/GlobalToast";
import { toast } from "react-toastify";

import { SidebarStyle } from "../../styles/sidebar.styles";
import BuyerRoutes from "./BuyerSidebarRoutes";
import SellerSidebarRoutes from "./SellerSidebarRoutes";
import LogoutIcon from "../../assets/SidebarIcon/LogoutIcon.svg";

export default function SidebarMenu() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openCall, setOpenCall] = React.useState(false);
  const handleOpenCall = () => setOpenCall(true);
  const handleCloseCall = () => setOpenCall(false);
  let naviagte = useNavigate();
  let location = useLocation();
  const [state, setState] = React.useState(false);
  const wrapperRef = useRef(null);
  const [useHubTypeData, setUserHubTypeData] = useState("");
  const navigate = useNavigate();

  const {
    data: datahere,
    isLoading: DataLoading,
    error: DataError,
    refetch: refetchData,
    isRefetching,
  } = useGetLoggedInUser();

  const LogoutCompany = async () => {
    await axios
      .get(`/auth/logout`, { withCredentials: true })
      .then((res) => {
        GlobalToast("Logged out successfully", 2000);
      })
      .catch((err) => {
        GlobalToast("Something went wrong", 2000);
      });
  };

  console.log("datahere", datahere);

  const {
    data: companyTypeData,
    isLoading: companyTypeLoading,
    error: companyTypeError,
  } = useGetCompanyTypeData(datahere?.data?.companyType);

  useEffect(() => {
    if (!location.pathname.includes("/terms")) {
      setTimeout(() => {
        if (isRefetching || DataLoading) return;
        if (
          datahere?.data?.companyOnboardingStatus === "UNVERIFIED" ||
          datahere?.data?.companyOnboardingStatus === "GST" ||
          datahere?.data?.companyOnboardingStatus === "BANK_DETAILS" ||
          datahere?.data?.companyOnboardingStatus === "REVERSE_DROP" ||
          datahere?.data?.companyOnboardingStatus === "COURT_CASE" ||
          datahere?.data?.companyOnboardingStatus === "UNDER_REVIEW"
        ) {
          LogoutCompany();
          naviagte("/");
        }
        if (
          location.pathname.includes("/terms") ||
          location.pathname.includes("/payment")
        )
          return;
        if (DataError || datahere?.data === "") {
          naviagte("/");
        }
      }, 2000);
    } else {
      return;
    }
  }, [isError, isRefetching, datahere]);

  useEffect(() => {
    const userhubtypedata = localStorage.getItem("userhubtype");
    setUserHubTypeData(userhubtypedata);
  }, [state]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setState(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const SendEmailCall = async () => {
    await axios
      .post(`AdminCompanyDetails/send_email`, { withCredentials: true })
      .then((res) => {
        handleCloseCall();
        if (res.status === 200) {
          toast.success("Mail Send");
        } else {
          alert("Mail Not Send");
        }
      })
      .catch((err) => {});
  };

  const logOutUser = async () => {
    const confirmed = window.confirm("Are you sure you want to logout?");
    if (confirmed === true) {
      await axios
        .get(`/auth/logout`, { withCredentials: true })
        .then((res) => {
          if (res.data === "Logged out" && res.status === 200) {
            localStorage.clear();
            navigate("/login");
            window.location.reload();
          }
        })
        .catch((err) => {});
    } else {
      return;
    }
    removeUserTypeOnLocalStorage();
  };

  const removeUserTypeOnLocalStorage = () => {
    localStorage.removeItem("userhubtype");
  };

  const handleSidebarState = (props) => {
    setState(props);
  };

  return (
    <Paper sx={SidebarStyle.PaperStyle} elevation={0}>
      {location.pathname.includes("/home/terms") ? null : <Header />}
      {location.pathname.includes("/home/terms") ? null : (
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            zIndex: 10,
            borderRadius: "0px 15px 15px 0px",
          }}
        >
          {datahere?.data?.termsAcceptStatus === true ? (
            state ? (
              <motion.div
                className="box"
                animate={{ x: 0 }}
                transition={{ type: "spring", ease: "easeOut", duration: 2 }}
              >
                <Paper sx={SidebarStyle.MotionPaperStyle} ref={wrapperRef}>
                  <Button
                    onClick={() => {
                      if (state === true) {
                        setState(false);
                      } else {
                        setState(true);
                      }
                      sendEvents("Click on menu");
                    }}
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    sx={SidebarStyle.CloseMenuButton}
                  >
                    <img
                      src={
                        useHubTypeData === "seller"
                          ? Seller_Menu_Icon
                          : MenuIcon
                      }
                      width="100%"
                      height={"auto"}
                    />
                  </Button>
                  <Paper
                    elevation={0}
                    sx={{
                      bgcolor: "transparent",
                      width: "80%",
                      height: "auto",
                      minHeight: "96.5vh",
                      mx: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        bgcolor: "transparent",
                        ml: 5,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        alignContent: "center",
                      }}
                    >
                      <Box
                        onClick={() => {
                          naviagte("/home"), setState(false);
                        }}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "end",
                          flexDirection: "row",
                          gap: "8px",
                          textDecoration: "none",
                          cursor: "pointer",
                          bgcolor: "white",
                        }}
                      >
                        <Box
                          component="img"
                          src={newbarterLogo}
                          alt=""
                          width="40px"
                          onClick={() => {
                            sendEvents("Click on BXI");
                          }}
                        />
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "13px",
                            textAlign: "center",
                            color: "#1D3163",
                            mb: "10px",
                          }}
                        >
                          Barter Exchange of India
                        </Typography>
                      </Box>
                    </Box>
                    {useHubTypeData === "buyer" ? (
                      <BuyerRoutes
                        companyTypeData={companyTypeData}
                        sendDataToParent={handleSidebarState}
                      />
                    ) : (
                      <SellerSidebarRoutes
                        companyTypeData={companyTypeData}
                        sendDataToParent={handleSidebarState}
                      />
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "end",
                        flexDirection: "row",
                        gap: "8px",
                        textDecoration: "none",
                        bgcolor: "transparent",
                      }}
                    >
                      <Button
                        sx={{
                          ...SidebarStyle.ButtonStyle,
                          backgroundColor: "transparent",
                          ":hover": {
                            backgroundColor: "#000",
                            color: "white",
                          },
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "15px",
                          color: "#000",
                          textAlign: "left",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                          width: "100%",
                        }}
                        onClick={() => {
                          logOutUser();
                          sendEvents("Click on logout");
                        }}
                      >
                        <Box
                          component="img"
                          src={LogoutIcon}
                          alt="logout"
                          sx={{
                            height: "30px",
                            width: "auto",
                          }}
                        />
                        Logout
                      </Button>
                    </Box>
                  </Paper>
                </Paper>
              </motion.div>
            ) : (
              <Button
                onClick={() => {
                  setState(true);
                  sendEvents("Click on menu button");
                }}
                disableRipple
                disableTouchRipple
                disableFocusRipple
                sx={{
                  position: "relative",
                  width: "50px",
                  height: "50px",
                  mt: 1,
                  "&:hover": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  },
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box
                  component={"img"}
                  src={
                    useHubTypeData === "seller" ? Seller_Menu_Icon : MenuIcon
                  }
                  sx={{
                    height: "auto",
                    width: {
                      xl: "80%",
                      lg: "80%",
                      md: "80%",
                      sm: "60%",
                      xs: "60%",
                    },
                    mt: { xl: 0, lg: 0, md: 0, sm: -2, xs: -2 },
                  }}
                />
              </Button>
            )
          ) : null}
        </Box>
      )}

      {location.pathname === "/home" ? (
        <Paper
          sx={{
            bgcolor: "transparent",
            borderRadius: "30px",
            maxHeight: "90vh",
            minHeight: "90vh",
            overflowY: "scroll",
          }}
          elevation={0}
        >
          <Outlet />
          <Box
            component="img"
            src={call}
            sx={{
              position: "fixed",
              zIndex: 100,
              width: {
                xl: "68px",
                lg: "68px",
                md: "68px",
                sm: "38px",
                xs: "38px",
              },
              height: {
                xl: "68px",
                lg: "68px",
                md: "68px",
                sm: "38px",
                xs: "38px",
              },
              right: { xl: "2.5%", lg: "2.5%", md: "2.5%", sm: "2%", xs: "2%" },
              bottom: { xl: "5%", lg: "5%", md: "5%", sm: "5%", xs: "5%" },
              cursor: "pointer",
              borderRadius: "50%", // Makes the image round
              boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.2)",
            }}
            onClick={() => {
              handleOpenCall();
              sendEvents("Click on call");
            }}
          />
          <Modal
            open={openCall}
            onClose={handleCloseCall}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={SidebarStyle.styleCall}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  textAlign: "center",
                  flexDirection: "column",
                  gap: "10px",
                  p: 1,
                }}
              >
                <Typography
                  sx={{
                    ...SidebarStyle.TextStyle,
                    lineHeight: {
                      xl: "25px",
                      lg: "25px",
                      md: "25px",
                      sm: "20px",
                      xs: "20px",
                    },
                    mt: 1,
                    p: { xl: 5, lg: 5, md: 5, sm: 2, xs: 2 },
                  }}
                >
                  Need Help? Get a callback from a BXI Support team.
                </Typography>
                <Button
                  variant="contained"
                  sx={{ mb: 4 }}
                  onClick={() => {
                    SendEmailCall();
                  }}
                >
                  Request Callback
                </Button>
              </Box>
            </Box>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={SidebarStyle.Barterstyle}>
              <BarterGPTModal modelopen={handleClose} />
            </Box>
          </Modal>
        </Paper>
      ) : (
        <Paper sx={SidebarStyle.OutLetParentStyle} elevation={0}>
          <Box sx={SidebarStyle.OutLetBoxStyle}>
            <Outlet />
            <Box
              component="img"
              src={call}
              sx={{
                position: "fixed",
                zIndex: 100,
                width: {
                  xl: "68px",
                  lg: "68px",
                  md: "68px",
                  sm: "38px",
                  xs: "38px",
                },
                height: {
                  xl: "68px",
                  lg: "68px",
                  md: "68px",
                  sm: "38px",
                  xs: "38px",
                },
                right: { xl: "3%", lg: "3%", md: "3%", sm: "2%", xs: "2%" },
                bottom: {
                  xl: "18%",
                  lg: "18%",
                  md: "18%",
                  sm: "12%",
                  xs: "12%",
                },
                cursor: "pointer",
                borderRadius: "50%",
                boxShadow: "0px 0px 8px 2px rgba(0, 0, 0, 0.2)",
              }}
              onClick={() => {
                handleOpenCall();
                sendEvents("Click on call");
              }}
            />
            <Modal
              open={openCall}
              onClose={handleCloseCall}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={SidebarStyle.styleCall}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    textAlign: "center",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      ...SidebarStyle.TextStyle,
                      lineHeight: "25px",
                      mt: 1,
                      p: 5,
                    }}
                  >
                    Thank you for requesting a callback from BXI. A relationship
                    manager from our team would call you in the next 4-12 hours.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ mb: 4 }}
                    onClick={() => {
                      SendEmailCall();
                    }}
                  >
                    Connect Us
                  </Button>
                </Box>
              </Box>
            </Modal>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={SidebarStyle.Barterstyle}>
                <BarterGPTModal modelopen={handleClose} />
              </Box>
            </Modal>
          </Box>
        </Paper>
      )}
    </Paper>
  );
}
