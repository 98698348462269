import React from 'react';
import { Box, Grid, Typography, Paper } from '@mui/material';
import * as Styles from '../Styles';
import { Fade } from 'react-reveal';
import sell from '../../../assets/marketplaceicons/benfiticon/sell.svg';
import earn from '../../../assets/marketplaceicons/benfiticon/earn.svg';
import business from '../../../assets/marketplaceicons/benfiticon/business.svg';
import network from '../../../assets/marketplaceicons/benfiticon/network.svg';
import cash from '../../../assets/marketplaceicons/benfiticon/cash.svg';
import buy from '../../../assets/marketplaceicons/benfiticon/buy.svg';
import multiple from '../../../assets/marketplaceicons/benfiticon/multiple.svg';
import save from '../../../assets/marketplaceicons/benfiticon/save.svg';
import best from '../../../assets/marketplaceicons/benfiticon/best.svg';
import credit from '../../../assets/marketplaceicons/benfiticon/credit.svg';
const Benefits = () => {
  const sellerBenefits = [
    { icon: sell, text: 'Sell your products/services' },
    { icon: network, text: 'Network' },
    { icon: business, text: 'New business development' },
    { icon: cash, text: 'New cash flow' },
    { icon: earn, text: 'Earn trade credits' },
  ];

  const buyerBenefits = [
    { icon: buy, text: 'Buy what you want' },
    { icon: multiple, text: 'Multiple categories to choose from' },
    { icon: save, text: 'Save cash flow' },
    { icon: best, text: 'Get the best deals' },
    { icon: credit, text: 'Buy with Trade credits' },
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        mt: 5,
        position: 'relative',
        overflow: 'hidden',
        padding: 5,
      }}
    >
      {/* Adding background image */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%', // Make the width dynamic
          backgroundImage: `url(${'https://bxilanding-page.s3.ap-south-1.amazonaws.com/image+4.svg'})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain', // Use 'contain' to preserve the aspect ratio
          backgroundPosition: 'left', // Ensure it aligns to the left
          zIndex: 0,
          display: {
            xl: 'block',
            lg: 'block',
            md: 'none',
            sm: 'none',
            xs: 'none',
          },
        }}
      />

      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Box>
          <Box
            sx={{
              width: '95%',
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Fade top duration={1000}>
              <Typography sx={Styles.headTextColor}>
                <span style={{ color: 'rgba(180, 78, 161, 1)' }}>
                  Seller&nbsp;
                </span>
                Benefits
              </Typography>
            </Fade>
            <Box mt={1} sx={Styles.sellerBox}>
              {sellerBenefits?.map((item, index) => (
                <Box sx={Styles.insideCategoryBox} key={index}>
                  <Box
                    component={'img'}
                    src={item?.icon}
                    sx={{ maxWidth: '30px', height: 'auto' }}
                  />
                  <Typography sx={Styles.categoryText}>{item?.text}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              width: '95%',
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              mt: 8,
            }}
          >
            <Fade top duration={1000}>
              <Typography
                sx={{
                  ...Styles.headTextColor,
                  textAlign: {
                    xl: 'right',
                    lg: 'right',
                    md: 'right',
                    sm: 'center',
                    xs: 'center',
                  },
                }}
              >
                <span style={{ color: 'rgba(68, 95, 210, 1)' }}>
                  Buyer&nbsp;
                </span>
                Benefits
              </Typography>
            </Fade>
            <Box
              mt={1}
              sx={{
                ...Styles.sellerBox,
                ml: 'auto',
                justifyContent: {
                  xl: 'flex-end',
                  lg: 'flex-end',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
              }}
            >
              {buyerBenefits?.map((item, index) => (
                <Box sx={Styles.insideCategoryBox} key={index}>
                  <Box
                    component={'img'}
                    src={item?.icon}
                    sx={{ maxWidth: '30px', height: 'auto' }}
                  />
                  <Typography sx={Styles.categoryText}>{item?.text}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Benefits;
