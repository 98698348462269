import {
  Box,
  Button,
  Grid,
  Pagination,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommaSeparator from "../../components/CommaSeprator.js";
import CompanyName from "../../components/CompanyName";
import BreadCrumbHeader from "../../components/Header/BreadCrumbHeader";
import axios from "axios";
import SkeletonComponent from "../../components/SkeletonComponent.jsx";
import NoDataFound from "../../components/NoDataFound/NoDataFound.jsx";
import sendEvents from "../../utils/sendEvents.js";
import { toast } from "react-toastify";
import GlobalToast from "../../components/Toasts/GlobalToast.jsx";
import { FaFilePdf } from "react-icons/fa";

const CommissionList = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#c64091",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#c64091",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#c64091",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = React.useState(1);
  const [loadingInvoice, setLoadingInvoice] = React.useState(true);
  const [paginationCount, setPaginationCount] = React.useState(0);
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const handleClick = (OrderId) => {
    navigate(`/home/commission/` + OrderId);
  };

  const [CommissionData, setCommissionData] = useState();

  const getAllOrder = async () => {
    await axios
      .get(`commission/get_all_bxi_commision_invoice?page=${currentPage}`)
      .then((res) => {
        setCommissionData(res.data?.Data);
        setPaginationCount(Math.ceil(res.data.DataCount / 10));
      })
      .catch((err) => {});
  };

  const make_commission_payment = async (OrderData) => {
    const totalAmount =
      Number(OrderData?.INRDetails?.TDS) +
      Number(
        OrderData?.INRDetails?.bxiCommisionGST ||
          OrderData?.INRDetails?.BxiCommisionGST
      ) +
      Number(
        OrderData?.INRDetails?.totalBxiCommission ||
          OrderData?.INRDetails?.TotalBxiCommission
      );
    if (totalAmount > 1) {
      await axios
        .post(`juspay/bxi_commision_payment`, {
          OrderId: OrderData?.OrderId,
        })
        .then((res) => {
          window.open(res?.data?.paymentURL, "_blank");
        })
        .catch((err) => {
          alert("Failed to make payment");
        });
    } else {
      toast.error("Minimum 1 rupee is required for payment", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const MakeWalletTransaction = async (OrderData) => {
    await axios
      .post(`wallet/release_onhold_transaction`, {
        order_number: OrderData?.OrderId,
        OrderId: OrderData?._id,
      })
      .then((resss) => {})
      .catch((err) => {
        GlobalToast("Wallet Transaction Release Error", err);
      });
  };

  useEffect(() => {
    getAllOrder();
  }, [currentPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingInvoice(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <BreadCrumbHeader MainText={"BXI Payouts"} showbreadcrumb={true} />

        {loadingInvoice ? (
          <>
            <SkeletonComponent />
          </>
        ) : (
          <>
            <Grid container>
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "17px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  padding: "2rem",
                }}
                gap={2}
              >
                <TableContainer sx={{ minWidth: 650, "& td": { border: 0 } }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" sx={tablehead}>
                          No
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Date
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Buyer Name
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Order Id
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Product Name
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          GSTAmount
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          TDS
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Amount
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Total Amount
                        </TableCell>
                        <TableCell align="left" sx={tablehead}></TableCell>
                        <TableCell align="left" sx={tablehead}></TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Pay Dues
                        </TableCell>
                        <TableCell align="left" sx={tablehead}>
                          Invoice
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {CommissionData?.length > 0 ? (
                        CommissionData?.map((el, idx) => {
                          const OrderData = el?.orders_result;
                          if (OrderData) {
                            const releaseTime = new Date(
                              OrderData.escrowBXIReleaseResponseTime
                            );
                            const cutoffDate = new Date(
                              "2024-04-12T00:00:00.000Z"
                            ); // ISO 8601 format
                            const isEscrowReleasedAfterCutoff =
                              releaseTime > cutoffDate;
                            const isJuspayCharged =
                              el?.JuspayResponse?.status === "CHARGED";
                            const isWalletTransactionPending =
                              !OrderData.Wallet_transaction_status;

                            if (
                              isEscrowReleasedAfterCutoff &&
                              isJuspayCharged &&
                              isWalletTransactionPending
                            ) {
                              MakeWalletTransaction(OrderData);
                            }
                          }

                          console.log("CommissionData", el);
                          return (
                            <>
                              <TableRow>
                                <TableCell align="left">
                                  {" "}
                                  <Typography sx={tableText}>
                                    {idx + 1}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography sx={tableText}>
                                    {new Date(el?.createdAt).toLocaleDateString(
                                      "en-IN"
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                    gap={2}
                                  >
                                    <Box>
                                      <Typography sx={tableText}>
                                        <CompanyName
                                          CompanyId={
                                            el?.BuyerDetails?.BuyerCompanyId
                                          }
                                          CompanyName={
                                            el?.BuyerDetails?.BuyerCompanyName
                                          }
                                        />
                                      </Typography>
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography sx={tableText}>
                                    {el?.OrderId}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography sx={tableText}>
                                    {el?.ProductData?.at(0)?.ProductName}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {" "}
                                  <Typography sx={tableText}>
                                    {" "}
                                    <CommaSeparator
                                      Price={el?.Commission?.CommissionGST}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  {" "}
                                  <Typography sx={tableText}>
                                    {" "}
                                    <CommaSeparator
                                      Price={OrderData?.INRDetails?.TDS}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography sx={tableText}>
                                    <CommaSeparator
                                      Price={el?.Commission?.CommissionPrice}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography sx={tableText}>
                                    <CommaSeparator
                                      Price={
                                        el?.Commission?.CommissionPrice +
                                        OrderData?.INRDetails?.TDS +
                                        el?.Commission?.CommissionGST
                                      }
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography sx={tableText}>
                                    {el?.TotalTaxValue}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left"></TableCell>
                                <TableCell align="left">
                                  {!el?.JuspayResponse ||
                                  el?.JuspayResponse?.status !== "CHARGED" ? (
                                    <Button
                                      size="large"
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#c64091",
                                        textTransform: "none",
                                        fontSize: "12px",
                                        "&:hover": {
                                          backgroundColor: "#c64091",
                                        },
                                      }}
                                      onClick={() => {
                                        make_commission_payment(OrderData);
                                        sendEvents("Click on edit");
                                      }}
                                    >
                                      Pay Dues
                                    </Button>
                                  ) : null}
                                </TableCell>

                                <TableCell align="left">
                                  <Button
                                    size="large"
                                    variant="contained"
                                    sx={{
                                      backgroundColor: "transparent",
                                      textTransform: "none",
                                      height: "38px",
                                      width: "70px",
                                      fontSize: "22px",
                                      fontWeight: "600",
                                      color: "#c64091",
                                      "&:hover": {
                                        variant: "outlined",
                                        background: "transparent",
                                      },
                                    }}
                                    onClick={() => {
                                      handleClick(el._id);
                                      sendEvents("Click on view");
                                    }}
                                  >
                                    <FaFilePdf />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })
                      ) : (
                        <TableRow width="100%">
                          <TableCell colSpan={12} align="center">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                              }}
                            >
                              <NoDataFound />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {CommissionData?.length > 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
                <Stack spacing={2}>
                  <StyledPagination
                    count={paginationCount}
                    color="primary"
                    page={currentPage}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                  />
                </Stack>
              </Box>
            ) : null}
          </>
        )}
      </Paper>
    </>
  );
};

export default CommissionList;
const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
  color: "#c64091",
};

const tableText = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  color: "#1B212D",
};
