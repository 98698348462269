import React from "react";
import { Box, Grid, Typography, Button, Paper } from "@mui/material";
import * as Styles from "../Styles";
import Benfits from "./Benfits";
import Trust from "./Trust";
import PricingDetailMarketplace from "../../../pages/Register/PricingDetailMarketplace";
import buysellsmallscreen from "../../../assets/HomePageImages/buysellsmallscreen.svg";
import textTileicon from "../../../assets/marketplaceicons/commonimg/textile.svg";
import lifestyle from "../../../assets/marketplaceicons/commonimg/lifestyle.svg";
import media from "../../../assets/marketplaceicons/commonimg/media.svg";
import office from "../../../assets/marketplaceicons/commonimg/officesupply.svg";
import electronics from "../../../assets/marketplaceicons/commonimg/electronics.svg";
import hotel from "../../../assets/marketplaceicons/commonimg/hotel.svg";
import qsr from "../../../assets/marketplaceicons/commonimg/qsr.svg";
import airline from "../../../assets/marketplaceicons/commonimg/airline.svg";
import entertenment from "../../../assets/marketplaceicons/commonimg/entertenment.svg";
import fmcg from "../../../assets/marketplaceicons/commonimg/fmcg.svg";
import mobility from "../../../assets/marketplaceicons/commonimg/mobility.svg";
import other from "../../../assets/marketplaceicons/commonimg/other.svg";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
const NewMarketplace = () => {
  const navigate = useNavigate();
  const categories = [
    {
      name: "Textile",
      image: textTileicon,
    },
    {
      name: "Lifestyle",
      image: lifestyle,
    },
    {
      name: "Media",
      image: media,
    },
    {
      name: "Office Supply",
      image: office,
    },
    {
      name: "Electronics",
      image: electronics,
    },
    {
      name: "Hotel",
      image: hotel,
    },
    {
      name: "QSR",
      image: qsr,
    },
  ];
  const categories2 = [
    {
      name: "Airlines Tickets",
      image: airline,
    },
    {
      name: "Entertainment & Events",
      image: entertenment,
    },
    {
      name: "FMCG",
      image: fmcg,
    },
    {
      name: "Mobility",
      image: mobility,
    },
    {
      name: "Others",
      image: other,
    },
  ];
  const row1 = [
    {
      name: "Textile",
      image: textTileicon,
    },
    {
      name: "Lifestyle",
      image: lifestyle,
    },
    {
      name: "Media",
      image: media,
    },
    {
      name: "Office Supply",
      image: office,
    },
  ];
  const row2 = [
    {
      name: "Electronics",
      image: electronics,
    },
    {
      name: "Hotel",
      image: hotel,
    },
    {
      name: "Mobility",
      image: mobility,
    },
    {
      name: "Airlines Tickets",
      image: airline,
    },
  ];
  const row3 = [
    {
      name: "FMCG",
      image: fmcg,
    },
    {
      name: "Entertainment & Events",
      image: entertenment,
    },
    {
      name: "QSR",
      image: qsr,
    },
    {
      name: "Others",
      image: other,
    },
  ];

  const handleClick = (name) => {
    navigate("/explore", {
      state: {
        label: name,
      },
    });
  };

  return (
    <Paper elevation={0} sx={{ mt: 10 }}>
      <Box sx={{ width: "90%", mx: "auto" }}>
        <Typography
          sx={{
            ...Styles.smallHeadText,
            textAlign: {
              xl: "left",
              lg: "left",
              md: "center",
              sm: "center",
              xs: "left",
            },
            width: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
              xs: "91%",
            },
            mx: "auto",
          }}
        >
          ACTIVE Categories
        </Typography>
        <Typography
          sx={{
            ...Styles.headText,
            width: { xl: "40%", lg: "40%", md: "40%", sm: "100%", xs: "100%" },
          }}
        >
          Explore Inventory Available on the{" "}
          <span style={{ color: " rgba(108, 108, 162, 1)" }}> Marketplace</span>
        </Typography>
      </Box>
      {/*  */}
      <Box
        sx={{
          width: "100%",
          background: "rgba(108, 108, 162, 0.09)",
          p: { xl: 4, lg: 4, md: 4, sm: 0.5, xs: 0.5 },
          mt: 2,
          height: "auto",
        }}
      >
        <Box sx={caregoriesBoxStyle}>
          {categories?.map((item, index) => (
            <Box
              sx={Styles.insideCategorySmallBox}
              onClick={() => {
                handleClick(item?.name);
              }}
            >
              <Box component={"img"} src={item?.image} sx={categoriesStyle} />
              <Typography sx={Styles.categoryText}>{item?.name}</Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            ...caregoriesBoxStyle,
            width: { xl: "70%", lg: "70%", md: "70%", sm: "90%", xs: "90%" },
            mt: { xl: 3, lg: 3, md: 3, sm: 1, xs: 1 },
          }}
        >
          {categories2?.map((item, index) => (
            <Box
              sx={Styles.insideCategorySmallBox}
              onClick={() => {
                handleClick(item?.name);
              }}
            >
              <Box component={"img"} src={item?.image} sx={categoriesStyle} />
              <Typography sx={Styles.categoryText}>{item?.name}</Typography>
            </Box>
          ))}
        </Box>
        {/* sm sc */}
        <Box
          sx={{
            width: "96%",
            mx: "auto",
            overflowX: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Box sx={caregoriesSmallBoxStyle}>
            {row1?.map((item, index) => (
              <Box
                sx={Styles.insideCategorySmallBox}
                onClick={() => {
                  handleClick(item?.name);
                }}
              >
                <Box component={"img"} src={item?.image} sx={categoriesStyle} />
                <Typography sx={Styles.categoryText}>{item?.name}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={caregoriesSmallBoxStyle}>
            {row2?.map((item, index) => (
              <Box
                sx={Styles.insideCategorySmallBox}
                onClick={() => {
                  handleClick(item?.name);
                }}
              >
                <Box component={"img"} src={item?.image} sx={categoriesStyle} />
                <Typography sx={Styles.categoryText}>{item?.name}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={caregoriesSmallBoxStyle}>
            {row3?.map((item, index) => (
              <Box
                sx={Styles.insideCategorySmallBox}
                onClick={() => {
                  handleClick(item?.name);
                }}
              >
                <Box component={"img"} src={item?.image} sx={categoriesStyle} />
                <Typography sx={Styles.categoryText}>{item?.name}</Typography>
              </Box>
            ))}
          </Box>
          {/* <Box sx={caregoriesSmallBoxStyle}>
            {row4?.map((item, index) => (
              <Box
                sx={Styles.insideCategorySmallBox}
                onClick={() => {
                  handleClick(item?.name);
                }}
              >
                <Box component={'img'} src={item?.image} sx={categoriesStyle} />
                <Typography sx={Styles.categoryText}>{item?.name}</Typography>
              </Box>
            ))}
          </Box> */}
        </Box>
        {/*  */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: { xl: 4, lg: 4, md: 4, sm: 2, xs: 2 },
            alignItems: "center",
          }}
        >
          <Box
            sx={Styles.ExlporeBtn}
            onClick={() => {
              navigate("/explore");
            }}
          >
            Explore Marketplace
          </Box>
        </Box>
      </Box>
      {/*  */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 5,
          mt: 4,
        }}
      >
        <Fade top duration={1000}>
          <Typography
            sx={{ ...Styles.headText, textAlign: "center", width: "100%" }}
          >
            How to Buy & Sell
          </Typography>
        </Fade>
        <Box
          component={"img"}
          src={
            "https://bxilanding-page.s3.ap-south-1.amazonaws.com/Group+1000003362.svg"
          }
          sx={{
            display: {
              xl: "block",
              lg: "block",
              md: "block",
              sm: "block",
              xs: "none",
            },
            width: { xl: "80%", lg: "80%", md: "70%", sm: "90%", xs: "90%" },
            height: "auto",
            mx: "auto",
          }}
        />
        <Box
          component={"img"}
          src={buysellsmallscreen}
          sx={{
            display: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "block",
            },
            width: { xl: "80%", lg: "80%", md: "70%", sm: "90%", xs: "90%" },
            height: "auto",
            mx: "auto",
          }}
        />
      </Box>
      <Benfits />
      <Trust />
      <PricingDetailMarketplace />
    </Paper>
  );
};

export default NewMarketplace;

const categoriesStyle = {
  maxWidth: { xl: "40px", lg: "40px", md: "40px", sm: "16px", xs: "16px" },
  height: "auto",
};

const caregoriesBoxStyle = {
  width: "90%",
  mx: "auto",
  display: {
    xl: "flex",
    lg: "flex",
    md: "flex",
    sm: "none",
    xs: "none",
  },
  justifyContent: "space-around",
  gap: 2,
  flexWrap: "wrap",
};
const caregoriesSmallBoxStyle = {
  width: "100%",
  mx: "auto",
  display: {
    xl: "none",
    lg: "none",
    md: "none",
    sm: "flex",
    xs: "flex",
  },
  justifyContent: "flex-start",
  gap: 2,
  flexWrap: "nowrap",
  // ml: 0.15,
};
