import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import GetCompanyNameNProfile from "../common/GetCompanyNameNProfile";
import TransactionsCommaSeprator from "../TransactionsCommaSeprator";
import Skeleton from "@mui/material/Skeleton";

function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

const formatDateTime = (dateTimeString) => {
  const createdAtDateTime = new Date(dateTimeString);
  const timeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedTime = createdAtDateTime.toLocaleTimeString(
    "en-US",
    timeFormatOptions
  );

  return `${formattedTime}`;
};

const DashboardWalletStatement = ({
  WalletData,
  userData,
  dataLoading,
  type,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
      }}
    >
      <TableContainer sx={TableContainerStyle}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={TableTitleStyle}>Date</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Company Name</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Order Number</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Type</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Debit</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Credit</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>TDS</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Account Balance</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Credit Due Balance</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>
                  Wallet Usable Balance
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Other</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>BXI Commission</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Assessable Value</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>GST</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>
                  Total Invoice Value
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>TDS</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>
                  Net Receivable/(Payable)
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>INR</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Coins</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>TDS (26AS)</Typography>
              </TableCell>
              <TableCell style={{ width: "300px" }}>
                <Typography sx={TableTitleStyle}>Invoice Value</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {dataLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={21}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {WalletData?.slice(0, 5)?.map((row, index) => {
                const wallet_release = type !== "pending" ? true : false;

                let price;
                if (
                  row._from_wallet_id === "admin" ||
                  row._from_wallet_id === userData?.data?._id ||
                  row?.type === "refund"
                ) {
                  price = row._from_wallet_balance;
                } else {
                  price = row._to_wallet_balance;
                }
                const getTotalGST = (row) => {
                  if (
                    row?._from_wallet_id !== "admin" ||
                    row?._type === "add_coupon" ||
                    row?._type === "sell_coupon"
                  ) {
                    if (
                      row?.OrderData?.BuyerChoosedTransportation
                        ?.TransportationType === "Seller"
                    ) {
                      return (
                        (row?.INRDetails?.totalTransportationCostGST ?? 0) +
                        (row?.INRDetails?.bxiCommisionGST ?? 0) +
                        (row?.INRDetails?.totalGstAmount ?? 0) +
                        (row?.INRDetails?.InsuranceCostGstValue ?? 0) +
                        (row?.INRDetails?.bxiCommisionOnTransportationGST ??
                          0) +
                        (row?.INRDetails?.totalGstInrOfAdCost ?? 0) +
                        (row?.INRDetails?.packagingCostGSTValue ?? 0)
                      );
                    } else if (row?.OrderData?.IsMedia) {
                      return (
                        row?.INRDetails?.BxiCommisionGST +
                        row?.INRDetails?.TotalGstAmount +
                        row?.INRDetails?.TotalGstInrOfAdCost
                      );
                    } else {
                      return (
                        (row?.INRDetails?.bxiCommisionGST ?? 0) +
                        (row?.INRDetails?.totalGstAmount ?? 0) +
                        (row?.INRDetails?.totalGstInrOfAdCost ?? 0)
                      );
                    }
                  } else {
                    return null;
                  }
                };
                const tds_amount = row?.INRDetails?.TDS;

                let Amount = row?._balance + tds_amount;

                const getTotalBalance = (row) => {
                  if (
                    row?.OrderData?.BuyerChoosedTransportation
                      ?.TransportationType === "Seller"
                  ) {
                    return (
                      (Amount ?? 0) +
                      (row?.INRDetails?.totalTransportationCost ?? 0) +
                      (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
                      (row?.INRDetails?.InsuranceCost ?? 0) +
                      (row?.INRDetails?.packagingCost ?? 0) +
                      (row?.INRDetails?.totalBxiCommission ?? 0)
                    );
                  } else if (row?.OrderData?.IsMedia) {
                    return (
                      (Amount ?? 0) +
                      (row?.INRDetails?.TotalValueInrOfAdCost ?? 0) +
                      (row?.INRDetails?.TotalBxiCommission ?? 0)
                    );
                  } else {
                    return (
                      (Amount ?? 0) +
                      (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
                      (row?.INRDetails?.totalBxiCommission ?? 0)
                    );
                  }
                };

                if (row?._type?.includes("credit_line")) {
                  return (
                    <Credit_line_TableBody
                      index={index}
                      data={row}
                      userData={userData}
                    />
                  );
                } else if (row?._type === "add_coupon") {
                  return (
                    <Private_deal_admin_to_buyer
                      index={index}
                      data={row}
                      userData={userData}
                    />
                  );
                } else {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        borderBottom: "1px solid green",
                      }}
                    >
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: "black",
                          }}
                        >
                          {row._from_wallet_id === userData?.data?._id
                            ? convertDate(row?._created_at)
                            : convertDate(row?._release_date)}
                          <br />
                          at{" "}
                          {row._from_wallet_id === userData?.data?._id
                            ? formatDateTime(row?._created_at)
                            : formatDateTime(row?._release_date)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <GetCompanyNameNProfile
                            CompanyId={
                              row?._from_wallet_id === userData?.data?._id
                                ? row?._to_wallet_id
                                : row?._from_wallet_id
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          {row?._order_number?.includes("BXI")
                            ? row?._order_number
                            : row?._clnumber || row?._order_number}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          {row?._type === "sell_recover_coupon"
                            ? "Sell/PrivateDeal"
                            : row?._type === "recover_coupon"
                            ? "Buy/PrivateDeal"
                            : row?._type === "sell_coupon" &&
                              row?._from_wallet_id !== userData?.data?._id
                            ? "Sell/PrivateDeal"
                            : row?._type === "sell_coupon"
                            ? "Buy/PrivateDeal"
                            : row?._type === "add_coupon"
                            ? "Buy/PrivateDeal"
                            : row?._type === "refund"
                            ? "Refund"
                            : row?._type?.includes("credit_line")
                            ? "Credit Line"
                            : row?._from_wallet_id === userData?.data?._id
                            ? "Buy"
                            : "Sell"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              row?._from_wallet_id === userData?.data?._id &&
                              row?._type === "refund"
                                ? null
                                : row?._from_wallet_id ===
                                    userData?.data?._id &&
                                  row?._type !== "recover_coupon"
                                ? Amount
                                : row?._type === "credit_line_recovery"
                                ? Amount
                                : null
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              row?._from_wallet_id === userData?.data?._id &&
                              row?._type === "refund"
                                ? Amount
                                : row?._from_wallet_id !== userData?.data?._id
                                ? row?._type === "add_coupon"
                                  ? Amount
                                  : row?._from_wallet_id === "admin" &&
                                    row?._type === "sell_coupon"
                                  ? Amount
                                  : row?._from_wallet_id === "admin"
                                  ? null
                                  : Amount
                                : row?._from_wallet_id ===
                                    userData?.data?._id &&
                                  row?._type === "recover_coupon"
                                ? Amount
                                : null
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={row?.INRDetails?.TDS}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          {price < 0 ? (
                            <>
                              -
                              <TransactionsCommaSeprator
                                Price={Math.abs(price)}
                              />
                            </>
                          ) : (
                            <TransactionsCommaSeprator Price={price} />
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              row?._type === "add_coupon"
                                ? row?._from_wallet_credit_due
                                : row?.type === "refund"
                                ? row?._from_wallet_credit_due
                                : row?._from_wallet_id === "admin" &&
                                  row?._type === "sell_coupon"
                                ? row?._from_wallet_credit_due?.toFixed(2)
                                : row?._from_wallet_id === "admin"
                                ? row?._to_wallet_credit_due?.toFixed(2)
                                : row?._from_wallet_id === userData?.data?._id
                                ? row?._from_wallet_credit_due?.toFixed(2)
                                : row?._to_wallet_credit_due?.toFixed(2)
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              row?._type === "add_coupon"
                                ? row?._from_wallet_usable_balance
                                : row?.type === "refund"
                                ? row?._from_wallet_usable_balance
                                : row?._from_wallet_id === "admin" &&
                                  row?._type === "sell_coupon"
                                ? row?._from_wallet_usable_balance?.toFixed(2)
                                : row?._from_wallet_id === "admin"
                                ? row?._to_wallet_usable_balance?.toFixed(2)
                                : row?._from_wallet_id === userData?.data?._id
                                ? row?._from_wallet_usable_balance?.toFixed(2)
                                : row?._to_wallet_usable_balance?.toFixed(2)
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          {row?.OrderData?.BuyerChoosedTransportation
                            ?.TransportationType === "Seller" ? (
                            <TransactionsCommaSeprator
                              Price={
                                row?.INRDetails?.totalTransportationCost +
                                  row?.INRDetails?.totalValueInrOfAdCost +
                                  row?.INRDetails?.packagingCost +
                                  row?.INRDetails?.InsuranceCost || 0
                              }
                            />
                          ) : (
                            <TransactionsCommaSeprator
                              Price={
                                row?.INRDetails?.totalValueInrOfAdCost +
                                  row?.INRDetails?.packagingCost ||
                                row?.INRDetails?.TotalValueInrOfAdCost +
                                  row?.INRDetails?.packagingCost ||
                                0
                              }
                            />
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              row?.INRDetails?.TotalBxiCommission ||
                              row?.INRDetails?.totalBxiCommission ||
                              0
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={getTotalBalance(row)}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator Price={getTotalGST(row)} />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              getTotalBalance(row) + getTotalGST(row)
                                ? (
                                    getTotalBalance(row) + getTotalGST(row)
                                  )?.toFixed(2)
                                : 0
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={row?.INRDetails?.TDS}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              row?._from_wallet_id !== userData?.data?._id
                                ? getTotalBalance(row) +
                                  getTotalGST(row) -
                                  row?.INRDetails?.TDS
                                : getTotalBalance(row) + getTotalGST(row)
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={row?.INRDetails?.TotalInrToPay}
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator Price={row?._balance} />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              row?._from_wallet_id !== userData?.data?._id
                                ? row?.INRDetails?.TDS
                                : null
                            }
                          />
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{
                            ...TextStyleCommon,
                            color: wallet_release ? "black" : "#FF8E00",
                          }}
                        >
                          <TransactionsCommaSeprator
                            Price={
                              getTotalBalance(row) + getTotalGST(row)
                                ? (
                                    getTotalBalance(row) + getTotalGST(row)
                                  )?.toFixed(2)
                                : 0
                            }
                          />
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                }
                // }
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};

const Credit_line_TableBody = ({ index, data, userData }) => {
  let price;
  if (
    data._from_wallet_id === "admin" ||
    data._from_wallet_id === userData?.data?._id ||
    data?._type === "refund"
  ) {
    price = data._from_wallet_balance;
  } else {
    price = data._to_wallet_balance;
  }

  const tds_amount = (data?._balance * 0.1) / 100;

  let Amount = data?._balance + tds_amount;

  return (
    <TableRow
      key={index}
      sx={{
        borderBottom: "1px solid green",
      }}
    >
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          {data._from_wallet_id === userData?.data?._id
            ? convertDate(data?._created_at)
            : convertDate(data?._release_date)}
          <br />
          at{" "}
          {data._from_wallet_id === userData?.data?._id
            ? formatDateTime(data?._created_at)
            : formatDateTime(data?._release_date)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <GetCompanyNameNProfile
            CompanyId={
              data?._from_wallet_id === userData?.data?._id
                ? data?._to_wallet_id
                : data?._from_wallet_id
            }
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          {data?._order_number?.includes("BXI")
            ? data?._order_number
            : data?._clnumber || data?._order_number}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          Credit Line
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._from_wallet_id === userData?.data?._id &&
              data?._type === "refund"
                ? null
                : data?._from_wallet_id === userData?.data?._id &&
                  data?._type !== "recover_coupon"
                ? Amount
                : data?._type === "credit_line_recovery"
                ? Amount
                : null
            }
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._from_wallet_id === userData?.data?._id &&
              data?._type === "refund"
                ? Amount
                : data?._from_wallet_id !== userData?.data?._id
                ? data?._type === "add_coupon"
                  ? Amount
                  : data?._from_wallet_id === "admin" &&
                    data?._type === "sell_coupon"
                  ? Amount
                  : data?._from_wallet_id === "admin"
                  ? null
                  : Amount
                : data?._from_wallet_id === userData?.data?._id &&
                  data?._type === "recover_coupon"
                ? Amount
                : null
            }
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator Price={data?.INRDetails?.TDS} />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          {price < 0 ? (
            <>
              -
              <TransactionsCommaSeprator Price={Math.abs(price)} />
            </>
          ) : (
            <TransactionsCommaSeprator Price={price} />
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._type === "add_coupon"
                ? data?._from_wallet_credit_due
                : data?.type === "refund"
                ? data?._from_wallet_credit_due
                : data?._from_wallet_id === "admin" &&
                  data?._type === "sell_coupon"
                ? data?._from_wallet_credit_due?.toFixed(2)
                : data?._from_wallet_id === "admin"
                ? data?._to_wallet_credit_due?.toFixed(2)
                : data?._from_wallet_id === userData?.data?._id
                ? data?._from_wallet_credit_due?.toFixed(2)
                : data?._to_wallet_credit_due?.toFixed(2)
            }
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._type === "add_coupon"
                ? data?._from_wallet_usable_balance
                : data?.type === "refund"
                ? data?._from_wallet_usable_balance
                : data?._from_wallet_id === "admin" &&
                  data?._type === "sell_coupon"
                ? data?._from_wallet_usable_balance?.toFixed(2)
                : data?._from_wallet_id === "admin"
                ? data?._to_wallet_usable_balance?.toFixed(2)
                : data?._from_wallet_id === userData?.data?._id
                ? data?._from_wallet_usable_balance?.toFixed(2)
                : data?._to_wallet_usable_balance?.toFixed(2)
            }
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          {data?.OrderData?.BuyerChoosedTransportation?.TransportationType ===
          "Seller" ? (
            <TransactionsCommaSeprator
              Price={
                data?.INRDetails?.totalTransportationCost +
                  data?.INRDetails?.totalValueInrOfAdCost +
                  data?.INRDetails?.packagingCost +
                  data?.INRDetails?.InsuranceCost || 0
              }
            />
          ) : (
            <TransactionsCommaSeprator
              Price={
                data?.INRDetails?.totalValueInrOfAdCost ||
                data?.INRDetails?.TotalValueInrOfAdCost ||
                0
              }
            />
          )}
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator Price={data?._balance} />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._from_wallet_id !== userData?.data?._id
                ? data?.INRDetails?.TDS
                : null
            }
          />
        </Typography>
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

const Private_deal_admin_to_buyer = ({ index, data, userData }) => {
  let price;
  if (
    data._from_wallet_id === "admin" ||
    data._from_wallet_id === userData?.data?._id ||
    data?._type === "refund"
  ) {
    price = data._from_wallet_balance;
  } else {
    price = data._to_wallet_balance;
  }

  const tds_amount = (data?._balance * 0.1) / 100;

  let Amount = data?._balance + tds_amount;

  return (
    <TableRow
      key={index}
      sx={{
        borderBottom: "1px solid green",
      }}
    >
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          {convertDate(data?._created_at)}
          <br />
          at {formatDateTime(data?._created_at)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <GetCompanyNameNProfile
            CompanyId={
              data?._from_wallet_id === userData?.data?._id
                ? data?._to_wallet_id
                : data?._from_wallet_id
            }
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          {data?._order_number?.includes("BXI")
            ? data?._order_number
            : data?._clnumber || data?._order_number}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          {data?._type === "sell_recover_coupon"
            ? "Sell/PrivateDeal"
            : data?._type === "recover_coupon"
            ? "Buy/PrivateDeal"
            : data?._type === "sell_coupon" &&
              data?._from_wallet_id !== userData?.data?._id
            ? "Sell/PrivateDeal"
            : data?._type === "sell_coupon"
            ? "Buy/PrivateDeal"
            : data?._type === "add_coupon"
            ? "Buy/PrivateDeal"
            : data?._type === "refund"
            ? "Refund"
            : data?._type?.includes("credit_line")
            ? "Credit Line"
            : data?._from_wallet_id === userData?.data?._id
            ? "Buy"
            : "Sell"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._from_wallet_id === userData?.data?._id &&
              data?._type === "refund"
                ? null
                : data?._from_wallet_id === userData?.data?._id &&
                  data?._type !== "recover_coupon"
                ? Amount
                : data?._type === "credit_line_recovery"
                ? Amount
                : null
            }
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._from_wallet_id === userData?.data?._id &&
              data?._type === "refund"
                ? Amount
                : data?._from_wallet_id !== userData?.data?._id
                ? data?._type === "add_coupon"
                  ? Amount
                  : data?._from_wallet_id === "admin" &&
                    data?._type === "sell_coupon"
                  ? Amount
                  : data?._from_wallet_id === "admin"
                  ? null
                  : Amount
                : data?._from_wallet_id === userData?.data?._id &&
                  data?._type === "recover_coupon"
                ? Amount
                : null
            }
          />
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          {price < 0 ? (
            <>
              -
              <TransactionsCommaSeprator Price={Math.abs(price)} />
            </>
          ) : (
            <TransactionsCommaSeprator Price={price} />
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._type === "add_coupon"
                ? data?._from_wallet_credit_due
                : data?.type === "refund"
                ? data?._from_wallet_credit_due
                : data?._from_wallet_id === "admin" &&
                  data?._type === "sell_coupon"
                ? data?._from_wallet_credit_due?.toFixed(2)
                : data?._from_wallet_id === "admin"
                ? data?._to_wallet_credit_due?.toFixed(2)
                : data?._from_wallet_id === userData?.data?._id
                ? data?._from_wallet_credit_due?.toFixed(2)
                : data?._to_wallet_credit_due?.toFixed(2)
            }
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator
            Price={
              data?._type === "add_coupon"
                ? data?._from_wallet_usable_balance
                : data?.type === "refund"
                ? data?._from_wallet_usable_balance
                : data?._from_wallet_id === "admin" &&
                  data?._type === "sell_coupon"
                ? data?._from_wallet_usable_balance?.toFixed(2)
                : data?._from_wallet_id === "admin"
                ? data?._to_wallet_usable_balance?.toFixed(2)
                : data?._from_wallet_id === userData?.data?._id
                ? data?._from_wallet_usable_balance?.toFixed(2)
                : data?._to_wallet_usable_balance?.toFixed(2)
            }
          />
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography
          sx={{
            ...TextStyleCommon,
            color: "black",
          }}
        >
          <TransactionsCommaSeprator Price={data?._balance} />
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell></TableCell>
    </TableRow>
  );
};

export default DashboardWalletStatement;

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const TableContainerStyle = {
  minHeight: "550px",
  maxHeight: "550px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
};
