import { Grid, Paper, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import WishlistIcon from "../../assets/wishlist.png";
import SharethisIcon from "../../assets/share.png";
import CartIcon from "../../assets/cart.png";
import PageLoader from "../../components/LoadingButton/PageLoader";
import stackofcoin from "../../assets/CartPage/unnamed 1.svg";
import readystock from "../../assets/Images/MarketplaceImages/readystock.png";
import { BsCartFill } from "react-icons/bs";
import { AiFillHeart } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { get_Cart_Items } from "../../redux/action/CartActions";
import { useGetWishlistData } from "../../Hooks/ProductActions/useGetWishlistData";
import { getProduct } from "../../redux/action/Home-Filter/products";
import { useProductAddToCart } from "../../Hooks/ProductActions/useProductAddToCart";
import { useAddToWishlist } from "../../Hooks/ProductActions/useAddToWishlist";
import { useRemoveCartProductByProductId } from "../../Hooks/ProductActions/useRemoveCartProduct";
import { useRemoveWishlistProductByProductId } from "../../Hooks/ProductActions/useRemoveWishlistProduct";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import { useGetCartData } from "../../Hooks/ProductActions/useGetCartData";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getProductFilter } from "../../redux/action/products";
import { ProductAnalysisUpdate } from "../../redux/action/Products/ProductAnalysis";

const AllProductWithFilter = (props) => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },
    // "& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
    //   color: "#1976d2",
    //   display: "none",
    // },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [AllProducts, setAllProducts] = useState([]);
  const [PaginationCount, setPaginationCount] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: mutateCartData, mutate: addtocart } = useProductAddToCart();

  const {
    data: mutateWishlistData,
    mutate: addtowishlist,
    isLoading: wishlistMutateLoading,
    error: wishlistMutateError,
  } = useAddToWishlist();

  const { data: mutateRemoveData, mutate: removefromcart } =
    useRemoveCartProductByProductId();

  const { data: mutateRemoveWishlistData, mutate: removefromwishlist } =
    useRemoveWishlistProductByProductId();

  const {
    data: wishlistData,
    isLoading: wishlistLoading,
    error: wishlistError,
    refetch: wishlistRefetch,
  } = useGetWishlistData();

  const { products, loading, error } = useSelector((state) => state.products);
  const [initialPaginationCount, setInitialPaginationCount] = useState(null);

  useEffect(() => {
    if (products && products.count !== undefined) {
      setInitialPaginationCount(products.count);
    }
  }, [products.count]);

  const {
    data: cartItems,
    isLoading: cartItemsLoading,
    error: cartItemsError,
    refetch: cartItemsRefetch,
  } = useGetCartData();

  async function handleAddToCart(id) {
    addtocart(id);
  }

  async function handleRemoveCart(id) {
    removefromcart(id);
  }

  async function handleAddToWishlist(id, ProductType) {
    axios
      .post(
        "wishlist/add_to_wishlist",
        { id: id, ProductType: ProductType },
        { withCredentials: true }
      )
      .then((res) => {
        wishlistRefetch();
      });
  }

  async function handleRemoveWishlist(id) {
    removefromwishlist(id);
  }
  useEffect(() => {
    dispatch(getProductFilter());
  }, [dispatch, handleRemoveWishlist, handleAddToWishlist]);

  useEffect(() => {
    // dispatch(getWishlist());
    dispatch(get_Cart_Items());
  }, [dispatch, currentPage]);

  useEffect(() => {
    dispatch(getProduct("", "", "", "", "", currentPage, "", "", "", ""));
  }, [currentPage]);

  {
    /*  const GetProductCategory = async (id, type) => {
    await axios
      .get(`product_type/get_productType/${type}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.CompanyTypeName === "Textile") {
          navigate(`/home/productdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Hotel") {
          navigate(`/home/voucherdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "QSR") {
          navigate(`/home/voucherdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Airlines Tickets") {
          navigate(`/home/voucherdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Entertainment & Events") {
          navigate(`/home/voucherdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Mobility") {
          navigate(`/home/productdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Electronics") {
          navigate(`/home/productdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "FMCG") {
          navigate(`/home/productdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Office Supply") {
          navigate(`/home/productdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Others") {
          navigate(`/home/productdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Lifestyle") {
          navigate(`/home/productdetail/${id}`);
        } else if (res?.data?.CompanyTypeName === "Media") {
          navigate(`/home/mediabuying/${id}`);
        } else {
          navigate(`/home/productdetail/${id}`);
        }
      });
  }; */
  }

  const GetProductCategory = async (
    name,
    id,
    type,
    listingType,
    VoucherType,
    ProductSubCategory
  ) => {
    dispatch(ProductAnalysisUpdate(id, "ProductViewCount"));
    if (listingType === "Voucher") {
      if (
        VoucherType === "Value Voucher / Gift Cards " ||
        ProductSubCategory === "Value Voucher" ||
        ProductSubCategory === "Gift Cards"
      ) {
        navigate(`/home/giftandvaluevoucher/${id}`);
      } else {
        navigate(`/home/voucherdetail/${id}`);
      }
    } else if (listingType === "Media") {
      navigate(`/home/mediabuying/${id}`, {
        state: { type: "Media" },
      });
    } else if (listingType === "Product") {
      navigate(`/home/productdetail/${id}`);
    } else if (ProductSubCategory === "Gift Cards") {
      navigate(`/home/giftandvaluevoucher/${id}`);
    } else {
      navigate(`/home/productdetail/${id}`);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getAllProducts = async () => {
    try {
      const response = await axios.get(
        `product/all_products?page=${currentPage}`,
        {
          withCredentials: true,
        }
      );

      setAllProducts(response.data.products);
      setPaginationCount(response.data.count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, [currentPage]);

  const GetProductShareLink = async (id, type) => {
    await axios
      .get(`product_type/get_productType/${type}`, {
        withCredentials: true,
      })
      .then((res) => {
        if (res?.data?.CompanyTypeName) {
          navigator.clipboard.writeText(
            `https://bxiworld.com/home/productdetail` + `/${id}`
          );
          toast.info("Url Copied", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      });
  };

  return (
    <Paper
      sx={{
        width: "100%",
        mx: "auto",
        background: "transparent",
        borderRadius: "5px",
        mt: 3,
        position: "relative",
      }}
      elevation={0}
    >
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: "transparent",
              boxShadow: "none",
              borderRadius: "0px",
              width: "96%",
              mx: "auto",
              height: "30px",
              display: "flex",
              justifyContent: "space-between",
              my: 1,
            }}
          >
            <Typography sx={ExTextStyle}>
              {props.categoryName ? props.categoryName : "Explore"}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid container sx={{ width: "100%" }}>
        {loading === true
          ? [...Array(12)].map((e, i) => (
              <Grid
                item
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={12}
                key={i}
                sx={{ mt: 3, display: "flex", flexDirection: "row" }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: "15px",
                    maxWidth: "300px",
                    mx: "auto",
                    display: "flex",
                    gap: "15px",
                  }}
                  // gap={3}
                >
                  <Skeleton
                    variant="rectangular"
                    width={290}
                    height={300}
                    sx={{
                      borderRadius: "15px",
                    }}
                  />
                </Paper>
              </Grid>
            ))
          : products?.products &&
            products?.products?.length > 0 &&
            products?.products?.map((res, idx) => {
              return (
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ mt: 3 }}
                  key={idx}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      bgcolor: "transparent",
                      borderRadius: "15px",
                      maxWidth: "280px",
                      // height: "auto",
                      // minHeight: "250px",
                      // mx: "auto",
                      mx: {
                        xl: "auto",
                        lg: 1,
                        md: 1,
                        sm: 1,
                        xs: "auto",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        boxSizing: "border-box",
                        width: {
                          xl: "100%",
                          lg: "99%",
                          md: "98%",
                          sm: "97%",
                          xs: "96%",
                        },
                        height: "auto",
                        minHeight: "280px",
                        bgcolor: "#fff",
                        borderRadius: "13.6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // p: 0.1,
                        cursor: "pointer",
                        border: "0.1px solid transparent",
                        backgroundImage:
                          "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                        transition: "box-shadow .1s",
                        "&:hover": {
                          boxShadow: "0px 0px 17px #ff864547",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          mx: "auto",
                          height: "auto",
                          minHeight: "280px",
                          bgcolor: "#fff",
                          borderRadius: "13px",
                          padding: "5px",
                          // border: "0.8px solid",
                          // borderImage:
                          //   "linear-gradient(180deg,#b83a83 0%,#8f8f8f 47.4%,#1992c6 100%)",
                          // borderImageSlice: 1,
                        }}
                      >
                        <Box sx={{ display: "flex", position: "relative" }}>
                          {res?.SellerCompanyId?.Membership ===
                          "PREMIUMPLUS" ? (
                            <Box
                              sx={{
                                // mr: "auto",
                                left: "2%",
                                top: "-23%",
                                // marginLeft: "15px",
                                // marginTop: "-1px",
                                position: "absolute",
                              }}
                            >
                              <img
                                src={readystock}
                                alt=""
                                style={{
                                  height: "auto",
                                  width: "38px",
                                }}
                              />
                            </Box>
                          ) : null}

                          <Paper
                            elevation={0}
                            sx={{
                              display: "flex",
                              width: "60px",
                              maxWidth: "60px",
                              ml: "auto",
                              mr: 2,
                              bgcolor: "transparent",
                              minHeight: "23px",
                              alignItems: "center",
                              height: "auto",
                              justifyContent: "space-evenly",
                              mt: 1,
                            }}
                          >
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                GetProductShareLink(res?._id, res?.ProductType)
                              }
                            >
                              <img
                                src={SharethisIcon}
                                width="15px"
                                height="15px"
                                alt="img"
                              />
                            </button>

                            {wishlistData &&
                            wishlistData?.find(
                              (item) => item?.ProductId?._id === res?._id
                            ) ? (
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveWishlist(res?._id)}
                              >
                                <AiFillHeart color={"red"} size={"15px"} />
                              </button>
                            ) : (
                              <button
                                style={{
                                  border: "none",
                                  backgroundColor: "transparent",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleAddToWishlist(
                                    res?._id,
                                    res?.ProductType
                                  )
                                }
                              >
                                <img
                                  src={WishlistIcon}
                                  width="15px"
                                  height="15px"
                                  alt="img"
                                />
                              </button>
                            )}
                          </Paper>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "auto",
                            minHeight: "160px",
                            mx: "auto",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            cursor: "pointer",
                            // bgcolor: "red",
                          }}
                          onClick={() => {
                            GetProductCategory(
                              res?.ProductCategoryName,
                              res?._id,
                              res?.ProductType,
                              res?.ListingType,
                              res?.ProductSubCategory
                            );
                          }}
                        >
                          <img
                            src={
                              res?.ProductImages.length > 0
                                ? res?.ProductImages[0].url
                                : res?.VoucherImages?.at(0).url
                            }
                            alt="img"
                            style={{
                              width: "auto",
                              maxWidth: "230px",
                              maxHeight: "130px",
                              height: "auto",
                              marginLeft: "auto",
                              marginRight: "auto",
                              borderRadius: "10px",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            mx: "auto",
                            height: "auto",
                            display: "grid",
                            justifyContent: "center",
                            alignItems: "center",
                            pb: 2,
                            cursor: "pointer",
                            mt: 1,
                          }}
                          onClick={() => {
                            GetProductCategory(
                              res?.ProductCategoryName,
                              res?._id,
                              res?.ProductType,
                              res?.ListingType,
                              res?.VoucherType,
                              res?.ProductSubCategory
                            );
                          }}
                        >
                          <Paper
                            sx={{
                              width: "90%",
                              mx: "auto",
                              height: "auto",
                              minWidth: "240px",
                              maxWidth: "275px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignContent: "center",
                            }}
                            elevation={0}
                          >
                            <Typography
                              sx={{
                                ...MainTextStyle,
                                display: "-webkit-box",
                                WebkitLineClamp: 1,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {res?.ProductName}
                            </Typography>
                            <Typography
                              sx={{
                                ...PriceTextStyle,
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              <img
                                src={stackofcoin}
                                style={{
                                  width: "18px",
                                  height: "18px",
                                }}
                                alt="coin"
                              />
                              {res?.ProductsVariantions[0]?.PricePerUnit
                                ? res?.ProductsVariantions[0]?.PricePerUnit
                                : ""}
                            </Typography>{" "}
                          </Paper>
                          <Paper
                            sx={{
                              width: "90%",
                              height: "auto",
                              mx: "auto",
                              minWidth: "240px",
                              maxWidth: "275px",
                            }}
                            elevation={0}
                          >
                            <Typography sx={BottomTextStyle}>
                              {res?.ProductDescription
                                ? res?.ProductDescription
                                : ""}
                            </Typography>
                          </Paper>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: {
            xl: "100%",
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
          mx: "auto",
        }}
        mt={2}
      >
        <Stack>
          <StyledPagination
            size="medium"
            count={initialPaginationCount}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

export default AllProductWithFilter;

const BoxHead = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        width: "100px",
        maxWidth: "200px",
        ml: "auto",
        mr: 3,
        bgcolor: "transparent",
        minHeight: "50px",
        alignItems: "center",
        height: "auto",
        justifyContent: "space-evenly",
      }}
    >
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <img src={SharethisIcon} width="18px" height={"18px"} />
      </button>
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <img src={CartIcon} width="20px" height={"20px"} />
      </button>
      <button
        style={{
          border: "none",
          backgroundColor: "transparent",
          cursor: "pointer",
        }}
      >
        <img src={WishlistIcon} width="17px" height={"17px"} />
      </button>
    </Paper>
  );
};

const PriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: { xl: "16px", lg: "16px", md: "15px", sm: "15px", xs: "15px" },
  lineHeight: "24px",
  color: "#717171",
};

const MainTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "17px", lg: "17px", md: "16px", sm: "16px", xs: "16px" },
  lineHeight: "30px",
  color: "#141414",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  maxWidth: "160px",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const BottomTextStyle = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#6B7A99",
  textAlign: "left",
  mt: 1,
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const ExTextStyle = {
  fontFamily: "Source Sans Pro",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "30px",
  lineHeight: "38px",
  color: "#6B7A99",
};
