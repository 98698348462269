import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import axios from "axios";

import "./chartStyle.css";

// Register necessary elements for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChartPage() {
  const [data, setData] = useState({});
  const [timeline, setTimeline] = useState("day"); // Default to 'day'
  const [selectedTab, setSelectedTab] = useState("sales"); // Default to "sales"

  // Function to fetch chart data based on selected tab
  const fetchChartData = async () => {
    try {
      const endpoint =
        selectedTab === "sales"
          ? "soldAndbrought/total-sales"
          : "soldAndbrought/total-buy";
      const response = await axios.post(endpoint, {
        timeline: timeline,
      });

      const chartLabels = Object.keys(response.data);
      const chartData = Object.values(response.data);

      setData({
        labels: chartLabels,
        datasets: [
          {
            label:
              selectedTab === "sales"
                ? "Completed Sales"
                : "Completed Purchases",
            data: chartData,
            backgroundColor: "rgba(34, 97, 162, 1)",
            borderColor: "rgba(34, 97, 162, 1)",
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching chart data:", error);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [timeline, selectedTab]); // Re-fetch data when timeline or tab changes

  const options = {
    plugins: {
      legend: { display: true },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide the grid on x-axis
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value.toLocaleString(); // Format Y-axis labels with commas
          },
        },
        grid: {
          display: false, // Hide the grid on y-axis
        },
      },
    },
    backgroundColor: "white", // Set chart background to white
    responsive: true, // Make chart responsive
    maintainAspectRatio: true, // Maintain aspect ratio
  };

  return (
    <div className="chart-container">
      {/* Tabs for Sales and Purchases */}
      <div className="tab-container">
        <button
          onClick={() => setSelectedTab("sales")}
          className={`tab-button ${selectedTab === "sales" ? "active" : ""}`}
        >
          Sales
        </button>
        <button
          onClick={() => setSelectedTab("purchases")}
          className={`tab-button ${
            selectedTab === "purchases" ? "active" : ""
          }`}
        >
          Purchases
        </button>
      </div>

      {/* Timeline Dropdown */}
      <div className="dropdown-container">
        <select
          value={timeline}
          onChange={(e) => setTimeline(e.target.value)}
          className="timeline-dropdown"
        >
          <option value="day">By Day</option>
          <option value="week">By Week</option>
          <option value="month">By Month</option>
          <option value="year">By Year</option>
        </select>
      </div>

      {/* Display the Bar Chart */}
      {data.labels ? (
        <div className="chart-wrapper">
          <Bar data={data} options={options} />
        </div>
      ) : (
        <p>Loading chart...</p>
      )}
    </div>
  );
}
