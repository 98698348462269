import React, { useState } from "react";
import * as Styles from "../Styles";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { Fade } from "react-reveal";
import justpay from "../../../assets/Images/MarketplaceImages/juspay.png";
import unadalogo from "../../../assets/Images/MarketplaceImages/unadalogo.svg";
import signzy from "../../../assets/Images/MarketplaceImages/signzy.png";
const Trust = () => {
  const [expandedItems, setExpandedItems] = useState({});

  const companyDetails = [
    {
      img: signzy,
      title:
        "Award Winning Platform Signzy secures the  marketplace by helping us onboarding ROC regulated   organizations. This helps our members to barter trade with reputable and trustworthy",
    },
    // {
    //   img: unadalogo,
    //   title:
    //     'A leader in disruptive technology, has been instrumental in architecting BXIs entire platform, revolutionizing B2B barter by fully digitizing and optimizing the process',
    // },
    {
      img: justpay,
      title:
        "Indias leading payments platform trusted by leading enterprises worldwide who simplifies payment orchestration, boosts conversions, reduces fraud, and delivers seamless customer experiences. Operating as both a payment aggregator and a payment gateway, allowing members to perform accept payments through various sources like UPI, Net Banking, Cards etc.",
    },
    {
      img: "https://bxilanding-page.s3.ap-south-1.amazonaws.com/image+187.svg",
      title:
        "Escrowpay , is a trusted partner that eliminates the risk of fraud in transactions. Buy and sell with confidence as Escrowpay holds your payment securely until you receive your goods or services, ensuring a smooth and transparent experience",
    },
  ];

  const toggleTextExpansion = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderCompanyText = (text, index) => {
    const isExpanded = expandedItems[index];
    const maxLength = 115; // Adjust this value to control initial text length

    if (text.length <= maxLength) {
      return text;
    }

    return (
      <Box sx={{ display: "inline" }}>
        {isExpanded ? text : `${text.slice(0, maxLength)}...`}
        <Typography
          component="span"
          onClick={() => toggleTextExpansion(index)}
          sx={{
            color: "primary.main",
            cursor: "pointer",
            ml: 1,
            fontWeight: "bold",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          {isExpanded ? "View Less" : "View More"}
        </Typography>
      </Box>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        mt: 5,
        position: "relative",
        overflow: "hidden",
        padding: 5,
      }}
    >
      <Fade top duration={1000}>
        <Typography
          sx={{ ...Styles.headText, textAlign: "center", width: "100%" }}
        >
          Trust & How
        </Typography>
      </Fade>
      <Typography sx={Styles.subTrustText}>
        At BXI, we don't just talk trust, we supercharge it!
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "flex-start",
          mt: 5,
          flexWrap: "wrap",
          gap: "10px",
        }}
      >
        {companyDetails?.map((item, index) => (
          <Box
            key={index}
            sx={{
              ...Styles.companyBox,
              height: "auto", // Make height flexible
              minHeight: {
                xl: "245px",
                lg: "245px",
                md: "245px",
                sm: "180px",
                xs: "180px",
              },
            }}
          >
            <Box
              component={"img"}
              src={item?.img}
              alt={`company-logo-${index}`}
              sx={{
                height: "auto",
                width: "auto",
                maxWidth: {
                  xl: "90%",
                  lg: "90%",
                  md: "90%",
                  sm: "70%",
                  xs: "70%",
                },
              }}
            />
            <Typography
              sx={{
                ...Styles.subCompanyText,
                whiteSpace: "normal", // Allow text to wrap
                wordBreak: "break-word", // Break long words if necessary
              }}
            >
              {renderCompanyText(item?.title, index)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default Trust;
