import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import sendEvent from "../../utils/sendEvents";
import { getProduct } from "../../redux/action/Home-Filter/products";

export let ProductDescriptionSearch = "";
export let ProductCompanyNameSearch = "";
export let ProductSubtittleSearch = "";
export let Tag = "";

const SearchInput = () => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");

  ProductDescriptionSearch = search;
  ProductCompanyNameSearch = search;
  ProductSubtittleSearch = search;
  Tag = search;

  const handleStyleChange = () => {
    inputRef.current.style.color = "#C3CAD9";
    inputRef.current.style.fontSize = "14px";
    inputRef.current.style.fontFamily = "Poppins";
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, delay);
    };
  };

  const debouncedSearchChange = useCallback(
    debounce((value) => setDebouncedSearch(value), 500),
    []
  );

  useEffect(() => {
    if (search !== undefined) {
      debouncedSearchChange(search);
    }
  }, [search, debouncedSearchChange]);

  useEffect(() => {
    if (debouncedSearch !== undefined) {
      if (debouncedSearch === "") {
        dispatch(getProduct("", "", "", "", "", "", "", "", "", ""));
      } else {
        dispatch(
          getProduct("", debouncedSearch, "", "", "", "", "", "", "", "")
        );
      }
    }
  }, [debouncedSearch, dispatch]);

  return (
    <input
      onClick={() => {
        handleStyleChange();
        sendEvent("Click on search expand");
      }}
      ref={inputRef}
      onChange={handleSearchChange}
      label="Search"
      placeholder="Search"
      variant="outlined"
      InputProps={{ disableUnderline: true }}
      style={{
        color: "#2261a2",
        width: "75%",
        boxShadow: "none",
        border: "none",
        height: "93%",
        fontSize: "16px",
      }}
    />
  );
};

export default SearchInput;
