import React from 'react';
import { Box, Grid, Typography, Button, Paper } from '@mui/material';
import * as Styles from '../Styles';
import textTileicon from '../../../assets/marketplaceicons/commonimg/textile.svg';
import lifestyle from '../../../assets/marketplaceicons/commonimg/lifestyle.svg';
import media from '../../../assets/marketplaceicons/commonimg/media.svg';
import office from '../../../assets/marketplaceicons/commonimg/officesupply.svg';
import electronics from '../../../assets/marketplaceicons/commonimg/electronics.svg';
import hotel from '../../../assets/marketplaceicons/commonimg/hotel.svg';
import qsr from '../../../assets/marketplaceicons/commonimg/qsr.svg';
import airline from '../../../assets/marketplaceicons/commonimg/airline.svg';
import entertenment from '../../../assets/marketplaceicons/commonimg/entertenment.svg';
import fmcg from '../../../assets/marketplaceicons/commonimg/fmcg.svg';
import mobility from '../../../assets/marketplaceicons/commonimg/mobility.svg';
import other from '../../../assets/marketplaceicons/commonimg/other.svg';
import { useNavigate } from 'react-router-dom';
import { Fade } from 'react-reveal';
const Data = () => {
  const categories = [
    {
      name: 'Textile',
      image: textTileicon,
    },
    {
      name: 'Lifestyle',
      image: lifestyle,
    },
    {
      name: 'Media',
      image: media,
    },
    {
      name: 'Office Supply',
      image: office,
    },
    {
      name: 'Electronics',
      image: electronics,
    },
    {
      name: 'Hotel',
      image: hotel,
    },
    {
      name: 'QSR',
      image: qsr,
    },
  ];
  const categories2 = [
    {
      name: 'Airlines Tickets',
      image: airline,
    },
    {
      name: 'Entertainment & Events',
      image: entertenment,
    },
    {
      name: 'FMCG',
      image: mobility,
    },
    {
      name: 'Mobility',
      image: fmcg,
    },
    {
      name: 'Others',
      image: other,
    },
  ];
  const row1 = [
    {
      name: 'Textile',
      image: textTileicon,
    },
    {
      name: 'Lifestyle',
      image: lifestyle,
    },
    {
      name: 'Media',
      image: media,
    },
    {
      name: 'Office Supply',
      image: office,
    },
  ];
  const row2 = [
    {
      name: 'Electronics',
      image: electronics,
    },
    {
      name: 'Hotel',
      image: hotel,
    },
    {
      name: 'Mobility',
      image: mobility,
    },
    {
      name: 'Airlines Tickets',
      image: airline,
    },
  ];
  const row3 = [
    {
      name: 'FMCG',
      image: fmcg,
    },
    {
      name: 'Entertainment & Events',
      image: entertenment,
    },
    {
      name: 'QSR',
      image: qsr,
    },
    {
      name: 'Others',
      image: other,
    },
  ];

  const navigate = useNavigate();

  const handleClick = (name) => {
    navigate('/explore', {
      state: {
        label: name,
      },
    });
  };

  return (
    <Paper elevation={0} sx={{ mt: { xl: 10, lg: 10, md: 10, sm: 5, xs: 5 } }}>
      <Box sx={{ width: '90%', mx: 'auto' }}>
        <Fade top duration={1000}>
          <Typography sx={Styles.headText}>
            Do You
            <span style={{ color: ' rgba(108, 108, 162, 1)' }}> Own or</span>
          </Typography>
          <Typography
            sx={{ ...Styles.headText, color: ' rgba(108, 108, 162, 1)' }}
          >
            Operate &nbsp;
            <span style={{ color: 'rgba(35, 36, 42, 1)' }}>a Business In</span>
          </Typography>
        </Fade>
      </Box>
      {/*  */}
      <Box
        sx={{
          width: '100%',
          background: 'rgba(108, 108, 162, 0.09)',
          p: { xl: 4, lg: 4, md: 4, sm: 0.5, xs: 0.5 },
          mt: 2,
          height: 'auto',
        }}
      >
        <Box
          sx={{
            width: '94%',
            mx: 'auto',
          }}
        >
          <Typography sx={{ ...Styles.smallHeadText, fontWeight: 400 }}>
            ACTIVE Categories
          </Typography>
        </Box>
        <Box mt={2} sx={caregoriesBoxStyle}>
          {categories?.map((item, index) => (
            <Box
              sx={Styles.insideCategoryBox}
              onClick={() => {
                handleClick(item?.name);
              }}
            >
              <Box component={'img'} src={item?.image} sx={categoriesStyle} />
              <Typography sx={Styles.categoryText}>{item?.name}</Typography>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            ...caregoriesBoxStyle,
            width: { xl: '70%', lg: '70%', md: '70%', sm: '90%', xs: '90%' },
            mt: { xl: 3, lg: 3, md: 3, sm: 1, xs: 1 },
          }}
        >
          {categories2?.map((item, index) => (
            <Box
              sx={Styles.insideCategoryBox}
              onClick={() => {
                handleClick(item?.name);
              }}
            >
              <Box component={'img'} src={item?.image} sx={categoriesStyle} />
              <Typography sx={Styles.categoryText}>{item?.name}</Typography>
            </Box>
          ))}
        </Box>
        {/* sm sc */}
        <Box
          sx={{
            width: '96%',
            mx: 'auto',
            overflowX: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          <Box sx={caregoriesSmallBoxStyle}>
            {row1?.map((item, index) => (
              <Box
                sx={Styles.insideCategorySmallBox}
                onClick={() => {
                  handleClick(item?.name);
                }}
              >
                <Box component={'img'} src={item?.image} sx={categoriesStyle} />
                <Typography sx={Styles.categoryText}>{item?.name}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={caregoriesSmallBoxStyle}>
            {row2?.map((item, index) => (
              <Box
                sx={Styles.insideCategorySmallBox}
                onClick={() => {
                  handleClick(item?.name);
                }}
              >
                <Box component={'img'} src={item?.image} sx={categoriesStyle} />
                <Typography sx={Styles.categoryText}>{item?.name}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={caregoriesSmallBoxStyle}>
            {row3?.map((item, index) => (
              <Box
                sx={Styles.insideCategorySmallBox}
                onClick={() => {
                  handleClick(item?.name);
                }}
              >
                <Box component={'img'} src={item?.image} sx={categoriesStyle} />
                <Typography sx={Styles.categoryText}>{item?.name}</Typography>
              </Box>
            ))}
          </Box>
          {/* <Box sx={caregoriesSmallBoxStyle}>
                    {row4?.map((item, index) => (
                      <Box
                        sx={Styles.insideCategorySmallBox}
                        onClick={() => {
                          handleClick(item?.name);
                        }}
                      >
                        <Box component={'img'} src={item?.image} sx={categoriesStyle} />
                        <Typography sx={Styles.categoryText}>{item?.name}</Typography>
                      </Box>
                    ))}
                  </Box> */}
        </Box>
        {/*  */}
      </Box>
      {/*  */}
    </Paper>
  );
};

export default Data;

const categoriesStyle = {
  maxWidth: { xl: '40px', lg: '40px', md: '40px', sm: '16px', xs: '16px' },
  height: 'auto',
};

const caregoriesBoxStyle = {
  width: '90%',
  mx: 'auto',
  display: {
    xl: 'flex',
    lg: 'flex',
    md: 'flex',
    sm: 'none',
    xs: 'none',
  },
  justifyContent: 'space-around',
  gap: 2,
  flexWrap: 'wrap',
};
const caregoriesSmallBoxStyle = {
  width: '100%',
  mx: 'auto',
  display: {
    xl: 'none',
    lg: 'none',
    md: 'none',
    sm: 'flex',
    xs: 'flex',
  },
  justifyContent: 'flex-start',
  gap: 2,
  flexWrap: 'nowrap',
  // ml: 0.15,
};
