import React from "react";
import * as Styles from "../Styles";
import { Box, Grid, Typography, Paper } from "@mui/material";
import { Fade } from "react-reveal";
import leftArr from "../../../assets/Images/LearnBarterImages/leftArr.svg";
import smallbxilogo from "../../../assets/Images/LearnBarterImages/smallbxilogo.svg";
const IntroLearnBarter = () => {
  const heroText = [
    "Buy and Sell on BXI Marketplace",
    "Not a Legal Tender",
    "For Accounting Purposes",
    "Valid Inside Marketplace",
    "Medium of Exchange",
    "1 Trade Credit = 1 INR",
  ];
  return (
    <Paper elevation={0} sx={{ mt: { xl: 10, lg: 10, md: 10, sm: 0, xs: 0 } }}>
      <Grid container spacing={10}>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <Typography
            sx={{
              ...Styles.smallHeadText,
              width: {
                xl: "93%",
                lg: "93%",
                md: "90%",
                sm: "90%",
                xs: "90%",
              },
              textAlign: "left",
              mx: "auto",
            }}
          >
            FOR BUSINESS
          </Typography>
          <Fade top duration={1000}>
            <Typography
              sx={{
                ...Styles.headMainText,
                textAlign: "left",
                width: {
                  xl: "93%",
                  lg: "93%",
                  md: "90%",
                  sm: "90%",
                  xs: "90%",
                },
                mx: "auto",
              }}
            >
              Introducing BXI
              <span style={{ color: "rgba(108, 108, 162, 1)" }}>
                &nbsp;Trade Credits
              </span>
            </Typography>
          </Fade>
          <Box sx={styleYoutubeVideo}>
            <Box
              component="iframe"
              // src="https://www.youtube.com/embed/R7I5xfnAaYg"
              src="https://www.youtube.com/embed/f6G90fJNgvg?si=XzZR63or2qbVjzHD"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              sx={stylesIframe}
            />
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Box sx={adjTextBox}>
            {heroText.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: {
                    xl: "100%",
                    lg: "100%",
                    md: "100%",
                    sm: "60%",
                    xs: "70%",
                  },
                  mr: "auto",
                  justifyContent: "flex-start",
                  gap: {
                    xl: 2,
                    lg: 2,
                    md: 1.5,
                    sm: 1.5,
                    xs: 1,
                  },
                }}
              >
                <Box
                  component={"img"}
                  src={smallbxilogo}
                  sx={{
                    height: "auto", // Adjust based on the logo size in the image
                    maxWidth: {
                      xl: "30px",
                      lg: "18px",
                      md: "15px",
                      sm: "15px",
                      xs: "15px",
                    }, // Ensure the logo is proportional
                  }}
                />
                <Typography sx={Styles.subCompanyText}>{item}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default IntroLearnBarter;

const styleYoutubeVideo = {
  position: "relative",
  width: {
    xl: "76%",
    lg: "76%",
    md: "76%",
    sm: "98%",
    xs: "98%",
  },
  mx: "auto",
  overflow: "hidden", // Ensures content respects the border radius
  mt: 2,
  borderRadius: "10px", // Apply borderRadius to parent
  ml: { xl: 4, lg: 4, md: 0, sm: 0.2, xs: 0.2 },
  paddingTop: "56.25%", // Maintain 16:9 aspect ratio for iframe
  height: 0, // Set height to 0, aspect ratio will determine the height
};

const adjTextBox = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: { xl: 3.5, lg: 3, md: 2.3, sm: 3, xs: 3 }, // Add consistent spacing
  alignItems: "flex-start",
  width: {
    xl: "100%",
    lg: "100%",
    md: "100%",
    sm: "80%",
    xs: "80%",
  },
  mx: "auto",
  mt: { xl: 10.5, lg: 11, md: 10, sm: 0, xs: -7 },
};

const stylesIframe = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: {
    xl: "76%",
    lg: "76%",
    md: "76%",
    sm: "98.5%",
    xs: "98.5%",
  }, // Make iframe fit the parent box
  borderRadius: "inherit", // Inherit parent's borderRadius
  border: "none",
};
