import axios from "axios";
import {
  Category_Id,
  Category_Ids,
} from "../../../components/Carousel/ProductDetailsCarousel";
import {
  Product_Qty,
  Clear,
  Refresh,
  PriceShortHightToLow,
  PriceShortLowToHight,
  WhatsNew,
  Popularity,
  Voucher,
  ProductsFilter,
  IsBestSeller,
  IsReadyStock,
} from "../../../views/MarketPlace";
import { Pagination_Count } from "../../../pages/Marketplace/ProductList";
import {
  product_Fail,
  product_Request,
  product_Success,
} from "../../reduser/Home-Filter/products";
import {
  MediaFilter,
  SelectedCompany,
  PriceRange,
  States,
} from "../../../components/Filters/SearchBar";
import {
  ProductSubtittleSearch,
  ProductDescriptionSearch,
  ProductCompanyNameSearch,
  Tag,
} from "../../../components/Filters/SearchInput";

// Create a cancel token source variable outside of the function so it persists
let cancelTokenSource = null;

export const getProduct =
  (
    SellerCompanyId = "",
    Search = "",
    sortBy = "",
    priceShortHightToLow,
    priceShortLowToHight,
    currentPage = "",
    voucher,
    productsFilter,
    IsBestSeller,
    IsReadyStock
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: product_Request.toString() });

      if (sortBy === "sort") sortBy = "-createdAt";

      // Cancel any ongoing request before starting a new one
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Previous request canceled.");
      }

      // Create a new cancel token source for each new request
      cancelTokenSource = axios.CancelToken.source();

      const queryParams = new URLSearchParams({
        SellerCompanyId: SellerCompanyId,
        Search: Search,
        PriceRange: PriceRange,
        ProductLocationState: States,
        ProductDeliveryType: "",
        ProductRating: "",
        ProductQty: Product_Qty,
        ProductCategory: Category_Ids,
        Clear: Clear,
        Short: sortBy,
        Category_Id: Category_Id,
        Refresh: Refresh,
        priceShortHightToLow: priceShortHightToLow,
        priceShortLowToHight: priceShortLowToHight,
        whatsNew: WhatsNew,
        companyName: SelectedCompany,
        page: currentPage,
        limit: "",
        Voucher: voucher,
        Tag: Tag,
        productsFilter: productsFilter,
        popularity: Popularity,
        DescriptionSearch: ProductDescriptionSearch,
        ProductSubtittleSearch: ProductSubtittleSearch,
        SellerCompanyNameSearch: ProductCompanyNameSearch,
        IsBestSeller: IsBestSeller,
        IsReadyStock: IsReadyStock,
        mediaFilter: MediaFilter,
      }).toString();

      // Make the request and attach the cancel token
      const { data } = await axios.get(`product/get_products?${queryParams}`, {
        cancelToken: cancelTokenSource.token, // Attach cancel token to the request
        withCredentials: true,
      });

      dispatch({
        type: product_Success.toString(),
        payload: data,
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message); // Handle canceled requests
      } else {
        dispatch({
          type: product_Fail.toString(),
          payload: error.response?.data?.message || error.message,
        });
      }
    }
  };
