import React, { useState } from 'react';
import * as Styles from '../Styles';
import { Fade } from 'react-reveal';
import { Box, Grid, Typography, Paper, styled } from '@mui/material';
import faqsImage from '../../../assets/Images/LearnBarterImages/faqsImage.svg';
const Faqs = () => {
  const [tab, setTab] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const tabZero = [
    {
      id: 1,
      question: 'What is barter?',
      answer:
        ' Barter involves exchanging goods and services without cash. Members give what they have and take what they need from other members offerings.',
    },
    {
      id: 2,
      question: 'How does barter work ?',
      answer:
        'Members must pledge their products or services to sell them. They can then use the equivalent value to buy anything listed on the marketplace.',
    },
    {
      id: 3,
      question: 'Who Can Barter ?',
      answer:
        'All reputable organizations registered with the ROC and offering products or services can barter on BXI.',
    },
    {
      id: 4,
      question: 'What Kind of Products & Services are available on Barter ?',
      answer:
        'BXI Marketplace offers a wide range of products and gift vouchers across 10+ categories, including media, airline, hotel, consumer durables, electronics, FMCG, Office Supply and more.',
    },
    {
      id: 5,
      question: 'How Secure is to Barter at BXI ?',
      answer:
        'BXI has a secure transaction process that holds INR payments in escrow until the buyer receives the goods or services.',
    },
    {
      id: 6,
      question: 'Is Barter Legal in India ?',
      answer:
        'Government approves of doing business on barter/kind. Both parties involved are required to issue invoices based on the actual value of goods or services exchanged, and pay the applicable Goods and Services Tax (GST) in INR. ',
    },
    {
      id: 7,
      question:
        'Do we need to pay GST in Barter Deal and How it works ? Will I get input credit in Barter Deal ?',
      answer:
        'On BXI, product values are in Barter Coins, while GST is paid in INR to the seller after a successful transaction. You would get input credit also. ',
    },
    {
      id: 8,
      question: 'Do I trade with BXI or with other Members Directly ?',
      answer:
        'BXI offers transparency by sharing contact details between buyer and seller after payment. Buyers can purchase multiple products from different sellers on the marketplace directly.',
    },
    {
      id: 9,
      question: 'Barter Deals happen on MRP / Cash Rates ?',
      answer:
        'We encourage BXI users to list their products and services at cash rates to promote more barter transactions.',
    },
    {
      id: 10,
      question: 'How does BXI Earn Money on Barter Deals ?',
      answer:
        'We earn through membership fees and success fees charged on successful transactions as per membership plans ',
    },
    {
      id: 11,
      question: 'How to Become a member on BXI Marketplace ?',
      answer:
        'To join BXI, members must be registered with the ROC, have a 3-year-old company, and pass GST verification. They also need to provide bank details to receive payments for sales.',
    },
  ];
  return (
    <Paper
      elevation={0}
      sx={{
        height: '100%',
        width: '100%',
        background: 'rgba(252, 251, 255, 1)',
        mt: 5,
        p: { xl: 1, lg: 1, md: 1, sm: 0, xs: 0 },
      }}
    >
      <Typography
        sx={{
          ...Styles.headMainText,
          width: '100%',
          display: {
            xl: 'none',
            lg: 'none',
            md: 'block',
            sm: 'block',
            xs: 'block',
          },
          py: 2,
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={Styles.faqMainPage}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: {
              xl: 'flex-start',
              lg: 'flex-start',
              md: 'center',
              sm: 'center',
              xs: 'center',
            },
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <Fade top duration={1000}>
            <Typography
              sx={{
                ...Styles.headMainText,
                width: '100%',
                display: {
                  xl: 'block',
                  lg: 'block',
                  md: 'none',
                  sm: 'none',
                  xs: 'none',
                },
              }}
            >
              Frequently Asked Questions
            </Typography>
          </Fade>
          <Typography sx={Styles.leagalSubText}>
            A list of commonly asked questions and their answers about barter.
            The purpose of an FAQ is to provide clear, concise, and accessible
            information that addresses the most common concerns or inquiries
            that you may have related to barter. You may also refer to the Legal
            Policy below.
          </Typography>
          <Box sx={Styles.leagalBtn}>Legal Policy</Box>
          <Box
            component={'img'}
            src={faqsImage}
            sx={{
              height: 'auto',
              width: '100%',
              maxWidth: {
                xl: '70%',
                lg: '70%',
                md: '70%',
                sm: '320px',
                xs: '320px',
              },
            }}
          />
        </Box>
        <Box sx={Styles.accordinStylesBox}>
          <Box
            sx={{
              width: '90%',
              mx: 'auto',
              height: 'auto',
              px: { xl: 3, lg: 3, md: 3, sm: 0, xs: 0 },
            }}
          >
            {tab === 0 ? (
              <Box>
                {tabZero?.map((item, index) => (
                  <div key={index}>
                    {/* Container for active/inactive question and answer */}
                    <Box
                      sx={{
                        width: '100%',
                        mt: 1,
                        borderRadius: '8px',
                        backgroundColor:
                          index === activeIndex
                            ? 'rgba(68, 95, 210, 1)'
                            : 'transparent', // Blue for active
                        color: index === activeIndex ? '#fff' : '#000', // White text when active
                        boxShadow:
                          index === activeIndex
                            ? '0 4px 6px rgba(0, 0, 0, 0.1)'
                            : 'none',
                        transition: 'all 0.2s ease-in-out',
                        borderBottom: '1px solid rgba(230, 233, 245, 1)', // Default border bottom
                      }}
                    >
                      {/* Question Box */}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          cursor: 'pointer',
                          px: 3,
                          py: 2,
                          fontWeight: index === activeIndex ? 'bold' : 'normal',
                          color:
                            index === activeIndex
                              ? '#fff'
                              : 'rgba(6, 28, 61, 1)',
                          borderBottom:
                            index === activeIndex
                              ? 'none'
                              : '1px solid rgba(230, 233, 245, 1)', // Remove border when active
                        }}
                        onClick={() => onTitleClick(index)}
                      >
                        <Typography sx={Styles.faqQuestionText}>
                          {item?.question}
                        </Typography>
                        <Box
                          sx={{
                            fontSize: '20px',
                            color: index === activeIndex ? '#fff' : '#000', // Icon matches text color
                          }}
                        >
                          {index === activeIndex ? '×' : '+'}{' '}
                          {/* Icon toggles */}
                        </Box>
                      </Box>

                      {/* Answer Box (only visible for active item) */}
                      <Box
                        sx={{
                          maxHeight: index === activeIndex ? '1000px' : '0px', // Smooth expand/collapse
                          overflow: 'hidden',
                          transition: 'max-height 0.3s ease-in-out', // Smooth animation
                          px: 3,
                          py: index === activeIndex ? 2 : 0,
                          backgroundColor: 'rgba(240, 243, 255, 1)', // Background for content
                          borderTop:
                            index === activeIndex
                              ? '1px solid rgba(245, 246, 247, 1)'
                              : 'none', // Subtle border for content
                        }}
                      >
                        <Typography sx={Styles.faqAnsweText}>
                          {item?.answer}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                ))}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default Faqs;

const contentStyle = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: { xl: '16px', lg: '16px', md: '16px', sm: '14px', xs: '12px' },
  display: 'flex',
  alignItems: 'center',
  color: 'rgba(73, 75, 122, 1)',
  marginBottom: '15px',
  // textTransform: "lowercase",
};
const AccordionContent = styled(Box)(({ theme }) => ({
  maxHeight: 0,
  overflow: 'hidden',
  transition: 'max-height 0.3s ease-out',
  '&.open': {
    maxHeight: '500px', // Adjust this value based on your content
    transition: 'max-height 0.3s ease-in',
  },
}));

const summerysubText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '12px', xs: '12px' },
  fontWeight: 400,
  textAlign: 'left',
  color: 'rgba(23, 22, 24, 1)',
};
