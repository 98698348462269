import BreadCrumbHeader from "../../../../components/Header/BreadCrumbHeader.jsx";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  Button,
  FormControl,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import axios from "axios";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import DotLoader from "../../../../components/LoadingButton/DotLoader.js";
import GlobalToast from "../../../../components/Toasts/GlobalToast.jsx";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import bulkuploadstep from "../../../../assets/bulkUploadStep.svg";
import CloseIconDialog from "@mui/icons-material/Close";

const useStyles = makeStyles({
  tableRow: {
    border: "1px solid rgba(224, 224, 224, 1)",
  },
});

const MediaOffline = [
  {
    key: 1,
    value: "Offline Media Output File - Newspaper/Magazines",
    name: "News Papers / Magazines",
  },
  {
    key: 2,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Gantry Gate",
  },
  {
    key: 3,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Hoardings",
  },
  {
    key: 4,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Skywalk",
  },
  {
    key: 5,
    value:
      "Offline Media Output File - Hoardings, Sky Walk, Wall Branding, Gantry Gate",
    name: "Wall Branding",
  },
  {
    key: 6,
    value: "Offline Media Output File - Others",
    name: "Activation",
  },
  {
    key: 7,
    value: "Offline Media Output File - Others",
    name: "Ambient Media",
  },
  {
    key: 8,
    value: "Offline Media Output File - Others",
    name: "Banners",
  },
  {
    key: 9,
    value: "Offline Media Output File - Others",
    name: "Boarding Pass",
  },
  {
    key: 10,
    value: "Offline Media Output File - Others",
    name: "Bus Handles",
  },
  {
    key: 11,
    value: "Offline Media Output File - Others",
    name: "Bus Shelters",
  },
  {
    key: 12,
    value: "Offline Media Output File - Others",
    name: "Dangler",
  },
  {
    key: 13,
    value: "Offline Media Output File - Others",
    name: "Door Handles",
  },
  {
    key: 14,
    value: "Offline Media Output File - Others",
    name: "Drop Down Banners",
  },
  {
    key: 15,
    value: "Offline Media Output File - Others",
    name: "Elevator Branding",
  },
  {
    key: 16,
    value: "Offline Media Output File - Others",
    name: "Escalator Branding",
  },
  {
    key: 17,
    value: "Offline Media Output File - Others",
    name: "Flight Wrap",
  },
  {
    key: 18,
    value: "Offline Media Output File - Others",
    name: "Kiosk",
  },
  {
    key: 19,
    value: "Offline Media Output File - Others",
    name: "Others",
  },
  {
    key: 20,
    value: "Offline Media Output File - Others",
    name: "Pole Kiosk",
  },
  {
    key: 21,
    value: "Offline Media Output File - Others",
    name: "Poster",
  },
  {
    key: 22,
    value: "Offline Media Output File - Others",
    name: "Posters",
  },
  {
    key: 23,
    value: "Offline Media Output File - Others",
    name: "Promoter Activity",
  },
  {
    key: 24,
    value: "Offline Media Output File - Others",
    name: "Standee",
  },
  {
    key: 25,
    value: "Offline Media Output File - Others",
    name: "Tent Card",
  },
  {
    key: 26,
    value: "Offline Media Output File - Others",
    name: "Ticket Branding",
  },
  {
    key: 27,
    value: "Offline Media Output File - Others",
    name: "Train Wrap",
  },
];

const MediaOfflineBulkUploadPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [upload, setUpload] = useState(null);
  const [data, setData] = useState(false);
  const [prDropdown, setprDropdown] = useState(true);
  const [loading, setLoading] = useState(false);
  const [offlineCategory, setOfflineCategory] = useState("");
  const [storeCompanyType, setStoreCompanyType] = useState("");
  const [storeStoredFiles, setStoreStoredFiles] = useState([]);

  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    const myForm = new FormData();
    myForm.append("file", file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      setUpload(data);
    };

    reader.onerror = (err) => {};
    reader?.readAsArrayBuffer(file);
    checkuploadExcelFile(file);
  };

  const checkuploadExcelFile = async (myForm) => {
    try {
      await axios
        .post(`publiq_bulk_upload/check_bulkproducts_upload_status`, {
          withCredentials: true,
        })
        .then((response) => {
          console.log(response?.data?.productUplodedstatus, "upload");
          if (response?.data?.productUplodedstatus === false) {
            GlobalToast("Please Upload Previous File Products", "error");
          } else if (response?.data?.productUplodedstatus === true) {
            // GlobalToast("success", "error");
            uploadExcelFile(myForm);
          }
        })
        .catch((error) => {
          GlobalToast(error.response.data.message, "error");
        });
    } catch (error) {
      GlobalToast(error.response.data.message, "error");
    }
  };

  const uploadExcelFile = async (myForm) => {
    if (offlineCategory === "" || !offlineCategory) {
      GlobalToast("Please select an Offline category", "error");
      return;
    }
    try {
      setLoading(true);

      const selectedCategory = MediaOffline.find(
        (item) => item.name === offlineCategory
      );

      const formData = new FormData();
      formData.append("file", myForm);
      formData.append("data", selectedCategory?.value);
      const { data } = await axios.post(
        "publiq_bulk_upload/bulk_upload",
        formData,
        {
          headers: { "Content-type": "multipart/form-data" },
          withCredentials: true,
        }
      );
      if (data?.success === true) {
        toast.success("File uploaded successfully", {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      setData(data?.success);
      setLoading(false);
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.name, {
          position: "top-center",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.error("Network Error:", error.message);
      }
    }
  };

  const findAllWebHookReponsesAndFiles = async () => {
    try {
      await axios
        .get(`publiq_bulk_upload/findAll_BulkUploadResponseAnd_NonStoreFiles`)
        .then((response) => {
          setStoreStoredFiles(response.data?.data);
        })
        .catch((error) => {});
    } catch (error) {}
  };

  const handleMediaOfflineCategoryChange = (event) => {
    const name = event.target.value;

    setOfflineCategory(name);
  };

  const UploadAllProducts = async (id, filedata) => {
    try {
      await axios
        .post(
          `publiq_bulk_upload/upload_bulkUpload_files_mediaoffline`,
          {
            data: id,
          },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          navigate("/home/bulkuploadexcelpreview", {
            state: {
              Link: filedata?.link,
              id: id,
              isMedia: true,
            },
          });
        })
        .catch((error) => {
          GlobalToast(error.response.data.message, "error");
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const confirmedUploadTheFile = async (
    id,
    filedata,
    productUplodedstatus,
    IsAllDeleted
  ) => {
    if (window.confirm("Are you sure you want to add products?")) {
      try {
        await axios
          .post(
            `publiq_bulk_upload/create_file_upload_status`,
            {
              file_data: filedata,
              excel_id: id,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            if (response?.data?.message === "Status Created True") {
              UploadAllProducts(id, filedata);
            } else if (
              response?.data?.message === "Status Already True" &&
              IsAllDeleted
            ) {
              UploadAllProducts(id, filedata);
            } else if (response?.data?.message === "Status Already True") {
              navigate("/home/bulkuploadexcelpreview", {
                state: {
                  Link: filedata?.link,
                  id: id,
                  productUplodedstatus: productUplodedstatus,
                  isMedia: true,
                },
              });
              GlobalToast("File Already Uploaded", "success");
            } else if (response?.data?.message === "Status Changed to True") {
              UploadAllProducts(id, filedata);
            } else if (
              response?.data?.message === "Please Upload Previous File"
            ) {
              GlobalToast("Please Upload All Previous File Products", "error");
            } else if (
              response?.data?.message === "Already Uploaded" &&
              IsAllDeleted
            ) {
              UploadAllProducts(id, filedata);
              GlobalToast("File Already Uploaded", "error");
            } else if (response?.data?.message === "Already Uploaded") {
              GlobalToast("File Already Uploaded", "error");
            }
          })
          .catch((error) => {
            GlobalToast(error.response.data.message, "error");
          });
      } catch (error) {
        GlobalToast(error.response.data.message, "error");
      }
    }
  };

  const handleDeletefileResponse = async (id) => {
    if (window.confirm("Are you sure you want to Delete File?")) {
      try {
        await axios
          .post(
            `publiq_bulk_upload/delete_bulk_res_by_id`,
            {
              excel_id: id,
            },
            {
              withCredentials: true,
            }
          )
          .then((response) => {
            findAllWebHookReponsesAndFiles();
            GlobalToast(response.data.message, "success");
          })
          .catch((error) => {
            GlobalToast(error.data.message, "error");
          });
      } catch (error) {}
    }
  };

  useEffect(() => {
    const companyType = localStorage.getItem("companyType");
    setStoreCompanyType(companyType);
    findAllWebHookReponsesAndFiles();
  }, []);

  return (
    <>
      <BreadCrumbHeader title="Bulk Upload" MainText={"Bulk Upload"} />

      <Paper
        sx={{
          width: "100%",
          height: "70vh",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          py: 1,
        }}
        elevation={0}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data ? (
            <Box
              sx={{
                display: "flex",
                width: "90%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "15px",
                height: "auto",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  width: "100%",
                  textAlign: "start",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (prDropdown) {
                    setprDropdown(false);
                  } else {
                    setprDropdown(true);
                  }
                }}
              >
                Learn Upload Process{" "}
                {prDropdown ? (
                  <KeyboardArrowDownIcon fontSize="large" />
                ) : (
                  <KeyboardArrowUpIcon fontSize="large" />
                )}
              </Typography>
              {prDropdown ? (
                <Box
                  sx={{
                    height: "300px",
                    width: "100%",
                  }}
                >
                  <Box
                    component={"img"}
                    src={bulkuploadstep}
                    sx={{
                      height: "300px",
                      width: "100%",
                    }}
                  />
                </Box>
              ) : null}
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: 500,
                  color: "#444444",
                  fontFamily: "Poppins",
                  marginTop: "24px",
                  fontFamily: "Poppins",
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                }}
              >
                AI is building your product/listing sheet. Min. Wait time 24 hrs{" "}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  textTransform: "none",
                  width: "15%",
                  background: "#c64091",
                  "&:hover": {
                    background: "#fff",
                    color: "#c64091",
                    cursor: "pointer",
                    border: "1px solid #c64091",
                  },
                }}
                onClick={() => {
                  navigate("/home");
                }}
              >
                Explore Marketplace
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "90%",
                flexDirection: "column",
                textAlign: "center",
                gap: "15px",
                height: "auto",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  width: "100%",
                  textAlign: "start",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (prDropdown) {
                    setprDropdown(false);
                  } else {
                    setprDropdown(true);
                  }
                }}
              >
                Learn Upload Process{" "}
                {prDropdown ? (
                  <KeyboardArrowDownIcon fontSize="large" />
                ) : (
                  <KeyboardArrowUpIcon fontSize="large" />
                )}
              </Typography>
              {prDropdown ? (
                <Box
                  sx={{
                    height: "300px",
                    width: "100%",
                    // border: "2px solid #e0e0e0",
                  }}
                >
                  <Box
                    component={"img"}
                    src={bulkuploadstep}
                    sx={{
                      height: "300px",
                      width: "100%",
                    }}
                  />
                </Box>
              ) : null}
              <Typography
                variant="h4"
                sx={{
                  width: "100%",
                  textAlign: "start",
                }}
              >
                Upload Input Excel File
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#444444",
                      fontFamily: "Poppins",
                      width: "50%",
                    }}
                  >
                    Select Category
                  </Typography>
                  <FormControl fullWidth>
                    <select
                      labelId="category-label"
                      value={offlineCategory}
                      label="Category"
                      defaultValue={""}
                      onChange={handleMediaOfflineCategoryChange}
                      style={{
                        border: "1px solid #c64091",
                        height: "35px",
                        width: "50%",
                        borderRadius: "5px",
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        lineHeight: "1.5",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <option
                        value={""}
                        disabled={true}
                        style={{
                          padding: "10px",
                          height: "35px",
                          width: "100%",
                        }}
                      >
                        <Typography variant="h6">{`<-- Select -->`}</Typography>
                      </option>
                      {MediaOffline?.map((res, idx) => {
                        return (
                          <option
                            key={idx}
                            value={res?.name}
                            style={{
                              padding: "10px",
                              height: "35px",
                              width: "100%",
                            }}
                          >
                            <Typography variant="h6">{res?.name}</Typography>
                          </option>
                        );
                      })}
                    </select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#444444",
                      fontFamily: "Poppins",
                    }}
                  >
                    Select Excel File{" "}
                  </Typography>
                  <input
                    type="file"
                    ref={inputFile}
                    onChange={handleFileUpload}
                    style={{ display: "none" }}
                  />
                  <Button
                    variant="contained"
                    onClick={onButtonClick}
                    sx={{
                      height: "40px",
                      fontSize: "14px",
                      textTransform: "none",
                      width: "300px",
                      background: "#c64091",
                      "&:hover": {
                        background: "#fff",
                        color: "#c64091",
                        cursor: "pointer",
                        border: "1px solid #c64091",
                      },
                    }}
                  >
                    Add file <FileUploadIcon fontSize="large" />
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  borderBottom: "2px dashed #e0e0e0",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                  width: "100%",
                  border: "1.5px solid #e0e0e0",
                }}
              >
                <Box
                  sx={{
                    height: "200px",
                    width: "90%",
                    py: 1,
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: "190px",
                      overflowY: "scroll",
                    }}
                  >
                    {storeStoredFiles?.map((res, idx) => {
                      if (res?.nonwebhook_res?.Category?.includes("Offline")) {
                        return (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              justifyContent: "space-around",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Poppins",
                                fontStyle: "normal",
                                fontSize: "20px",
                                fontWeight: 500,
                                width: "50%",
                                textAlign: "start",
                                wordBreak: "break-all",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {res?.data?.status_code === 200
                                ? "File complete and ready for upload"
                                : res?.data?.status_code === 202
                                ? "File with incomplete data"
                                : res?.data?.status_code === 400
                                ? "Input File Error"
                                : res?.data?.Message}
                              <CloseIconDialog
                                sx={{
                                  color: "#667085",
                                  fontSize: "25px",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleDeletefileResponse(res?._id);
                                }}
                              />
                            </Typography>
                            {res?.productUplodedstatus ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                  width: "25%",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  sx={{
                                    height: "40px",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    width: "80%",
                                    background: "#c64091",
                                    "&:hover": {
                                      background: "#fff",
                                      color: "#c64091",
                                      cursor: "pointer",
                                      border: "1px solid #c64091",
                                    },
                                  }}
                                >
                                  Already Uploaded
                                </Button>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-around",
                                  alignItems: "center",
                                  width: "25%",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={
                                    res?.data?.status_code === 202 ||
                                    res?.data?.status_code === 400
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    confirmedUploadTheFile(
                                      res?._id,
                                      res?.data,
                                      res?.productUplodedstatus,
                                      res?.IsAllDeleted
                                    )
                                  }
                                  sx={{
                                    height: "40px",
                                    fontSize: "14px",
                                    textTransform: "none",
                                    width: "auto",
                                    background: "#c64091",
                                    "&:hover": {
                                      background: "#fff",
                                      color: "#c64091",
                                      cursor: "pointer",
                                      border: "1px solid #c64091",
                                    },
                                  }}
                                >
                                  Upload
                                </Button>
                                {res?.data?.status_code === 202 ||
                                res?.data?.status_code === 400 ? (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    target="_blank"
                                    href={res?.data?.Link}
                                    sx={{
                                      height: "40px",
                                      fontSize: "14px",
                                      textTransform: "none",
                                      width: "120px",
                                      background: "#fff",
                                      color: "#c64091",
                                      border: "1px solid #c64091",
                                      "&:hover": {
                                        background: "#c64091",
                                        color: "#fff",
                                        cursor: "pointer",
                                        border: "1px solid #c64091",
                                      },
                                    }}
                                  >
                                    Change File
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                      confirmedUploadTheFile(
                                        res?._id,
                                        res?.data,
                                        res?.productUplodedstatus,
                                        res?.IsAllDeleted
                                      );
                                    }}
                                    sx={{
                                      height: "40px",
                                      fontSize: "14px",
                                      textTransform: "none",
                                      width: "120px",
                                      // width: "auto",
                                      background: "#fff",
                                      color: "#c64091",
                                      border: "1px solid #c64091",
                                      "&:hover": {
                                        background: "#c64091",
                                        color: "#fff",
                                        cursor: "pointer",
                                        border: "1px solid #c64091",
                                      },
                                    }}
                                  >
                                    Preview
                                  </Button>
                                )}
                              </Box>
                            )}
                          </Box>
                        );
                      }
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default MediaOfflineBulkUploadPage;
