import { Paper, Box, Grid, Typography, Card, CardContent } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Fade } from "react-reveal";
import * as Styles from "./Styles.js";
import statebg from "../../../assets/HomePageImages/statebgimg.svg";
import { motion, useAnimation } from "framer-motion";
import axios from "axios";

export default function Diffrance() {
  const controls = useAnimation();
  const [counterData, setCounterData] = useState({});

  const fetchData = async () => {
    try {
      await axios
        .get(`home/company_count`)
        .then((response) => {
          console.log("dsjfghyusdfgsgudfy", response);
          setCounterData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching counter data:", error);
        });
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log("counterData", counterData?.companyCount);

  const statistics = [
    { number: counterData?.companyCount + "+", label: "Happy Businesses" },
    { number: counterData?.ordersCount + "+", label: "Transactions" },
    { number: counterData?.cities + "+", label: "Cities" },
    { number: counterData?.productsCount + "+", label: "Products & Services" },
  ];

  return (
    <Paper
      elevation={0}
      sx={{
        mt: 0,
      }}
    >
      <Box sx={{ position: "relative", height: "100%" }}>
        <Box
          sx={{
            position: "absolute",
            top: "38%",
            left: "5%",
            transform: "translateY(-50%)",
            zIndex: 0,
            display: {
              xl: "block",
              lg: "block",
              md: "none",
              sm: "none",
              xs: "none",
            },
          }}
        >
          <img
            src={statebg}
            alt="Big Logo"
            style={{
              width: "70%",
              height: "auto",
            }}
          />
        </Box>
        <Box sx={Styles.mainboxdefancebox2}>
          <Fade top duration={1000}>
            <Typography sx={{ ...Styles.headText, width: "100%" }}>
              Making A&nbsp;
              <span style={{ color: " rgba(108, 108, 162, 1)" }}>
                Difference
              </span>
            </Typography>
          </Fade>

          <Typography sx={Styles.LetsBartertypo}>
            Think smarter, Let’s Barter !
          </Typography>
        </Box>
        <Box sx={Styles.mainboxmap}>
          <Grid container spacing={4} alignItems="center" sx={{ py: 4, px: 2 }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={4}>
                {statistics.map((item, index) => (
                  <Grid item xs={6} sm={6} key={index}>
                    <Card sx={Styles.carddata}>
                      <CardContent>
                        <Typography sx={Styles.typonumber}>
                          {item.number}
                        </Typography>
                        <Typography sx={Styles.typoame}>
                          {item.label}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: {
                    xl: "80%",
                    lg: "80%",
                    md: "80%",
                    sm: "95%",
                    xs: "95%",
                  },
                  mx: "auto",
                }}
              >
                <Box
                  component="img"
                  src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/IndiaMap.png"
                  alt="India Map"
                  sx={{
                    width: "100%",
                    height: "auto",
                    zIndex: 1,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
