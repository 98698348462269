import React from "react";
import { Box, Grid } from "@mui/material";
const MainLogo = () => {
  return (
    <Box
      sx={{
        width: "100px",
        position: "absolute",
        top: "1%",
        left: "1%",
        cursor: "pointer",
      }}
      onClick={() => {
        window.location.href = "/";
      }}
    >
      <img
        src="https://bxidevelopment1.s3.ap-south-1.amazonaws.com/assets/BXI_LOGO.png"
        alt="img"
        style={{
          height: "auto",
          width: "60px",
        }}
      />
    </Box>
  );
};

export default MainLogo;
