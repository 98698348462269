import { Paper, Box, Typography, Button } from '@mui/material';
import React from 'react';
import ClientMarquee from './ClientMarquee';
import { Fade } from 'react-reveal';
import * as Styles from './Styles';
import ClientBox from './ClientBox';
import HorizontalScrollingList from './HorizontalScrollingList';
import jonnow from '../../../assets/HomePageImages/joinnowBackground.svg';
import smjonnow from '../../../assets/HomePageImages/joinnowsmback.svg';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Brand() {
  const navigate = useNavigate();

  const theme = useTheme();

  // Media queries for detecting breakpoints
  const isMdAndUp = useMediaQuery(theme.breakpoints.up('md')); // Matches md, lg, xl

  const clients = [
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/GTPL.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Go+first.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Foce+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/GMR+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Free+Press+Journal+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Fintch.png',
    },

    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Dollar.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Della+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Clothing+cltur.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Limelight.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Mast.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Laromani.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/KAribo+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/jade+blue.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/KFC.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Khushi+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/IRA+(2).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/Hyatt+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/HAwa.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/email-templates/VR.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/WIngreen.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Xech.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Udaywani+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Twills.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Royal+orchid+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/PPZ+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Tea+Culture.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Timezone.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/TravelBiz.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Byke.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/Emotorad.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/BG+(1).png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/MID+day.png',
    },
    {
      image:
        'https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BxiStatic/MOD+(1).png',
    },

    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Bright+Outdoor+SVG.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Chilzo+SVG.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Cinepolis+SVG.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Conplex+SVG.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Divya+Media+SVG.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Dr.+Auto.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/DSL+Mall.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Emotorad.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/ENIL.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Enrich.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Finch.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Gautam+Hotel.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Growels+mall.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Hammer.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/HarKoi+Perfums.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Holiday+Inn.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/India+Today.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Karibo.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Khushi+Media.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/Korum+Mall.svg',
    },
    {
      image:
        'https://bxilanding-page.s3.ap-south-1.amazonaws.com/Logos+SVG+(1)/Logos+SVG/LYNE.svg',
    },
  ];

  return (
    <Paper elevation={0}>
      <Box sx={{ marginBottom: '30px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '30px',
            marginBottom: '30px',
          }}
        >
          <Fade top duration={1000}>
            <Typography
              variant="inherit"
              component="span"
              sx={{
                ...Styles.headingStyle,
                fontWeight: 600,
                fontSize: {
                  xl: '30px',
                  lg: '30px',
                  md: '30px',
                  sm: '20px',
                  xs: '20px',
                },
              }}
            ></Typography>
            <Typography sx={{ ...Styles.headText, width: '100%' }}>
              Brands Active&nbsp;
              <span style={{ color: ' rgba(108, 108, 162, 1)' }}>With BXI</span>
            </Typography>
          </Fade>
        </Box>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <HorizontalScrollingList fromLeft sx={{ mt: 1 }}>
            {clients.map((client, idx) => {
              return <ClientBox key={idx} image={client.image} />;
            })}
          </HorizontalScrollingList>
          <ClientMarquee />
        </Box>

        <Box sx={Styles.jonnowmainbox}>
          <Box
            component="img"
            // src={jonnow}
            src={isMdAndUp ? jonnow : smjonnow}
            alt="Background"
            sx={Styles.joinnowimg}
          />

          <Box sx={Styles.joinnowsecbox}>
            <Typography sx={Styles.typo1}>
              “Sell, Buy, Exchange Through Barter “
            </Typography>

            <Typography sx={Styles.typo2}>Pay By Products</Typography>

            <Box sx={Styles.buttonmainbox}>
              <Button
                variant="contained"
                sx={Styles.buttonjoinow}
                onClick={() => navigate('/createaccount')}
              >
                Join Now
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
