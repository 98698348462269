import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TableContainer,
  Skeleton,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import CommaSeprator from "../components/TransactionsCommaSeprator.js";
import BreadCrumbHeader from "../components/Header/BreadCrumbHeader";
import useGetLoggedInUser from "../Hooks/LoggedInUser/useGetLoggedInUser";
import { HiDownload } from "react-icons/hi";
import "jspdf-autotable";
import GetCompanyNameNProfile from "../components/common/GetCompanyNameNProfile.js";
import * as XLSX from "xlsx/xlsx.mjs";
import "./WalletTransaction.css";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const financialYearArray = [
  { value: [2021, new Date().getFullYear()], label: "All" },
  { value: [2021, 2022], label: "2021-2022" },
  { value: [2022, 2023], label: "2022-2023" },
  { value: [2023, 2024], label: "2023-2024" },
  { value: [2024, 2025], label: "2024-2025" },
];

const TransactionsPage = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [initialPaginationCount, setInitialPaginationCount] = useState(null);
  const [completedEntries, setCompletedEntries] = useState(true);
  const [Statement, setStatement] = useState([]);
  const [Statement_no_limit, setStatement_no_limit] = useState([]);
  const [storeFinancialDate, setStoreFinancialDate] = useState({
    startDate: "2021",
    endDate: new Date().getUTCFullYear(),
  });
  const [selectFromDate, setSelectFromDate] = useState(dayjs("2021-01-01"));
  const [selectToDate, setSelectToDate] = useState(dayjs());
  const [PendingStatement, setPendingStatement] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pcurrentPage, setPCurrentPage] = useState(1);

  const [loading, setLoading] = useState(true);
  const [pendingTransactionLength, setPendingTransactionLength] = useState();

  const formatDateTime = (dateTimeString) => {
    const createdAtDateTime = new Date(dateTimeString);
    const timeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedTime = createdAtDateTime.toLocaleTimeString(
      "en-US",
      timeFormatOptions
    );

    return `${formattedTime}`;
  };

  const {
    data: userData,
    isLoading: userLoading,
    isError: userError,
    isRefetching,
  } = useGetLoggedInUser();

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handlePendingPageChange = (event, newPage) => {
    setPCurrentPage(newPage);
  };

  const handleChangeFinancialDate = async (e) => {
    try {
      setStoreFinancialDate({
        startDate: e.split(",")[0],
        endDate: e.split(",")[1],
      });
      setCurrentPage(1);
    } catch (error) {}
  };

  const fetch_user_wallet_statement = async ({
    userData,
    storeFinancialDate,
    selectToDate,
    selectFromDate,
  }) => {
    try {
      setLoading(true);
      await axios
        .post(`wallet/get_user_wallet_statement?page=${currentPage}`, {
          User: userData,
          financialYear: storeFinancialDate ?? {},
          selectFromDate: selectFromDate ?? new Date("01/01/2021"),
          selectToDate: selectToDate ?? new Date(),
          pcurrentPage: pcurrentPage,
        })
        .then((response) => {
          console.log("sdjhgfytsdgyfgudsvf", response);
          setLoading(false);
          setStatement(response?.data?.transactionsData);
          setPendingStatement(response?.data?.pending_transactions);
          setInitialPaginationCount(response?.data?.finalCount);
          setPendingTransactionLength(
            Math.ceil(response?.data?.pendingTransactionsLength / 10)
          );
        });
    } catch (error) {
      setLoading(false);
    }
  };
  const fetch_user_wallet_statement_no_limit = async ({ userData }) => {
    try {
      setLoading(true);
      await axios
        .post(`wallet/get_user_wallet_noLimit_statement`, {
          User: userData,
        })
        .then((response) => {
          setStatement_no_limit(response?.data?.transactionsData);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleFromDateChange = async (e) => {
    setSelectFromDate(new Date(e?.$d)?.toISOString());
  };

  const handleToDateChange = async (e) => {
    setSelectToDate(new Date(e?.$d)?.toISOString());
  };

  useEffect(() => {
    fetch_user_wallet_statement({
      userData,
      storeFinancialDate,
      selectToDate,
      selectFromDate,
    });
  }, [
    currentPage,
    storeFinancialDate,
    selectToDate,
    setSelectToDate,
    pcurrentPage,
  ]);

  useEffect(() => {
    fetch_user_wallet_statement_no_limit({ userData });
  }, []);

  const make_commission_payment = async (OrderData) => {
    const TotalAmountToPay = [
      OrderData?.INRDetails?.TDS,
      OrderData?.INRDetails?.bxiCommisionGST ||
        OrderData?.INRDetails?.BxiCommisionGST,
      OrderData?.INRDetails?.totalBxiCommission ||
        OrderData?.INRDetails?.TotalBxiCommission,
    ]
      .map((value) => Number(value) || 0)
      .reduce((acc, curr) => acc + curr, 0);
    if (TotalAmountToPay > 1) {
      await axios
        .post(`commission/bxi_commision_payment_dashboard`, {
          OrderId: OrderData?.OrderId,
        })
        .then((res) => {
          window.open(res?.data?.paymentURL, "_blank");
        })
        .catch((err) => {
          alert("Failed to make payment");
        });
    } else {
      toast.error("Minimum 1 rupee is required for payment", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  return (
    <Paper elevation={0} sx={{ bgcolor: "transparent", boxShadow: "none" }}>
      <BreadCrumbHeader MainText={"Transactions"} showbreadcrumb={true} />

      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "17px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
            // padding: "2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Financial Year & Date Filters Section */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
                backgroundColor: "transparent",
                padding: "8px",
                borderRadius: "8px",
              }}
            >
              {/* Financial Year Dropdown */}
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#2261A2",
                  }}
                >
                  Financial Year
                </Typography>
                <select
                  style={{
                    borderRadius: "5px",
                    background: "#fff",
                    color: "#2261A2",
                    border: "1px solid lightgray",
                    marginTop: "5px",
                    width: "180px",
                    height: "46px",
                    textAlign: "center",
                  }}
                  onChange={(e) => handleChangeFinancialDate(e.target.value)}
                >
                  {financialYearArray?.map((response) => (
                    <option key={response.value} value={response.value}>
                      {response.label}
                    </option>
                  ))}
                </select>
              </Box>

              {/* From Date Picker */}
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#2261A2",
                    marginBottom: "5px",
                  }}
                >
                  From Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    value={selectFromDate}
                    onChange={(e) => handleFromDateChange(e)}
                  />
                </LocalizationProvider>
              </Box>

              {/* To Date Picker */}
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#2261A2",
                    marginBottom: "5px",
                  }}
                >
                  To Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    value={selectToDate}
                    onChange={(e) => handleToDateChange(e)}
                  />
                </LocalizationProvider>
              </Box>
            </Box>

            {/* Download Statement Section */}
            {completedEntries && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  backgroundColor: "transparent",
                  padding: "15px",
                  borderRadius: "8px",
                }}
              >
                {Statement_no_limit?.length > 0 ? (
                  <GeneratePDFStatement
                    StatementData={Statement_no_limit?.slice()?.reverse()}
                    UserData={userData}
                  />
                ) : (
                  <Button
                    sx={{
                      background: "#2261A2",
                      borderRadius: "17px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "12px",
                      textTransform: "none",
                      color: "#F8F8F8",
                      width: "200px",
                      height: "40px",
                      "&:hover": {
                        background: "#2261A2",
                      },
                    }}
                  >
                    Fetching Data
                    <div className="transactionloader"></div>
                  </Button>
                )}
              </Box>
            )}
          </Box>

          {Statement?.length === 0 ? null : (
            <Box
              sx={{
                width: "100%",
                height: "auto",
              }}
            >
              {/* Completed and Pending Entries Buttons Section */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  backgroundColor: "#f9f9f9",
                  gap: 5,
                }}
              >
                {/* Completed Entries Button */}
                <Box
                  sx={{
                    padding: "10px 40px",
                    border: completedEntries
                      ? "1px solid #2261A2"
                      : "1px solid #6B7A99",
                    backgroundColor: completedEntries
                      ? "#2261A2"
                      : "transparent",
                    color: completedEntries ? "#FFFFFF" : "#6B7A99",
                    cursor: "pointer",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "13px",
                  }}
                  onClick={() => setCompletedEntries(!completedEntries)}
                >
                  Completed Entries
                </Box>

                {/* Pending Entries Button */}
                <Box
                  sx={{
                    padding: "10px 40px",
                    border: !completedEntries
                      ? "1px solid #2261A2"
                      : "1px solid #6B7A99",
                    backgroundColor: !completedEntries
                      ? "#2261A2"
                      : "transparent",
                    color: !completedEntries ? "#FFFFFF" : "#6B7A99",
                    cursor: "pointer",
                    borderRadius: "5px",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    fontSize: "13px",
                  }}
                  onClick={() => setCompletedEntries(!completedEntries)}
                >
                  Pending Entries
                </Box>
              </Box>

              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                }}
              >
                <TableContainer sx={TableContainerStyle}>
                  {loading ? (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={21}>
                          <Skeleton
                            animation="wave"
                            sx={{
                              height: "65px",
                              width: "100%",
                            }}
                          />
                          <Skeleton
                            animation="wave"
                            sx={{
                              height: "65px",
                              width: "100%",
                            }}
                          />
                          <Skeleton
                            animation="wave"
                            sx={{
                              height: "65px",
                              width: "100%",
                            }}
                          />
                          <Skeleton
                            animation="wave"
                            sx={{
                              height: "65px",
                              width: "100%",
                            }}
                          />
                          <Skeleton
                            animation="wave"
                            sx={{
                              height: "65px",
                              width: "100%",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ) : completedEntries ? (
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>No</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Date</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Company Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Order Number
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Type</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Debit</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Credit</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>TDS</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Account Balance
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Credit Due Balance
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Wallet Usable Balance
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Other</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              BXI Commission
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Assessable Value
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>GST</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Total Invoice Value
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>TDS</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Net Receivable/(Payable)
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>INR</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Coins</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              TDS (26AS)
                            </Typography>
                          </TableCell>
                          <TableCell style={{ width: "300px" }}>
                            <Typography sx={TableTitleStyle}>
                              Invoice Value
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Statement?.map((row, index) => {
                          let price;
                          if (
                            row._from_wallet_id === "admin" ||
                            row._from_wallet_id === userData?.data?._id
                          ) {
                            price = row._from_wallet_balance;
                          } else {
                            price = row._to_wallet_balance;
                          }

                          const getTotalGST = (row) => {
                            if (
                              row?._from_wallet_id !== "admin" ||
                              row?._type === "add_coupon" ||
                              row?._type === "sell_coupon"
                            ) {
                              if (
                                row?.OrderData?.BuyerChoosedTransportation
                                  ?.TransportationType === "Seller"
                              ) {
                                return (
                                  (row?.INRDetails
                                    ?.totalTransportationCostGST ?? 0) +
                                  (row?.INRDetails?.bxiCommisionGST ?? 0) +
                                  (row?.INRDetails?.totalGstAmount ?? 0) +
                                  (row?.INRDetails?.InsuranceCostGstValue ??
                                    0) +
                                  (row?.INRDetails
                                    ?.bxiCommisionOnTransportationGST ?? 0) +
                                  (row?.INRDetails?.totalGstInrOfAdCost ?? 0) +
                                  (row?.INRDetails?.packagingCostGSTValue ?? 0)
                                );
                              } else if (row?.OrderData?.IsMedia) {
                                return (
                                  row?.INRDetails?.BxiCommisionGST +
                                  row?.INRDetails?.TotalGstAmount +
                                  row?.INRDetails?.TotalGstInrOfAdCost
                                );
                              } else {
                                return (
                                  (row?.INRDetails?.bxiCommisionGST ?? 0) +
                                  (row?.INRDetails?.totalGstAmount ?? 0) +
                                  (row?.INRDetails?.totalGstInrOfAdCost ?? 0)
                                );
                              }
                            } else {
                              return null;
                            }
                          };

                          let Amount = row?._balance + row?.INRDetails?.TDS;

                          const getTotalBalance = (row) => {
                            if (
                              row?.OrderData?.BuyerChoosedTransportation
                                ?.TransportationType === "Seller"
                            ) {
                              return (
                                (Amount ?? 0) +
                                (row?.INRDetails?.totalTransportationCost ??
                                  0) +
                                (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
                                (row?.INRDetails?.InsuranceCost ?? 0) +
                                (row?.INRDetails?.packagingCost ?? 0) +
                                (row?.INRDetails?.totalBxiCommission ?? 0)
                              );
                            } else if (row?.OrderData?.IsMedia) {
                              return (
                                (Amount ?? 0) +
                                (row?.INRDetails?.TotalValueInrOfAdCost ?? 0) +
                                (row?.INRDetails?.TotalBxiCommission ?? 0)
                              );
                            } else {
                              return (
                                (Amount ?? 0) +
                                (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
                                (row?.INRDetails?.totalBxiCommission ?? 0)
                              );
                            }
                          };

                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  {row._from_wallet_id === userData?.data?._id
                                    ? convertDate(row?._created_at)
                                    : convertDate(row?._release_date)}
                                  <br />
                                  at{" "}
                                  {row._from_wallet_id === userData?.data?._id
                                    ? formatDateTime(row?._created_at)
                                    : formatDateTime(row?._release_date)}
                                </Typography>
                              </TableCell>
                              {/* company profile */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <GetCompanyNameNProfile
                                    CompanyId={
                                      row?._from_wallet_id ===
                                      userData?.data?._id
                                        ? row?._to_wallet_id
                                        : row?._from_wallet_id
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              {/* order number */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  {row?._order_number?.includes("BXI")
                                    ? row?._order_number
                                    : row?._clnumber || row?._order_number}
                                </Typography>
                              </TableCell>
                              {/* Type */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  {row?._type === "sell_recover_coupon"
                                    ? "Sell/PrivateDeal"
                                    : row?._type === "recover_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?._type === "sell_coupon" &&
                                      row?._from_wallet_id !==
                                        userData?.data?._id
                                    ? "Sell/PrivateDeal"
                                    : row?._type === "sell_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?._type === "add_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?._type === "refund"
                                    ? "Refund"
                                    : row?._type?.includes("credit_line")
                                    ? "Credit Line"
                                    : row?._from_wallet_id ===
                                      userData?.data?._id
                                    ? "Buy"
                                    : "Sell"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?._from_wallet_id ===
                                        userData?.data?._id &&
                                      row?._type === "refund"
                                        ? null
                                        : row?._from_wallet_id ===
                                          userData?.data?._id
                                        ? Amount
                                        : row?._type === "credit_line_recovery"
                                        ? Amount
                                        : null
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?._from_wallet_id ===
                                        userData?.data?._id &&
                                      row?._type === "refund"
                                        ? Amount
                                        : row?._from_wallet_id !==
                                          userData?.data?._id
                                        ? row?._type === "add_coupon"
                                          ? Amount
                                          : row?._from_wallet_id === "admin" &&
                                            row?._type === "sell_coupon"
                                          ? Amount
                                          : row?._from_wallet_id === "admin"
                                          ? null
                                          : Amount
                                        : null
                                    }
                                  />
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator Price={row?.INRDetails?.TDS} />
                                </Typography>
                              </TableCell>
                              {/* account balance */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  {price < 0 ? (
                                    <>
                                      -
                                      <CommaSeprator Price={Math.abs(price)} />
                                    </>
                                  ) : (
                                    <CommaSeprator Price={price} />
                                  )}
                                </Typography>
                              </TableCell>
                              {/* credit due */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?._type === "add_coupon"
                                        ? row?._from_wallet_credit_due
                                        : row?._from_wallet_id === "admin" &&
                                          row?._type === "sell_coupon"
                                        ? row?._from_wallet_credit_due?.toFixed(
                                            2
                                          )
                                        : row?._from_wallet_id === "admin"
                                        ? row?._to_wallet_credit_due?.toFixed(2)
                                        : row?._from_wallet_id ===
                                          userData?.data?._id
                                        ? row?._from_wallet_credit_due?.toFixed(
                                            2
                                          )
                                        : row?._to_wallet_credit_due?.toFixed(2)
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              {/* Wallet Usable Balance */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?._type === "add_coupon"
                                        ? row?._from_wallet_usable_balance
                                        : row?._from_wallet_id === "admin" &&
                                          row?._type === "sell_coupon"
                                        ? row?._from_wallet_usable_balance?.toFixed(
                                            2
                                          )
                                        : row?._from_wallet_id === "admin"
                                        ? row?._to_wallet_usable_balance?.toFixed(
                                            2
                                          )
                                        : row?._from_wallet_id ===
                                          userData?.data?._id
                                        ? row?._from_wallet_usable_balance?.toFixed(
                                            2
                                          )
                                        : row?._to_wallet_usable_balance?.toFixed(
                                            2
                                          )
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  {row?.OrderData?.BuyerChoosedTransportation
                                    ?.TransportationType === "Seller" ? (
                                    <CommaSeprator
                                      Price={
                                        row?.INRDetails
                                          ?.totalTransportationCost +
                                          row?.INRDetails
                                            ?.totalValueInrOfAdCost +
                                          row?.INRDetails?.packagingCost +
                                          row?.INRDetails?.InsuranceCost || 0
                                      }
                                    />
                                  ) : (
                                    <CommaSeprator
                                      Price={
                                        row?.INRDetails?.totalValueInrOfAdCost +
                                          row?.INRDetails?.packagingCost ||
                                        row?.INRDetails?.TotalValueInrOfAdCost +
                                          row?.INRDetails?.packagingCost ||
                                        0
                                      }
                                    />
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?.INRDetails?.TotalBxiCommission ||
                                      row?.INRDetails?.totalBxiCommission ||
                                      0
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator Price={getTotalBalance(row)} />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator Price={getTotalGST(row)} />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      getTotalBalance(row) + getTotalGST(row)
                                        ? (
                                            getTotalBalance(row) +
                                            getTotalGST(row)
                                          )?.toFixed(2)
                                        : 0
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator Price={row?.INRDetails?.TDS} />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?._from_wallet_id !==
                                      userData?.data?._id
                                        ? getTotalBalance(row) +
                                          getTotalGST(row) -
                                          row?.INRDetails?.TDS
                                        : getTotalBalance(row) +
                                          getTotalGST(row)
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={row?.INRDetails?.TotalInrToPay}
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator Price={row?._balance} />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?._from_wallet_id !==
                                      userData?.data?._id
                                        ? row?.INRDetails?.TDS
                                        : null
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: "black",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      getTotalBalance(row) + getTotalGST(row)
                                        ? (
                                            getTotalBalance(row) +
                                            getTotalGST(row)
                                          )?.toFixed(2)
                                        : 0
                                    }
                                  />
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>No</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Date</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Pay Dues
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Company Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>
                              Order Number
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Type</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Debit</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>Credit</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography sx={TableTitleStyle}>TDS</Typography>
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            <Typography sx={TableTitleStyle}>
                              Net trade Credits
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {PendingStatement?.map((row, index) => {
                          const wallet_release =
                            Boolean(row?.OrderData?.commissionJuspayResponse) ||
                            row?._type === "refund" ||
                            row?._refunded ||
                            row?._from_wallet_id === "admin" ||
                            (row?._document_type === "delivery_confirmed" &&
                              row?._from_wallet_id === userData?.data?._id);

                          let price = 0;
                          if (
                            row._from_wallet_id === "admin" ||
                            row._from_wallet_id === userData?.data?._id
                          ) {
                            price = row._from_wallet_balance;
                          } else {
                            price = row._to_wallet_balance;
                          }

                          let Amount = row?._balance + row?.INRDetails?.TDS;

                          const commissionTotals =
                            row?.INRDetails?.TDS +
                            row?.INRDetails?.totalBxiCommission +
                            row?.INRDetails?.bxiCommisionGST;

                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  {convertDate(row?._updated_at)}
                                  <br />
                                  at {formatDateTime(row?._updated_at)}
                                </Typography>
                              </TableCell>
                              {/* Pay Dues */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  {row?.OrderData?.escrowSellerReleaseResponse
                                    ?.success ? (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        backgroundColor: "#2261a2",
                                        width: "180px",
                                        height: "40px",
                                        textTransform: "none",
                                        fontFamily: "Poppins",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        borderRadius: "10px",
                                        "&:hover": {
                                          backgroundColor: "#2261a2",
                                        },
                                      }}
                                      onClick={() => {
                                        make_commission_payment(row?.OrderData);
                                      }}
                                    >
                                      Pay Dues | ₹{commissionTotals?.toFixed(2)}
                                    </Button>
                                  ) : null}
                                </Typography>
                              </TableCell>
                              {/* company profile */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  <GetCompanyNameNProfile
                                    CompanyId={
                                      row?._from_wallet_id ===
                                      userData?.data?._id
                                        ? row?._to_wallet_id
                                        : row?._from_wallet_id
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              {/* order number */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  {row?._order_number?.includes("BXI")
                                    ? row?._order_number
                                    : row?._clnumber || row?._order_number}
                                </Typography>
                              </TableCell>
                              {/* Type */}
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  {row?._type === "sell_recover_coupon"
                                    ? "Sell/PrivateDeal"
                                    : row?._type === "recover_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?._type === "sell_coupon" &&
                                      row?._from_wallet_id !==
                                        userData?.data?._id
                                    ? "Sell/PrivateDeal"
                                    : row?._type === "sell_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?._type === "add_coupon"
                                    ? "Buy/PrivateDeal"
                                    : row?._type === "refund"
                                    ? "Refund"
                                    : row?._type?.includes("credit_line")
                                    ? "Credit Line"
                                    : row?._from_wallet_id ===
                                      userData?.data?._id
                                    ? "Buy"
                                    : "Sell"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?._from_wallet_id ===
                                        userData?.data?._id &&
                                      row?._type === "refund"
                                        ? Amount
                                        : row?._from_wallet_id ===
                                          userData?.data?._id
                                        ? Amount
                                        : row?._type === "credit_line_recovery"
                                        ? Amount
                                        : row?._type === "refund"
                                        ? Amount
                                        : null
                                    }
                                  />
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  <CommaSeprator
                                    Price={
                                      row?._from_wallet_id ===
                                        userData?.data?._id &&
                                      row?._type === "refund"
                                        ? Amount
                                        : row?._from_wallet_id !==
                                          userData?.data?._id
                                        ? row?._type === "add_coupon"
                                          ? Amount
                                          : row?._from_wallet_id === "admin" &&
                                            row?._type === "sell_coupon"
                                          ? Amount
                                          : row?._from_wallet_id === "admin"
                                          ? null
                                          : row?._type === "refund"
                                          ? null
                                          : Amount
                                        : null
                                    }
                                  />
                                </Typography>
                              </TableCell>

                              <TableCell>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  <CommaSeprator Price={row?.INRDetails?.TDS} />
                                </Typography>
                              </TableCell>

                              <TableCell sx={{ textAlign: "center" }}>
                                <Typography
                                  sx={{
                                    ...TextStyleCommon,
                                    color: wallet_release ? "black" : "#FF8E00",
                                  }}
                                >
                                  <CommaSeprator Price={row?._balance} />
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </Paper>
            </Box>
          )}
        </Grid>
      </Grid>
      {completedEntries && Statement?.length > 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
          <Stack spacing={2}>
            <StyledPagination
              count={initialPaginationCount}
              color="primary"
              page={currentPage}
              onChange={(event, newPage) => handlePageChange(event, newPage)}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Box>
      ) : null}
      {!completedEntries && pendingTransactionLength > 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }} mt={2}>
          <Stack spacing={2}>
            <StyledPagination
              count={pendingTransactionLength}
              color="primary"
              page={pcurrentPage}
              onChange={(event, newPage) =>
                handlePendingPageChange(event, newPage)
              }
              showFirstButton
              showLastButton
            />
          </Stack>
        </Box>
      ) : null}
    </Paper>
  );
};

export default TransactionsPage;

const GeneratePDFStatement = (props) => {
  let ExcelData = props?.StatementData;
  const convertDate = (date) => {
    return new Date(date);
  };

  const generatePDFStatement = async () => {
    async function getCompanyByName(props) {
      let datda = await axios
        .get(`company/get_company/${props}`)
        .then((res) => {
          return res?.data?.companyName;
        });

      return datda;
    }

    const wb = XLSX.utils.book_new();

    const headerRows = [
      [`Company Name: ${props?.UserData?.data?.companyName}`],
      [`Email: ${props?.UserData?.data?.email}`],
      [`Address: ${props?.UserData?.data?.RegisteredAddress}`],
      [`Statement Date: ${new Date().toDateString()}`],
      [],
      [
        "Sr No",
        "Date",
        "Company Name",
        "Order Number",
        "Type",
        "Debit",
        "Credit",
        "TDS",
        "Account Balance",
        "Credit Due Balance",
        "Wallet Usable Balance",
        "Other",
        "BXI Commission",
        "Assessable Value",
        "GST",
        "Total Invoice Value",
        "TDS",
        "Net Receivable/(Payable)",
        "INR",
        "Coins",
        "TDS (26AS)",
        "Invoice Value",
      ],
    ];

    const infoWs = XLSX.utils.aoa_to_sheet(headerRows);

    await Promise.all(
      ExcelData?.map(async (row, index) => {
        const getTotalGST = (row) => {
          if (
            row?._from_wallet_id !== "admin" ||
            row?._type === "add_coupon" ||
            row?._type === "sell_coupon"
          ) {
            if (
              row?.OrderData?.BuyerChoosedTransportation?.TransportationType ===
              "Seller"
            ) {
              return (
                (row?.INRDetails?.totalTransportationCostGST ?? 0) +
                (row?.INRDetails?.bxiCommisionGST ?? 0) +
                (row?.INRDetails?.totalGstAmount ?? 0) +
                (row?.INRDetails?.InsuranceCostGstValue ?? 0) +
                (row?.INRDetails?.bxiCommisionOnTransportationGST ?? 0) +
                (row?.INRDetails?.totalGstInrOfAdCost ?? 0) +
                (row?.INRDetails?.packagingCostGSTValue ?? 0)
              );
            } else if (row?.OrderData?.IsMedia) {
              return (
                row?.INRDetails?.BxiCommisionGST +
                row?.INRDetails?.TotalGstAmount +
                row?.INRDetails?.TotalGstInrOfAdCost
              );
            } else {
              return (
                (row?.INRDetails?.bxiCommisionGST ?? 0) +
                (row?.INRDetails?.totalGstAmount ?? 0) +
                (row?.INRDetails?.totalGstInrOfAdCost ?? 0)
              );
            }
          } else {
            return 0;
          }
        };

        let Amount = row?._balance + row?.INRDetails?.TDS;

        const getTotalBalance = (row) => {
          if (
            row?.OrderData?.BuyerChoosedTransportation?.TransportationType ===
            "Seller"
          ) {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.totalTransportationCost ?? 0) +
              (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.InsuranceCost ?? 0) +
              (row?.INRDetails?.packagingCost ?? 0) +
              (row?.INRDetails?.totalBxiCommission ?? 0)
            );
          } else if (row?.OrderData?.IsMedia) {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.TotalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.TotalBxiCommission ?? 0)
            );
          } else {
            return (
              (Amount ?? 0) +
              (row?.INRDetails?.totalValueInrOfAdCost ?? 0) +
              (row?.INRDetails?.totalBxiCommission ?? 0)
            );
          }
        };

        const otherBalance = (row) => {
          return row?.OrderData?.BuyerChoosedTransportation
            ?.TransportationType === "Seller"
            ? row?.INRDetails?.totalTransportationCost +
                row?.INRDetails?.totalValueInrOfAdCost +
                row?.INRDetails?.packagingCost +
                row?.INRDetails?.InsuranceCost || "-"
            : row?.INRDetails?.totalValueInrOfAdCost ||
                row?.INRDetails?.TotalValueInrOfAdCost ||
                0;
        };

        // let Amount = (row?._balance + row?.INRDetails?.TDS).toFixed(2);

        let companyNamessss =
          (await getCompanyByName(
            row?._from_wallet_id === props?.UserData?.data?._id
              ? row?._to_wallet_id
              : row?._from_wallet_id
          )) || "Barter Exchange of India";

        let _closing_balance = 0;

        switch (true) {
          case row?._from_wallet_id === props?.UserData?.data?._id:
            _closing_balance = row?._closing_balance_buyer?.toFixed(2);
            break;
          case row?._to_wallet_id !== props?.UserData?.data?._id:
            _closing_balance = row?._closing_balance_seller?.toFixed(2);
            break;
          default:
            _closing_balance = row?._closing_balance_buyer?.toFixed(2);
            break;
        }
        if (row?._type === "credit_line") {
          _closing_balance = row?._closing_balance_buyer?.toFixed(2);
        }

        const rowData = [
          // srno
          index + 1,
          // Date
          convertDate(row._created_at)?.toLocaleString("en-IN"),
          // compny name
          companyNamessss,
          // Order number
          row?._order_number?.includes("BXI")
            ? row?._order_number
            : row?._clnumber || row?._order_number,
          // type
          row?._type === "sell_recover_coupon"
            ? "Sell/PrivateDeal"
            : row?._type === "recover_coupon"
            ? "Buy/PrivateDeal"
            : row?._type === "sell_coupon" &&
              row?._from_wallet_id !== props?.UserData?.data?._id
            ? "Sell/PrivateDeal"
            : row?._type === "sell_coupon"
            ? "Buy/PrivateDeal"
            : row?._type === "add_coupon"
            ? "Buy/PrivateDeal"
            : row?.type === "refund"
            ? "Refund"
            : row?._type?.includes("credit_line")
            ? "Credit Line"
            : row?._from_wallet_id === props?.UserData?.data?._id
            ? "Buy"
            : "Sell",
          // Debit
          row?._from_wallet_id === props?.UserData?.data?._id
            ? Amount.toFixed(2)
            : row?._type === "credit_line_recovery"
            ? Amount.toFixed(2)
            : null,
          // credit
          row?._from_wallet_id !== props?.UserData?.data?._id
            ? row?._type === "add_coupon"
              ? Amount?.toFixed(2)
              : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
              ? Amount?.toFixed(2)
              : row?._from_wallet_id === "admin"
              ? null
              : Amount?.toFixed(2)
            : null,
          // TDS
          row?.INRDetails?.TDS ? row?.INRDetails?.TDS.toFixed(2) : null,
          // acc balance
          row._from_wallet_id === props?.UserData?.data?._id ||
          row._from_wallet_id === "admin"
            ? row?._from_wallet_balance?.toFixed(2)
            : row?._to_wallet_balance?.toFixed(2),
          // Credit Due Balance
          row?._type === "add_coupon"
            ? row?._from_wallet_credit_due
            : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
            ? row?._from_wallet_credit_due?.toFixed(2)
            : row?._from_wallet_id === "admin"
            ? row?._to_wallet_credit_due?.toFixed(2)
            : row?._from_wallet_id === props?.UserData?.data?._id
            ? row?._from_wallet_credit_due?.toFixed(2)
            : row?._to_wallet_credit_due?.toFixed(2),
          // Wallet Usable Balance
          row?._type === "add_coupon"
            ? row?._from_wallet_usable_balance
            : row?._from_wallet_id === "admin" && row?._type === "sell_coupon"
            ? row?._from_wallet_usable_balance?.toFixed(2)
            : row?._from_wallet_id === "admin"
            ? row?._to_wallet_usable_balance?.toFixed(2)
            : row?._from_wallet_id === props?.UserData?.data?._id
            ? row?._from_wallet_usable_balance?.toFixed(2)
            : row?._to_wallet_usable_balance?.toFixed(2),
          // Other
          otherBalance(row),
          // BXI Commission
          row?.INRDetails?.TotalBxiCommission?.toFixed(2) ||
            row?.INRDetails?.totalBxiCommission?.toFixed(2) ||
            0,
          // Assessable Value
          getTotalBalance(row) ? getTotalBalance(row).toFixed(2) : null,
          // GST
          getTotalGST(row) ? getTotalGST(row).toFixed(2) : null,
          // Total Invoice Value
          getTotalBalance(row) + getTotalGST(row)
            ? (getTotalBalance(row) + getTotalGST(row))?.toFixed(2)
            : null,
          // TDS
          row?.INRDetails?.TDS?.toFixed(2),
          // Net Receivable/(Payable)
          row?._from_wallet_id !== props?.UserData?.data?._id
            ? (
                getTotalBalance(row) +
                getTotalGST(row) -
                (row?.INRDetails?.TDS || 0)
              ).toFixed(2)
            : (getTotalBalance(row) + getTotalGST(row)).toFixed(2),
          // INR
          row?.INRDetails?.TotalInrToPay?.toFixed(2),
          // Coins
          row?._balance?.toFixed(2),
          // TDS (26AS)
          row._from_wallet_id !== props?.UserData?.data?._id
            ? row?.INRDetails?.TDS?.toFixed(2)
            : null,
          // Invoice Value
          getTotalBalance(row) + getTotalGST(row)
            ? (getTotalBalance(row) + getTotalGST(row))?.toFixed(2)
            : null,
        ];

        XLSX.utils.sheet_add_aoa(infoWs, [rowData], { origin: index + 7 });
      })
    );

    XLSX.utils.book_append_sheet(wb, infoWs, "Statement");

    XLSX.writeFile(
      wb,
      `${props?.UserData?.data?.CUID}-${new Date().toISOString()}.xlsx`
    );
  };

  return (
    <Button
      onClick={generatePDFStatement}
      sx={{
        background: "#2261a2",
        borderRadius: "17px",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        textTransform: "none",
        color: "#F8F8F8",
        width: "200px",
        height: "40px",
        "&:hover": {
          background: "#2261a2",
        },
      }}
    >
      <HiDownload fill="white" size={20} /> Download Statement
    </Button>
  );
};

const PendingEntriesButton = {
  width: "100%",
  maxWidth: "200px",
  height: "35px",
  padding: "0px 1rem",

  fontFamily: "Outfit",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: {
    xl: "16px",
    lg: "16px",
    md: "16px",
    sm: "16px",
    xs: "14px",
  },
  lineHeight: "25px",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  borderRadius: "10px",
  cursor: "pointer",
  transition: "background-color 0.5s ease",
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#000",
};

const TableContainerStyle = {
  minHeight: "550px",
  maxHeight: "550px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};
