import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useCallback } from "react";
import { AiFillHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import WishlistIcon from "../../assets/wishlist.png";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TostMessagesTop from "../../Component/Toast";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { useAddToWishlist } from "../../Hooks/ProductActions/useAddToWishlist";
import { useGetWishlistData } from "../../Hooks/ProductActions/useGetWishlistData";
import { useRemoveWishlistProductByProductId } from "../../Hooks/ProductActions/useRemoveWishlistProduct";
import StacsOfCoinIcon from "../../assets/BXITokenIcon.png";
import readystock from "../../assets/Images/MarketplaceImages/ReadyStocknew.svg";
import bestseller from "../../assets/Images/MarketplaceImages/BestSellernew.svg";
import { get_Cart_Items } from "../../redux/action/CartActions";
import { getLoggedCompanyDetails } from "../../redux/action/Company/LoggedInCompanyAction";
import { setCurrentPageCount } from "../../redux/action/Home-Filter/CurrentPageCount";
import { getProduct } from "../../redux/action/Home-Filter/products";
import { allNotification } from "../../redux/action/Notification/getNotifications";
import { setActivePaginationCount } from "../../redux/action/PaginationCount";
import { ProductAnalysisUpdate } from "../../redux/action/Products/ProductAnalysis";
import { getProductFilter } from "../../redux/action/products";
import { socket } from "../Message/Message";
import Nodataimgur from "../../assets/Images/datanotfound/NoDataFoundWithLogo.png";
import DiscountPrice from "./DiscountPrice";

export let Pagination_Count = 1;

function DiscountedPrice(regularPrice, gst, type) {
  const gstPrice = regularPrice / (1 + gst / 100);

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "auto",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#2261A2",
          }}
        >
          {type === "Media"
            ? Number(regularPrice).toFixed(2)
            : Number(gstPrice).toFixed(2)}
        </Typography>
      </Box>
    </div>
  );
}

function GstWithoutDiscountedPrice({ price, gst, listing }) {
  const priceWithoutGST = price / (1 + gst / 100);

  const gstAmount = price - priceWithoutGST;

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "auto",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#2261A2",
          }}
        >
          {Number(gstAmount).toFixed(2)}
        </Typography>
      </Box>
    </div>
  );
}

function MediaGstWithoutDiscountedPrice({ price, gst, listing }) {
  // Exclusive GST calculation
  const gstAmount = (price * gst) / 100; // GST on top of base price

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
          width: "auto",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            letterSpacing: "0.06em",
            textTransform: "capitalize",
            color: "#2261A2",
          }}
        >
          {gstAmount.toFixed(2)}{" "}
          {/* Display the GST amount rounded to 2 decimal places */}
        </Typography>
      </Box>
    </div>
  );
}

const ProductList = ({ taxToggle, pagecount }) => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Next'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Next'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'Previous'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const { products, loading, error } = useSelector((state) => state.products);
  const { activePaginationCount } = useSelector(
    (state) => state.PaginationCount
  );
  const { CurrentPage } = useSelector((state) => state.CurrentPage);

  const [initialPaginationCount, setInitialPaginationCount] = useState(
    CurrentPage || 1
  );
  const [currentPage, setCurrentPage] = useState(activePaginationCount || 1);
  const [IsBestSeller, setIsBestSeller] = useState();
  const [IsReadyStock, setIsReadyStock] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: loggedInUserData } = useGetLoggedInUser();

  const {
    data: mutateWishlistData,
    mutate: addtowishlist,
    isLoading: wishlistMutateLoading,
    error: wishlistMutateError,
  } = useAddToWishlist();

  const { data: mutateRemoveWishlistData, mutate: removefromwishlist } =
    useRemoveWishlistProductByProductId();

  const {
    data: wishlistData,
    isLoading: wishlistLoading,
    error: wishlistError,
    refetch: wishlistRefetch,
  } = useGetWishlistData();

  useEffect(() => {
    if (products?.count !== undefined) {
      setInitialPaginationCount(products?.count);
      dispatch(setCurrentPageCount(products?.count));
    }
  }, [products?.count, dispatch]);

  useEffect(() => {
    setCurrentPage(1);
  }, [pagecount?.carouselChnage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [CurrentPage]);

  useEffect(() => {
    if (products?.products && products?.products?.length < 0) {
      setCurrentPage(1);
    }
  }, [products]);

  useEffect(() => {
    wishlistRefetch();
    dispatch(get_Cart_Items());
  }, [wishlistRefetch, mutateWishlistData, mutateRemoveWishlistData, dispatch]);

  useEffect(() => {
    dispatch(getLoggedCompanyDetails());
    dispatch(getProductFilter());
  }, [dispatch]);

  useEffect(() => {
    const fetchBestSeller = async () => {
      try {
        const { data } = await axios.get("AdminCompanyDetails/BestSeller");
        setIsBestSeller(data);
      } catch (error) {
        console.error("Failed to fetch best sellers", error);
      }
    };

    const fetchReadyStock = async () => {
      try {
        const { data } = await axios.get(
          "wallet/get_all_credit_line_transaction"
        );
        setIsReadyStock(data.body);
      } catch (error) {
        console.error("Failed to fetch ready stock", error);
      }
    };

    fetchBestSeller();
    fetchReadyStock();
  }, []);

  useEffect(() => {
    if (loggedInUserData?.data?._id) {
      dispatch(allNotification(loggedInUserData.data._id));
    }
  }, [loggedInUserData?.data?._id, dispatch]);

  useEffect(() => {
    if (socket.id && loggedInUserData?.data?._id) {
      socket.emit("newUser", {
        login_User: loggedInUserData.data._id,
        socketId: socket.id,
      });
    }
  }, [socket.id, loggedInUserData?.data?._id]);

  useEffect(() => {
    dispatch(getProduct("", "", "", "", "", currentPage, "", "", "", ""));
  }, [currentPage]);

  const handlePageChange = useCallback(
    (event, page) => {
      dispatch(setActivePaginationCount(page));
      setCurrentPage(page);
    },
    [dispatch]
  );

  const handleAddToWishlist = useCallback(
    async (id, ProductType) => {
      try {
        await axios.post(
          "wishlist/add_to_wishlist",
          { id, ProductType },
          { withCredentials: true }
        );
        wishlistRefetch();
      } catch (error) {
        console.error("Failed to add to wishlist", error);
      }
    },
    [wishlistRefetch]
  );

  const handleRemoveWishlist = useCallback(
    async (id) => {
      try {
        await removefromwishlist(id);
      } catch (error) {
        console.error("Failed to remove from wishlist", error);
      }
    },
    [removefromwishlist]
  );

  const GetProductCategory = useCallback(
    (name, id, type, listingType, VoucherType, ProductSubCategory) => {
      dispatch(ProductAnalysisUpdate(id, "ProductViewCount"));

      let path = `/home/productdetail/${id}`;
      if (listingType === "Voucher" || ProductSubCategory === "Gift Cards") {
        path = `/home/voucherdetail/${id}`;
      } else if (listingType === "Media") {
        path = `/home/mediabuying/${id}`;
      }
      navigate(path, { state: { type: listingType } });
    },
    [dispatch, navigate]
  );

  let IsReadyStockflatIdsArray = null;
  if (IsReadyStock?.length > 0) {
    const IsReadyStockproductsArrayFilter =
      IsReadyStock && IsReadyStock?.filter((item) => item._status === true);

    IsReadyStockflatIdsArray = IsReadyStockproductsArrayFilter?.map(
      (item) => item._product_id
    );
  }

  useEffect(() => {
    <TostMessagesTop PageLocation="Marketplace" />;
  }, []);

  return (
    <Paper
      sx={{
        width: "100%",
        mx: "auto",
        background: "transparent",
        borderRadius: "5px",
        mt: 2,
      }}
      elevation={0}
    >
      <Paper
        elevation={0}
        sx={{ width: "100%", mx: "auto", background: "transparent" }}
      >
        <Grid container>
          {loading === true ? (
            [...Array(32)].map((e, i) => (
              <Grid
                item
                xl={3}
                lg={3}
                md={4}
                sm={6}
                xs={6}
                key={i}
                sx={GridCssOne}
              >
                <Paper elevation={0} sx={GridPaper}>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "100%",
                      maxWidth: "350px",
                      minHeight: {
                        xl: "360px",
                        lg: "360px",
                        md: "360px",
                        sm: "180px",
                        xs: "180px",
                      },
                      borderRadius: "15px",
                    }}
                  />
                </Paper>
              </Grid>
            ))
          ) : products?.products && products?.products?.length > 0 ? (
            products?.products?.map((res, idx) => {
              const discountPrice = Number(
                res?.ProductsVariantions[0]?.DiscountedPrice
              );
              const percentage = Number(res?.ProductsVariantions[0]?.GST);
              const gstPrice = (discountPrice * percentage) / 100;
              const gstamount = discountPrice + gstPrice;

              const isAllOutOfStock = res?.ProductsVariantions.every(
                (variation) =>
                  variation.MaxOrderQuantity < variation.MinOrderQuantity ||
                  variation.MaxOrderQuantity === 0
              );

              return (
                <Grid
                  item
                  xl={3}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  key={idx}
                  sx={{}}
                >
                  <Box sx={ArrayBoxCss}>
                    <Box sx={ArrayBoxChild}>
                      <Box sx={ArrayBoxAbsolute}>
                        <Box sx={ArrayBoxAbsoluteChild}>
                          {(IsReadyStockflatIdsArray?.includes(`${res._id}`) ||
                            res?.IsReadyStock) &&
                          !isAllOutOfStock ? (
                            <img
                              src={readystock}
                              alt=""
                              style={{
                                height: "auto",
                                width: "38px",
                              }}
                            />
                          ) : null}
                          {res?.IsBestSeller ? (
                            <img
                              src={bestseller}
                              alt=""
                              style={{
                                height: "auto",
                                width: "38px",
                              }}
                            />
                          ) : null}
                        </Box>

                        <Paper elevation={0} sx={ArrayBoxPaper}>
                          {wishlistData &&
                          Array.isArray(wishlistData) &&
                          wishlistData?.find(
                            (item) => item?.ProductId?._id === res?._id
                          ) ? (
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() => handleRemoveWishlist(res?._id)}
                            >
                              <AiFillHeart color={"red"} size={"18px"} />
                            </button>
                          ) : (
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleAddToWishlist(res?._id, res?.ProductType)
                              }
                            >
                              <img
                                src={WishlistIcon}
                                width="18px"
                                height="18px"
                                alt="img"
                              />
                            </button>
                          )}
                        </Paper>
                      </Box>
                      <Box sx={{ position: "relative" }}>
                        <Box
                          sx={ArrayBoxBoxRelative}
                          onClick={() => {
                            GetProductCategory(
                              res?.ProductCategoryName,
                              res?._id,
                              res?.ProductType,
                              res?.ListingType,
                              res?.ProductSubCategory
                            );
                          }}
                        >
                          <Box
                            component={"img"}
                            src={
                              res?.ProductImages.length > 0
                                ? res?.ProductImages[0].url
                                : res?.VoucherImages?.at(0).url
                            }
                            alt="img"
                            sx={ArrayImageBox}
                          />
                          {res?.ListingType !== "Media" && isAllOutOfStock ? (
                            <Box sx={OutOfStock}>
                              <Button sx={OutOfBoxButton}>OUT OF STOCK</Button>
                            </Box>
                          ) : null}
                        </Box>
                      </Box>
                      <Box
                        sx={MainProductBox}
                        onClick={() => {
                          GetProductCategory(
                            res?.ProductCategoryName,
                            res?._id,
                            res?.ProductType,
                            res?.ListingType,
                            res?.VoucherType,
                            res?.ProductSubCategory
                          );
                        }}
                      >
                        <Paper
                          sx={MainProductPaper}
                          onClick={() => {
                            GetProductCategory(
                              res?.ProductCategoryName,
                              res?._id,
                              res?.ProductType,
                              res?.ListingType,
                              res?.VoucherType,
                              res?.ProductSubCategory
                            );
                          }}
                          elevation={0}
                        >
                          <Typography
                            sx={{
                              ...MainTextStyle,
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              mt: "10px",
                            }}
                          >
                            {res?.ProductName}
                          </Typography>
                        </Paper>
                        <Paper
                          sx={StartingFromPaper}
                          onClick={() => {
                            GetProductCategory(
                              res?.ProductCategoryName,
                              res?._id,
                              res?.ProductType,
                              res?.ListingType,
                              res?.VoucherType,
                              res?.ProductSubCategory
                            );
                          }}
                          elevation={0}
                        >
                          {res?.ProductsVariantions?.length > 1 ? (
                            <Typography
                              sx={{
                                ...PriceTextStyle,
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                                alignContent: "center",
                              }}
                            >
                              Starting from
                            </Typography>
                          ) : null}
                          <Typography
                            sx={{
                              ...PriceTextStyle,
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                              alignContent: "center",
                              height: "50px",
                            }}
                          >
                            {taxToggle ? (
                              <>
                                <Typography
                                  sx={{
                                    color: "#2261A2",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    component={"img"}
                                    src={StacsOfCoinIcon}
                                    sx={{
                                      width: {
                                        xl: "18px",
                                        lg: "18px",
                                        md: "18px",
                                        sm: "12px",
                                        xs: "12px",
                                      },
                                      height: "auto",
                                    }}
                                    alt="coin"
                                  />{" "}
                                </Typography>

                                {DiscountedPrice(
                                  res?.ProductsVariantions[0]?.DiscountedPrice,
                                  Number(res?.ProductsVariantions[0]?.GST),
                                  res?.ListingType
                                )}
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    letterSpacing: "0.06em",
                                    textTransform: "capitalize",
                                    color: "#2261A2",
                                    minWidth: "100px",
                                    display: "flex",
                                    gap: "5px",
                                    ml: "1px",
                                  }}
                                >
                                  + ₹
                                  {res?.ListingType !== "Media"
                                    ? GstWithoutDiscountedPrice({
                                        price:
                                          res?.ProductsVariantions?.at(0)
                                            ?.DiscountedPrice,
                                        gst: Number(
                                          res?.ProductsVariantions?.at(0)?.GST
                                        ),
                                        listing: res?.ListingType,
                                      })
                                    : MediaGstWithoutDiscountedPrice({
                                        price:
                                          res?.mediaVariation?.DiscountedPrice,
                                        gst: Number(res?.mediaVariation?.GST),
                                        listing: res?.ListingType,
                                      })}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography
                                  sx={{
                                    color: "#2261A2",
                                    fontSize: "16px",
                                    fontWeight: 600,
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <DiscountPrice
                                    regularPrice={
                                      res?.ProductsVariantions[0]?.PricePerUnit
                                    }
                                    discountPrice={
                                      res?.ProductsVariantions[0]
                                        ?.DiscountedPrice
                                    }
                                  />
                                </Typography>
                              </>
                            )}
                          </Typography>
                        </Paper>
                        <Paper
                          sx={{
                            ...ProductDescPaper,
                            minHeight:
                              res?.ProductDescription.length >= 33
                                ? "33px"
                                : "34px",
                          }}
                          elevation={0}
                        >
                          <Typography sx={BottomTextStyle}>
                            {res?.ProductDescription
                              ? res?.ProductDescription
                              : ""}
                          </Typography>
                        </Paper>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })
          ) : (
            <Box
              sx={{
                mx: "auto",
                gap: "20px",
                width: "100%",
                mt: 2,
                display: "grid",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Box
                component="img"
                src={Nodataimgur}
                sx={{
                  width: "auto",
                  maxWidth: "250px",
                  height: "auto",
                  mx: "auto",
                }}
              />
              <Typography
                sx={{
                  ...EmptyWishlistTextOne,
                  lineHeight: "20px",
                }}
              >
                No Data Found!
                <span style={{ fontWeight: 400, fontSize: "12px" }}>
                  Sorry .. This information is not available for a moment.
                </span>
              </Typography>
            </Box>
          )}
        </Grid>
      </Paper>
      {products?.products && products?.products?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
            mx: "auto",
          }}
          mt={2}
        >
          <Stack>
            <StyledPagination
              size="medium"
              count={initialPaginationCount}
              color="primary"
              page={currentPage}
              onChange={handlePageChange}
              strokeWidth={currentPage}
            />
          </Stack>
        </Box>
      ) : null}
      <TostMessagesTop PageLocation="Marketplace" />
    </Paper>
  );
};

export default ProductList;

const GridCssOne = {
  boxSizing: "border-box",
  width: "100%",
  height: "auto",
  maxWidth: "350px",
  minHeight: {
    xl: "360px",
    lg: "360px",
    md: "360px",
    sm: "180px",
    xs: "180px",
  },
  my: "20px",
  borderRadius: "13.6px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const GridPaper = {
  width: "100%",
  mx: "auto",
  height: "100%",
  bgcolor: "#fff",
  borderRadius: "13px",
  padding: "0px",
  maxWidth: "350px",
  minHeight: {
    xl: "360px",
    lg: "360px",
    md: "360px",
    sm: "180px",
    xs: "180px",
  },
};

const ArrayBoxCss = {
  mx: "auto",
  boxSizing: "border-box",
  width: "100%",
  height: "auto",
  maxWidth: {
    xl: "340px",
    lg: "330px",
    md: "300px",
    sm: "280px",
    xs: "310px",
  },
  minHeight: {
    xl: "360px",
    lg: "360px",
    md: "360px",
    sm: "340px",
    xs: "340px",
  },
  bgcolor: "red",
  borderRadius: "13.6px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  // p: 0.1,
  my: "20px",
  cursor: "pointer",
  border: "0.1px solid transparent",
  backgroundImage:
    "linear-gradient(180deg,#2261a2 0%,#2261a2 47.4%,#2261a2 100%)",
  transition: "box-shadow .1s",
  "&:hover": {
    boxShadow: "0px 0px 17px #ff864547",
  },
};

const ArrayBoxChild = {
  width: "100%",
  mx: "auto",
  height: "100%",
  bgcolor: "#fff",
  borderRadius: "13px",
  padding: "0px",
  minHeight: {
    xl: "360px",
    lg: "360px",
    md: "360px",
    sm: "360px",
    xs: "360px",
  },
  position: "relative",
};

const ArrayBoxAbsolute = {
  display: "flex",
  position: "relative",
  position: "absolute",
  width: "100%",
  top: "0px",
};

const ArrayBoxAbsoluteChild = {
  left: "2%",
  top: "-5%",
  position: "absolute",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  gap: "6px",
};

const ArrayBoxPaper = {
  display: "flex",
  width: "60px",
  maxWidth: "60px",
  ml: "auto",
  mr: 1,
  bgcolor: "transparent",
  minHeight: "23px",
  alignItems: "center",
  height: "auto",
  justifyContent: "space-evenly",
  mt: 1,
};

const ArrayBoxBoxRelative = {
  width: "100%",
  height: "auto",
  minHeight: {
    xl: "160px",
    lg: "160px",
    md: "160px",
    sm: "100px",
    xs: "100px",
  },
  mx: "auto",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  cursor: "pointer",
  position: "absolute",
  top: "40px",
};

const ArrayImageBox = {
  width: "auto",
  maxWidth: {
    xl: "300px",
    lg: "270px",
    md: "270px",
    sm: "130px",
    xs: "130px",
  },
  maxHeight: {
    xl: "200px",
    lg: "200px",
    md: "130px",
    sm: "80px",
    xs: "80px",
  },
  height: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: {
    xl: "10px",
    lg: "10px",
    md: "10px",
    sm: "5px",
    xs: "5px",
  },
};

const OutOfStock = {
  background: "rgba(129, 129, 129, 0.47)",
  backdropFilter: "blur(5.599999904632568px)",
  position: "absolute",
  width: "100%",
  maxWidth: "350px",
  maxHeight: "60px",
  height: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "0px",
  top: "50%",
};

const OutOfBoxButton = {
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textTransform: "uppercase",
  textAlign: "center",
  width: "100%",
  height: "100%",
};

const MainProductBox = {
  width: "100%",
  mx: "auto",
  height: "auto",
  display: "grid",
  justifyContent: "center",
  alignItems: "center",
  pb: 2,
  cursor: "pointer",
  mt: 1,
  minWidth: {
    xl: "350px",
    lg: "350px",
    md: "350px",
    sm: "250px",
    xs: "250px",
  },
  position: "absolute",
  top: "auto",
  bottom: "-0px",
};

const MainProductPaper = {
  width: "97%",
  mx: "auto",
  height: "auto",
  minWidth: {
    xl: "275px",
    lg: "275px",
    md: "275px",
    sm: "140px",
    xs: "140px",
  },
  maxWidth: {
    xl: "275px",
    lg: "275px",
    md: "275px",
    sm: "150px",
    xs: "150px",
  },
  display: "flex",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const StartingFromPaper = {
  width: "97%",
  mx: "auto",
  height: "auto",
  minWidth: {
    xl: "275px",
    lg: "275px",
    md: "275px",
    sm: "140px",
    xs: "140px",
  },
  maxWidth: {
    xl: "275px",
    lg: "275px",
    md: "275px",
    sm: "150px",
    xs: "150px",
  },
  display: "flex",
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const ProductDescPaper = {
  width: "97%",

  mx: "auto",
  minWidth: {
    xl: "275px",
    lg: "275px",
    md: "275px",
    sm: "140px",
    xs: "140px",
  },
  maxWidth: {
    xl: "275px",
    lg: "275px",
    md: "275px",
    sm: "150px",
    xs: "150px",
  },
};

const PriceTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "15px", lg: "15px", md: "15px", sm: "10px", xs: "10px" },
  lineHeight: "24px",
  color: "#717171",
};

const MainTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: { xl: "16px", lg: "16px", md: "14px", sm: "10px", xs: "10px" },
  // lineHeight: "30px",
  color: "#141414",
  display: "-webkit-box",
  WebkitLineClamp: 1,
  maxWidth: "320px",
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const BottomTextStyle = {
  width: "90%",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: {
    xl: "11px",
    lg: "11px",
    md: "8px",
    sm: "8px",
    xs: "8px",
  },
  lineHeight: "15px",
  color: "#6B7A99",
  textAlign: "left",
  mt: 0.5,
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const EmptyWishlistTextOne = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "18px",
  textAlign: "center",
  color: "#7E8BA6",
  display: "flex",
  flexDirection: "column",
};
