import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import BxiLogo from '../../assets/BXI_LOGO.png';
import whitearr from '../../assets/whitearr.png';
import Arrow from '../../assets/LoginArrow.svg';
import { useNavigate } from 'react-router-dom/dist';
import { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import DrawerComp from './DrawerComp';
import { useEffect } from 'react';

const Navbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState('1');
  const [imgArr, setImgArr] = useState(false);
  const [border, SetBorder] = useState(false);
  const currentLocation = window.location.pathname;
  const pages = [
    { name: 'Home', link: '/', id: '1', text: '/' },
    {
      name: 'marketplace',
      link: '/marketplace',
      id: '2',
      text: '/marketplace',
    },
  ];
  const links = ['/home', '/howitworks'];
  // howitworks;
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: '100%',
          background: '#fff',
          // boxShadow: "none",
          boxShadow: '0px 8px 16px rgba(220, 220, 220, 0.5)',
          // height: "auto",
          // p: 1,
        }}
      >
        <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              // bgcolor: "red",
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'end',
              flexDirection: 'row',
              gap: '8px',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/')}
          >
            <img src={BxiLogo} alt="" width="45px" />
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                textAlign: 'center',
                color: '#000',
                mb: '-4px',
                textTransform: 'uppercase',
              }}
            >
              Barter Exchange of India
            </Typography>
          </Box>

          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'right',
                  width: '22%',
                  marginLeft: 'auto',
                }}
              >
                <TabContext
                  textColor="#252525"
                  value={value}
                  onChange={(event, newValue) => setValue(newValue)}
                  indicatorColor="primary"
                >
                  {pages.map((page, index) => (
                    <Tab
                      key={index}
                      label={page.name}
                      value={page.id}
                      index={page.id}
                      sx={{
                        ...NavbarText,
                        textTransform: 'none',
                        m: '0 5px',
                        p: '6px 16px',
                        minHeight: '48px',
                        position: 'relative',
                        overflow: 'hidden',
                        color:
                          currentLocation === page.text
                            ? 'rgba(34, 40, 49, 1)'
                            : 'rgba(25, 24, 37, 0.5)',
                        '&::after': {
                          content: "''",
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: '100%',
                          height: '3px',
                          backgroundColor:
                            currentLocation === page.text
                              ? 'transparent'
                              : '#375DBB',
                          transform: 'scaleX(0)',
                          transition: 'transform 0.3s ease-in-out',
                        },
                        '&:hover': {
                          backgroundColor: 'transparent',
                          color: '#000',
                        },
                      }}
                      onClick={() => navigate(page.link)}
                      disableRipple
                    />
                  ))}
                </TabContext>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '10px',
                }}
              >
                <Box
                  onClick={() => navigate('/login')}
                  sx={{
                    ...registerText,
                    borderRadius: '10px',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      backgroundColor: 'rgba(68, 95, 210, 1)',
                      color: '#fff',
                    },
                  }}
                >
                  Log In
                </Box>
                <Box
                  onClick={() => navigate('/createaccount')}
                  sx={{
                    ...registerText,
                    borderRadius: '10px',
                    color: '#fff',
                    backgroundColor: 'rgba(68, 95, 210, 1)',
                  }}
                >
                  Sign Up
                </Box>
              </Box>
            </>
          )}
          {/* <TrendingFlatIcon /> */}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;

const NavbarText = {
  marginLeft: '16px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '14px', xs: '14px' },
  lineHeight: '18px',
  color: 'rgba(140, 139, 146, 1)',
  cursor: 'pointer',
};

const registerText = {
  fontFamily: 'Poppins',
  fontSize: { xl: '12px', lg: '12px', md: '10px', sm: '12px', xs: '12px' },
  fontWeight: 700,
  border: '1px solid rgba(239, 239, 239, 1)',
  borderRadius: '16px',
  color: 'rgba(34, 40, 49, 1)',
  p: 1,
  cursor: 'pointer',
  width: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
