import React from 'react';
import * as Styles from '../Styles';
import { Box, Grid, Typography, Paper } from '@mui/material';
import smallbxilogo from '../../../assets/Images/LearnBarterImages/smallbxilogo.svg';
import FinalBarterCoin from '../../../assets/HomePageImages/FinalBarterCoin.png';
import { Fade } from 'react-reveal';
const Earn = () => {
  const heroText = [
    'List Product - Sell First - Earn Trade Credits',
    'List Product - Apply Trade Limit - Buy First ',
  ];
  return (
    <Paper elevation={0} mt={10}>
      <Grid container mt={5}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Box sx={{ mt: { xl: 8, lg: 8, md: 8, sm: 2, xs: 2 } }}>
            <Fade top duration={1000}>
              <Typography
                sx={{
                  ...Styles.headMainText,
                  textAlign: 'left',
                  width: {
                    xl: '90%',
                    lg: '90%',
                    md: '80%',
                    sm: '80%',
                    xs: '80%',
                  },
                  mx: 'auto',
                }}
              >
                Ways to Earn Your
                <span style={{ color: 'rgba(108, 108, 162, 1)' }}>
                  &nbsp;Trade Credits
                </span>
              </Typography>
            </Fade>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 2,
                alignItems: {
                  xl: 'flex-start',
                  lg: 'flex-start',
                  md: 'flex-start',
                  sm: 'flex-start',
                  xs: 'flex-start',
                },
                ml: { xl: 5, lg: 5, md: 5, sm: 10, xs: 5 },
                mt: { xl: 5, lg: 5, md: 5, sm: 2, xs: 2 },
              }}
            >
              {heroText.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3,
                  }}
                >
                  <Box
                    component={'img'}
                    src={smallbxilogo}
                    sx={{
                      height: 'auto',
                      maxWidth: '20px',
                    }}
                  />
                  <Typography sx={Styles.subCompanyText}>{item}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
        >
          <Box
            sx={{
              alignItems: 'center',
              position: 'relative',
              // marginLeft: '100px',
              display: {
                xl: 'block',
                lg: 'block',
                md: 'block',
                sm: 'none',
                xs: 'none',
              },
            }}
          >
            <div className="bgImageWithUrl">
              <div className="Bounce_img">
                <Box
                  component="img"
                  src={FinalBarterCoin}
                  alt="altimage"
                  sx={{
                    height: 'auto',
                    maxWidth: '173px',
                    width: '100%',
                  }}
                />
              </div>
              <Typography sx={IBCBTypo}>1 Trade Credit = 1 INR</Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Earn;

const IBCBTypo = {
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontSize: '15px',
  position: 'absolute',
  fontWeight: 400,
  top: 200,
  right: 130,
  fontSize: { xl: '14px', lg: '14px', md: '14px', sm: '10px', xs: '10px' },
  lineHeight: { xl: '30px', lg: '30px', md: '30px', sm: '20px', xs: '20px' },
  color: 'rgba(73, 75, 122, 1)',
};
