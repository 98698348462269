import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import GetCompanyNameNProfile from "../common/GetCompanyNameNProfile";
import TransactionsCommaSeprator from "../TransactionsCommaSeprator";
import Skeleton from "@mui/material/Skeleton";
import axios from "axios";
import { toast } from "react-toastify";
import GlobalToast from "../Toasts/GlobalToast";
import { useNavigate } from "react-router-dom";

function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

const formatDateTime = (dateTimeString) => {
  const createdAtDateTime = new Date(dateTimeString);
  const timeFormatOptions = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  const formattedTime = createdAtDateTime.toLocaleTimeString(
    "en-US",
    timeFormatOptions
  );

  return `${formattedTime}`;
};

const DashboardPendingTransactions = ({
  WalletData,
  userData,
  dataLoading,
  type,
}) => {
  const navigate = useNavigate();

  const make_commission_payment = async (OrderData) => {
    const TotalAmountToPay = [
      OrderData?.INRDetails?.TDS,
      OrderData?.INRDetails?.bxiCommisionGST ||
        OrderData?.INRDetails?.BxiCommisionGST,
      OrderData?.INRDetails?.totalBxiCommission ||
        OrderData?.INRDetails?.TotalBxiCommission,
    ]
      .map((value) => Number(value) || 0)
      .reduce((acc, curr) => acc + curr, 0);
    if (TotalAmountToPay > 1) {
      await axios
        .post(`commission/bxi_commision_payment_dashboard`, {
          OrderId: OrderData?.OrderId,
        })
        .then((res) => {
          window.open(res?.data?.paymentURL, "_blank");
        })
        .catch((err) => {
          alert("Failed to make payment");
        });
    } else {
      toast.error("Minimum 1 rupee is required for payment", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        mt: "30px",
      }}
    >
      <TableContainer sx={TableContainerStyle}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography sx={TableTitleStyle}>Date</Typography>
              </TableCell>
              <TableCell sx={{ width: "200px" }}>
                <Typography sx={TableTitleStyle}>Pay Dues</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Company Name</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Order Number</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Type</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Debit</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>Credit</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableTitleStyle}>TDS</Typography>
              </TableCell>

              <TableCell>
                <Typography sx={TableTitleStyle}>Net trade Credits</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {dataLoading ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={21}>
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{
                      height: "65px",
                      width: "100%",
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {WalletData?.slice(0, 5)?.map((row, index) => {
                const wallet_release = row?._on_hold ? false : true;
                const tds_amount = row?.INRDetails?.TDS;
                let Amount = row?._balance + tds_amount;

                const commissionTotals =
                  row?.INRDetails?.TDS +
                  row?.INRDetails?.totalBxiCommission +
                  row?.INRDetails?.bxiCommisionGST;

                return (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: "1px solid green",
                    }}
                  >
                    <TableCell>
                      <Typography
                        sx={{
                          ...TextStyleCommon,
                          color: "black",
                        }}
                      >
                        {convertDate(row?._created_at)}
                        <br />
                        at {formatDateTime(row?._created_at)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "200px" }}>
                      {row?.OrderData?.escrowSellerReleaseResponse?.success ? (
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#2261a2",
                            width: "180px",
                            height: "40px",
                            textTransform: "none",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 500,
                            borderRadius: "10px",
                            "&:hover": {
                              backgroundColor: "#2261a2",
                            },
                          }}
                          onClick={() => {
                            make_commission_payment(row?.OrderData);
                          }}
                        >
                          Pay Dues | ₹{commissionTotals?.toFixed(2)}
                        </Button>
                      ) : null}
                    </TableCell>

                    <TableCell>
                      <Typography
                        sx={{
                          ...TextStyleCommon,
                          color: wallet_release ? "black" : "#FF8E00",
                        }}
                      >
                        <GetCompanyNameNProfile
                          CompanyId={
                            row?._from_wallet_id === userData?.data?._id
                              ? row?._to_wallet_id
                              : row?._from_wallet_id
                          }
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          ...TextStyleCommon,
                          color: wallet_release ? "black" : "#FF8E00",
                        }}
                      >
                        {row?._order_number?.includes("BXI")
                          ? row?._order_number
                          : row?._clnumber || row?._order_number}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          ...TextStyleCommon,
                          color: wallet_release ? "black" : "#FF8E00",
                        }}
                      >
                        {row?._type === "sell_recover_coupon"
                          ? "Sell/PrivateDeal"
                          : row?._type === "recover_coupon"
                          ? "Buy/PrivateDeal"
                          : row?._type === "sell_coupon" &&
                            row?._from_wallet_id !== userData?.data?._id
                          ? "Sell/PrivateDeal"
                          : row?._type === "sell_coupon"
                          ? "Buy/PrivateDeal"
                          : row?._type === "add_coupon"
                          ? "Buy/PrivateDeal"
                          : row?._type === "refund"
                          ? "Refund"
                          : row?._type?.includes("credit_line")
                          ? "Credit Line"
                          : row?._from_wallet_id === userData?.data?._id
                          ? "Buy"
                          : "Sell"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          ...TextStyleCommon,
                          color: wallet_release ? "black" : "#FF8E00",
                        }}
                      >
                        <TransactionsCommaSeprator
                          Price={
                            row?._from_wallet_id === userData?.data?._id
                              ? Amount
                              : row?._type === "credit_line_recovery"
                              ? Amount
                              : row?._type === "refund"
                              ? Amount
                              : null
                          }
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          ...TextStyleCommon,
                          color: wallet_release ? "black" : "#FF8E00",
                        }}
                      >
                        <TransactionsCommaSeprator
                          Price={
                            row?._from_wallet_id !== userData?.data?._id
                              ? row?._type === "add_coupon"
                                ? Amount
                                : row?._from_wallet_id === "admin" &&
                                  row?._type === "sell_coupon"
                                ? Amount
                                : row?._from_wallet_id === "admin"
                                ? null
                                : row?._type === "refund"
                                ? null
                                : Amount
                              : null
                          }
                        />
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        sx={{
                          ...TextStyleCommon,
                          color: wallet_release ? "black" : "#FF8E00",
                        }}
                      >
                        <TransactionsCommaSeprator
                          Price={row?.INRDetails?.TDS}
                        />
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Typography
                        sx={{
                          ...TextStyleCommon,
                          color: wallet_release ? "black" : "#FF8E00",
                        }}
                      >
                        <TransactionsCommaSeprator Price={row?._balance} />
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
                // }
              })}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DashboardPendingTransactions;

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const TableContainerStyle = {
  minHeight: "550px",
  maxHeight: "550px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
};
