import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import BXIToken from "../../assets/BXITokenIcon.png";
import CloseIcon from "../../assets/Images/FilterIcons/Close.png";
import SortIcon from "../../assets/Images/FilterIcons/Sort.png";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  FormControlLabel,
  Input,
  Modal,
  Paper,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import ToastComp from "../Hooks/ToastComp";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import useGetLoggedInUser from "../../Hooks/LoggedInUser/useGetLoggedInUser";
import { getProduct } from "../../redux/action/Home-Filter/products";
import sendEvent from "../../utils/sendEvents";
import VoucherIcon2 from "../../assets/Images/MarketplaceImages/VoucherIcon2.svg";
import ProductIcon2 from "../../assets/Images/MarketplaceImages/ProductIcon2.svg";
import { Autocomplete } from "@mui/material";
import AndhraPradeshStateIcon from "../../assets/Images/States/Andhra.png";
import ArunachalPradeshIcon from "../../assets/Images/States/ArunachalPradesh.png";
import AssamIcon from "../../assets/Images/States/Assam.png";
import BiharStateIcon from "../../assets/Images/States/Bihar.png";
import ChhattisgarhStateIcon from "../../assets/Images/States/Chhattisgarh.png";
import GoaIcon from "../../assets/Images/States/Goa.png";
import GujaratStateIcon from "../../assets/Images/States/Gujarat.png";
import HimachalPradeshIcon from "../../assets/Images/States/HP.png";
import HaryanaIcon from "../../assets/Images/States/Haryana.png";
import JammuKashmirIcon from "../../assets/Images/States/J&K.png";
import JharkhandStateIcon from "../../assets/Images/States/Jharkhand.png";
import KarnatakaStateIcon from "../../assets/Images/States/Karnataka.png";
import KeralaStateIcon from "../../assets/Images/States/Kerala.png";
import MadhyaPradeshStateIcon from "../../assets/Images/States/MP.png";
import MaharastraStateIcon from "../../assets/Images/States/Maharashtra.png";
import ManipurIcon from "../../assets/Images/States/Manipur.png";
import MeghalayaIcon from "../../assets/Images/States/Meghalaya.png";
import MizoramIcon from "../../assets/Images/States/Mizoram.png";
import NagalandIcon from "../../assets/Images/States/Nagaland.png";
import OdishaStateIcon from "../../assets/Images/States/Odisha.png";
import PunjabIcon from "../../assets/Images/States/Punjab.png";
import RajasthanStateIcon from "../../assets/Images/States/Rajasthan.png";
import SikkimIcon from "../../assets/Images/States/Sikkim.png";
import TamilNaduStateIcon from "../../assets/Images/States/TamilNadu.png";
import TripuraIcon from "../../assets/Images/States/Tripura.png";
import UttarPradeshStateIcon from "../../assets/Images/States/UP.png";
import UttarakhandIcon from "../../assets/Images/States/Uttrakhand.png";
import WestBengalStateIcon from "../../assets/Images/States/WestBengal.png";
import TelanganaStateIcon from "../../assets/Images/States/telanganaStateIcon.png";
import axios from "axios";
import CommaSeparator from "../CommaSeprator";

const StateObjectArray = [
  { Img: MaharastraStateIcon, text: "Maharashtra" },
  { Img: RajasthanStateIcon, text: "Rajasthan" },
  { Img: GujaratStateIcon, text: "Gujarat" },
  { Img: MadhyaPradeshStateIcon, text: "Madhya Pradesh" },
  { Img: KarnatakaStateIcon, text: "Karnataka" },
  { Img: UttarPradeshStateIcon, text: "Uttar Pradesh" },
  { Img: TamilNaduStateIcon, text: "Tamil Nadu" },
  { Img: AndhraPradeshStateIcon, text: "Andhra Pradesh" },
  { Img: TelanganaStateIcon, text: "Telangana" },
  { Img: KeralaStateIcon, text: "Kerala" },
  { Img: WestBengalStateIcon, text: "West Bengal" },
  { Img: OdishaStateIcon, text: "Odisha" },
  { Img: JharkhandStateIcon, text: "Jharkhand" },
  { Img: BiharStateIcon, text: "Bihar" },
  { Img: ChhattisgarhStateIcon, text: "Chhattisgarh" },
  { Img: ArunachalPradeshIcon, text: "Arunachal Pradesh" },
  { Img: AssamIcon, text: "Assam" },
  { Img: ManipurIcon, text: "Manipur" },
  { Img: MeghalayaIcon, text: "Meghalaya" },
  { Img: MizoramIcon, text: "Mizoram" },
  { Img: NagalandIcon, text: "Nagaland" },
  { Img: SikkimIcon, text: "Sikkim " },
  { Img: TripuraIcon, text: "Tripura" },
  { Img: HaryanaIcon, text: "Haryana" },
  { Img: PunjabIcon, text: "Punjab" },
  { Img: JammuKashmirIcon, text: "Jammu & Kashmir" },
  { Img: HimachalPradeshIcon, text: "Himachal Pradesh" },
  { Img: UttarakhandIcon, text: "Uttarakhand" },
  { Img: GoaIcon, text: "Goa" },
];

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    mx: "auto",
  },
  searchInput: {
    marginRight: 2,
  },
  filterButton: {
    marginLeft: 2,
  },
  select: {
    minWidth: 120,
  },
  largerIcon: {
    fontSize: 24, // Adjust the size as needed
  },
}));

export let States = null;
export let PriceRange = null;
export let Product_Qty = null;
export let Clear = null;
export let Refresh = null;
export let PriceShortHightToLow = null;
export let PriceShortLowToHight = null;
export let WhatsNew = null;
export let Popularity = null;
export let Voucher = null;
export let ProductsFilter = null;
export let IsBestSeller = null;
export let IsReadyStock = null;
export let Search = "";
export let Tag = "";
export let ProductDescriptionSearch = "";
export let ProductCompanyNameSearch = "";
export let ProductSubtittleSearch = "";
export let MediaFilter = "";
export let SelectedCompany = "";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#2261a2",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "lightgray" : "red",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const GlobalSearchFilter = ({ sendDataToParent }) => {
  const classes = useStyles();
  const [searchType, setSearchType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allProductSearch, setAllProductSearch] = useState([]);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [sortIconChange, setSortIconChange] = useState(false);
  const [Activeexpanded, setActiveexpanded] = useState(false);
  const [whatsNew, setWhatsNew] = useState("");
  const [isBestSeller, setIsBestSeller] = useState("");
  const [isReadyStock, setIsReadyStock] = useState("");
  const [popularity, setPopularity] = useState(false);
  const [storeFilter, setStoreFilter] = useState();
  const [sortByView, setSortByView] = useState();
  const [ProductLocationState, setstatefilter] = useState([]);
  const [pricerange, setPriceRange] = useState(1000000);
  const [ProductRating, setStoreRating] = useState([]);
  const [ProductQty, setProductQty] = useState("");
  const [ProductDeliveryType, setStoreDeliveryType] = useState();
  const [clear, setClear] = useState("");
  // const [defaults, setDefault] = useState();
  const [refresh, setRefresh] = useState(false);
  const [priceShortHightToLow, setPriceShortHightToLow] = useState("");
  const [priceShortLowToHight, setPriceShortLowToHight] = useState("");
  const [storeAllFilterData, setStoreAllFilterData] = useState();
  const [voucher, setVoucher] = useState(false);
  const [productsFilter, setProductsFilter] = useState(false);
  const [mediaFilter, setMediaFilter] = useState(false);
  const [search, setSearch] = useState("");
  const [tag, setTag] = useState("");
  const [descriptionSearch, setDescriptionSearch] = useState("");
  const [productCompanyNameSearch, setProductCompanyNameSearch] = useState("");
  const [productSubtittleSearch, setProductSubtittleSearch] = useState("");
  const [FilterType, setFilterType] = useState("all");
  const [all, setAll] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [taxSwitch, setTaxSwitch] = useState(false);

  const inputRef = useRef(null);

  const handleStyleChange = () => {
    inputRef.current.style.color = "#C3CAD9";
    inputRef.current.style.fontSize = "14px";
    inputRef.current.style.fontFamily = "Poppins";
  };

  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllProductSearch(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    filterByCompany();
  }, []);

  const handleBlur = () => {
    setIsExpanded(false);
  };

  const { data: loggedInUserData } = useGetLoggedInUser();

  const storeAllFilterDataFun = async () => {
    setStoreAllFilterData({
      sortByView: sortByView,
      ProductLocationState: ProductLocationState,
      pricerange: pricerange,
      ProductRating: ProductRating,
      ProductQty: ProductQty,
      ProductDeliveryType: ProductDeliveryType,
    });
    handleClose();
  };

  const { company: CompanyDetails } = useSelector(
    (state) => state.companyDetails
  );

  States = ProductLocationState;
  PriceRange = pricerange;
  Product_Qty = ProductQty;
  Clear = clear;
  Refresh = refresh;
  PriceShortHightToLow = priceShortHightToLow;
  PriceShortLowToHight = priceShortLowToHight;
  WhatsNew = whatsNew;
  Popularity = popularity;
  Voucher = voucher;
  ProductsFilter = productsFilter;
  IsBestSeller = isBestSeller;
  IsReadyStock = isReadyStock;
  Search = search;
  Tag = tag;
  ProductDescriptionSearch = descriptionSearch;
  ProductCompanyNameSearch = productCompanyNameSearch;
  ProductSubtittleSearch = productSubtittleSearch;
  MediaFilter = mediaFilter;
  SelectedCompany = selectedCompany;

  useEffect(() => {
    if (
      loggedInUserData &&
      loggedInUserData.data &&
      loggedInUserData.data._id
    ) {
      dispatch(
        getProduct(
          search,
          pricerange,
          sortByView,
          ProductQty,
          clear,
          CompanyDetails ? CompanyDetails._id : "",
          refresh,
          loggedInUserData.data._id,
          priceShortHightToLow,
          priceShortLowToHight,
          whatsNew,
          selectedCompany,
          "",
          popularity,
          voucher,
          "",
          productsFilter,
          isBestSeller,
          isReadyStock,
          tag,
          descriptionSearch,
          productCompanyNameSearch,
          productSubtittleSearch,
          States
        )
      );
    }
  }, [
    loggedInUserData,
    pricerange,
    sortByView,
    ProductQty,
    storeFilter,
    clear,
    CompanyDetails,
    refresh,
    priceShortHightToLow,
    priceShortLowToHight,
    whatsNew,
    selectedCompany,
    ProductLocationState,
    popularity,
    voucher,
    productsFilter,
    isBestSeller,
    isReadyStock,
    search,
    tag,
    descriptionSearch,
    productSubtittleSearch,
    mediaFilter,
    all,
  ]);

  const handleSearchTypeChange = (event) => {
    setFilterType(event.target.value);
    let type = event.target.value;
    if (type === "media") {
      setMediaFilter(true);
      setProductsFilter(false);
      setVoucher(false);
      handleClose();
      sendEvent("Click on product icon");
      setAll(false);
    } else if (type === "product") {
      setMediaFilter(false);
      setProductsFilter(true);
      setVoucher(false);
      handleClose();
      setAll(false);
      sendEvent("Click on product icon");
    } else if (type === "voucher") {
      setMediaFilter(false);
      setProductsFilter(false);
      setVoucher(true);
      handleClose();
      setAll(false);
      sendEvent("Click on product icon");
    } else if (type === "all") {
      setMediaFilter(false);
      setProductsFilter(false);
      setVoucher(false);
      handleClose();
      setAll(true);
      sendEvent("Click on clear filter");
    }
  };

  const handleSwitch = () => {
    setTaxSwitch(!taxSwitch);
    sendDataToParent(taxSwitch);
  };

  useEffect(() => {
    handleSwitch();
  }, []);

  const { products, loading } = useSelector((state) => state.products);

  const handleSearch = (event, newValue) => {
    setSelectedCompany(newValue);
    const uniqueCompanyNames = products?.products
      ?.map((option) => option?.SellerCompanyId?.companyName || "")
      .filter(Boolean);
    const filteredResults = uniqueCompanyNames.filter((name) =>
      name.toLowerCase().includes(newValue?.toLowerCase())
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "95%",
        mx: "auto",
        boxShadow: "none",
        background: "transparent",
        display: "flex",
        gap: "20px",
        marginTop: "0px",
        height: "80px",
        alignItems: "center",
        alignContent: "center",
        mt: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "center",
          width: "100%",
          maxWidth: "80%",
          mx: "auto",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            border: "none",
            height: "45px",
            borderRadius: "25px",
            background: "#fff",
            p: 0,
            display: "flex",
            boxShadow: "#2261a2 0px 0px 0px 0.5px;",
          }}
        >
          <Box
            sx={{
              background: "#fff",
              width: "4%",
              height: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              alignContent: "center",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <SearchIcon fontSize="large" color="disabled" />
          </Box>
          <input
            onClick={() => {
              handleStyleChange();
              sendEvent("Click on search expand");
            }}
            ref={inputRef}
            onChange={(e) => {
              setSearch(e.target.value);
              setTag(e.target.value);
              setDescriptionSearch(e.target.value);
              setProductCompanyNameSearch(e.target.value);
              setProductSubtittleSearch(e.target.value);
            }}
            label="Search"
            placeholder="Search"
            variant="outlined"
            className={classes.searchInput}
            InputProps={{ disableUnderline: true }}
            style={{
              color: "#2261a2",
              width: "75%",
              boxShadow: "none",
              border: "none",
              height: "93%",
              fontSize: "16px",
            }}
            onBlur={handleBlur}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              height: "100%",
              width: "4.5%",
              mr: "05px",
              borderRadius: "0px 27px 27px 0px",
              background: "#fff",
              width: "50px",
              border: "none",
              marginTop: "0px",
              boxShadow: "none",
              ":hover": {
                background: "#fff",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              handleOpen();
              sendEvent("Click on filter");
            }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <TuneIcon fontSize="large" color="primary" />
          </Button>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={FilterModalStyle}>
              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    height: "50px",
                  }}
                >
                  <Typography sx={{ ...FilterTitle, cursor: "default" }}>
                    Product Type
                  </Typography>
                </Box>

                <Select
                  select
                  label="Search Type"
                  defaultValue="All"
                  value={FilterType}
                  onChange={handleSearchTypeChange}
                  variant="standard"
                  disableUnderline
                  className={classes.searchInput}
                  IconComponent={() => (
                    <ArrowDropDownIcon
                      className={classes.largerIcon}
                      fontSize="larger"
                    />
                  )}
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    border: "none",
                    outline: "none",
                    height: "100%",
                    background: "transparent",
                    margin: "10px",
                    color: "#2261A2",
                    fontSize: "15px",
                    marginLeft: "17px",
                    marginTop: "0px",
                  }}
                >
                  <MenuItem value="all" sx={{ color: "#2261A2" }}>
                    All
                  </MenuItem>
                  <MenuItem value="product" sx={{ color: "#2261A2" }}>
                    Product
                  </MenuItem>
                  <MenuItem value="media" sx={{ color: "#2261A2" }}>
                    Media
                  </MenuItem>
                  <MenuItem value="voucher" sx={{ color: "#2261A2" }}>
                    Voucher
                  </MenuItem>
                </Select>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    height: "50px",
                  }}
                >
                  <Typography sx={{ ...FilterTitle, cursor: "default" }}>
                    Order Status
                  </Typography>
                </Box>
                <Select
                  select
                  label="Search Type"
                  defaultValue="All"
                  value={FilterType}
                  onChange={handleSearchTypeChange}
                  variant="standard"
                  disableUnderline
                  className={classes.searchInput}
                  IconComponent={() => (
                    <ArrowDropDownIcon
                      className={classes.largerIcon}
                      fontSize="larger"
                    />
                  )}
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    border: "none",
                    outline: "none",
                    height: "100%",
                    background: "transparent",
                    margin: "10px",
                    color: "#2261A2",
                    fontSize: "15px",
                    marginLeft: "17px",
                    marginTop: "0px",
                  }}
                >
                  <MenuItem value="all" sx={{ color: "#2261A2" }}>
                    All
                  </MenuItem>
                  <MenuItem value="product" sx={{ color: "#2261A2" }}>
                    Pending
                  </MenuItem>
                  <MenuItem value="media" sx={{ color: "#2261A2" }}>
                    Delivered
                  </MenuItem>
                  <MenuItem value="voucher" sx={{ color: "#2261A2" }}>
                    InActive
                  </MenuItem>
                  <MenuItem value="voucher" sx={{ color: "#2261A2" }}>
                    Active
                  </MenuItem>
                </Select>
              </Paper>

              <Paper
                elevation={0}
                sx={{
                  width: "80%",
                  bgcolor: "transparent",
                  mx: "auto",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "100px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    mt: 0,
                    borderRadius: "10px",
                  }}
                >
                  <Button
                    value="reset"
                    variant="outlined"
                    onClick={(e) => {
                      setstatefilter([]);
                      setPriceRange(1000000);
                      setProductQty("");
                      setSelectedCompany("");
                      setClear(e.target.value);
                      sendEvent("Click on Reset");
                      setOpen(false);
                    }}
                    sx={{
                      width: "170px",
                      height: "38px",
                      borderRadius: "6px",
                      fontFamily: "Poppins",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "13px",
                      lineHeight: "20px",
                      textAlign: "center",
                      color: "#0053ad",
                      textTransform: "none",
                    }}
                  >
                    Clear Filter
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Paper>
  );
};

export default GlobalSearchFilter;

const FilterModalStyle = {
  position: "absolute",
  width: "90%",
  maxWidth: { xl: "300px", lg: "300px", md: "300px", sm: "300px", xs: "300px" },
  height: "100%",
  right: "15%",
  top: "25%",
  maxHeight: "30vh",
  overflowY: "scroll",
  background: "#FFFFFF",
  borderRadius: "10px",
  "&::-webkit-scrollbar": {
    display: "none",
  },
};

const FilterTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "28px",

  color: "#2E2E2E",
};

const FilterTextStyleOne = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#B2BCCF",
  ml: 1.5,
};
